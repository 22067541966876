import { CmsGridWrapper } from 'commonStyledComponents'
import VideoBox from 'component/VideoBoxVertical'
import { memo } from 'react'

/**
 * 模板 ID: "9": 豎版一行三個
 * */
function Template9({ videoData, trigger, currentLimit }) {
  return (
    <CmsGridWrapper>
      {videoData?.slice(0, currentLimit).map((item, i) => {
        return <VideoBox overflow={true} columns={3} key={i} videoInfo={item} allowUnload />
      })}
      {trigger}
    </CmsGridWrapper>
  )
}

// this indicate areaBox should hide bottom section until fully shown
Template9.lazy = true
export default memo(Template9)
