import VideoBox from 'component/VideoBoxVertical'

import { Wrapper } from './Styled'
import { memo } from 'react'

/**
 * 模板 ID: "17": 豎版兩個半向左滑動
 * */
function Template17({ videoData }) {
  return (
    // 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能
    <Wrapper className={'swiper-no-swiping'}>
      {videoData?.map((item, i) => {
        return <VideoBox key={i} videoInfo={item} columns={2.5} allowUnload />
      })}
    </Wrapper>
  )
}
export default memo(Template17)
