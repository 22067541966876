import { useMemo } from 'react'

export const useDuplicatedArray = (arr, minLength) => {
  return useMemo(() => {
    if (!Array.isArray(arr) || arr.length === 0) {
      return arr
    }

    const dupeTimes = Math.ceil(minLength / arr.length)

    if (dupeTimes === 1) {
      return arr
    }

    const duped = []

    for (let i = 0; i < dupeTimes; i++) {
      duped.push(...arr)
    }

    return duped
  }, [arr, minLength])
}
