import { useCallback, useRef } from 'react'
import { useEvent } from './useEvent'

export const useResizeObserver = ({ onResize }) => {
  const onResizeEvent = useEvent(onResize)
  const observer = useRef(undefined)
  const onRefed = useCallback(
    (el) => {
      if (observer.current) {
        observer.current.disconnect()
        observer.current = undefined
      }

      if (el) {
        const newObserver = new ResizeObserver(() => {
          onResizeEvent()
        })
        newObserver.observe(el)
        observer.current = newObserver
      }
    },
    [onResizeEvent]
  )

  return onRefed
}
