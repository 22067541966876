// 漫畫 單行左滑(兩個半)
import ComicBox from 'component/ComicBox'

import { Wrapper } from './Styled'
import { memo } from 'react'

function Template203({ videoData }) {
  return (
    // 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能
    <Wrapper className={'swiper-no-swiping'}>
      {videoData?.map((item, i) => {
        return <ComicBox overflow={true} key={i} comicInfo={item} width="3rem" allowUnload />
      })}
    </Wrapper>
  )
}
export default memo(Template203)
