import { ColoredIcon } from 'component/ColoredIcon'
import styled, { css } from 'styled-components'

export const TitleBar = styled.div`
  flex: 0 0 auto;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.4rem;
  color: var(--page-view-novel--chapters-list-title-color);
  position: relative;
  padding: 0 var(--cms-grid-column-gap);
  gap: var(--cms-grid-column-gap);
`
export const TitleWrap = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
`
export const SortButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.1rem;
  width: 1.79rem;
  height: 0.75rem;
  font-size: 0.35rem;
  border-radius: 1rem;
  background: var(--page-view-novel--start-read-background);
  color: var(--page-view-novel--start-read-color);
`

export const SortIcon = styled(ColoredIcon).attrs((props) => {
  return {
    src: `${process.env.PUBLIC_URL}/comic-icons/sort.svg`,
    style: {
      transform: props.reversed ? 'rotate(180deg)' : 'rotate(0deg)',
    },
  }
})`
  width: 0.35rem;
  height: 0.35rem;
  transition: transform ease-in-out 0.25s;
`

export const CloseButton = styled.button`
  flex: 0 0 auto;
  width: 0.64rem;
  height: 0.64rem;
  background-size: 100% 100%;
  background-image: url('${process.env.PUBLIC_URL}/comic-icons/chapter_list_close.svg');
`

export const ChapterArea = styled.div`
  height: 0;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: var(--cms-grid-column-gap);
  padding: 0 var(--cms-grid-column-gap);
  padding-bottom: calc(env(safe-area-inset-bottom, 0px) + var(--cms-grid-column-gap));
`
export const ChapterItem = styled.button`
  flex: 0 0 auto;
  height: 1.46rem;
  display: flex;
  align-items: center;
  padding-left: 0.41rem;
  background: var(--page-view-novel--chapters-list-item-background);
  color: var(--page-view-novel--chapters-list-item-color);
  border-radius: 0.13rem;
  font-size: 0.41rem;

  border: 1px solid transparent;
  border-color: var(--page-view-novel--chapters-border-color);

  ${(props) =>
    props.active
      ? css`
          border: var(--page-view-novel--chapters-list-item-active-border);
          color: var(--page-view-novel--chapters-list-item-active-color);
        `
      : ''}
`

export const BareListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--main-background);
  border-radius: 5px 5px 0px 0px;
  color: var(--main-color);
`
