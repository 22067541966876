import { useMemo, useCallback, memo, useRef } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { selectAdInfo } from 'redux/selector/app'
//
import { fetchPostAdEvent } from 'api'
//
import { AdvertisementType } from 'constant/advertisement'
import { TRUE, VideoTypeEnum } from 'constant/common'
//
import useOpenModal from './hooks/useOpenModal'
import { useTemplates } from './hooks/useTemplates'
import { SORT_BY_RANDOM } from './constant'
//
import { Wrapper, BtnMono, BtnColorful, AdImage, Bottom, FunctionalArea, ContentWrapper, BottomRow } from './Styled'
import AreaTitleNew from 'component/AreaTitle'
import { formatDate } from 'utils/timeFormat'
import { useViewport } from './hooks/useViewport'
import AreaWrapper from 'component/AreaWrapper'
import { WorkSectionProvider } from 'hooks/useAnalytics'

export default memo(function AreaBox({ info, areaIndex, lazy, onReload, videoData, ...props }) {
  const adInfo = useSelector(selectAdInfo)
  const { trigger, currentLimit, finished } = useViewport({ enabled: lazy, count: videoData?.length ?? 0 })

  const wrapRef = useRef(null)

  const {
    /** 是否顯示標題 */
    title_is_visible,
    /** 標題 */
    title,
    /** 是否顯示區域上方的更多 (更多>) */
    more_isvisible,
    /** 是否顯示區域下方的更多按鈕 */
    show_more_isvisible,
    /** 是否顯示區域下方的換一批按鈕 */
    next_isvisible,
    video_sortby,
    template_id,
    type,
  } = /** @type {import("../../../types/api").SchemaCmsSectionShow} */ (info)

  /** 如果後端回傳 video_sortby 為 random, 則由前端實作 random 邏輯 */
  const isVideoSortByRandom = video_sortby === SORT_BY_RANDOM
  const isShowTitle = title_is_visible === TRUE
  /** 是否顯示區域上方的更多 (更多>) */
  const isShowMore = more_isvisible === TRUE
  /** 是否顯示區域下方的更多按鈕 */
  const isShowButtonMore = show_more_isvisible === TRUE
  /** 是否顯示區域下方的換一批按鈕 */
  const isShowButtonNext = next_isvisible === TRUE

  const { openViewMoreModal } = useOpenModal({
    ...info,
    isVideoSortByRandom,
    type:
      type === VideoTypeEnum.LongVideo
        ? 'video'
        : type === VideoTypeEnum.ShortVideo
        ? 'short'
        : type === VideoTypeEnum.Comic
        ? 'comic'
        : type === VideoTypeEnum.Novel
        ? 'novel'
        : 'video',
  })
  const { templates } = useTemplates()
  const TemplateComponent = templates?.[template_id]

  /** 視頻櫥窗下方廣告位 */
  const adAreaBottom = useMemo(() => {
    const list = adInfo?.[AdvertisementType.AreaBottom].filter(
      (m) => m.filter_section_ids.findIndex((b) => b === info.id) < 0
    )
    if (!list?.length) return
    const adIndex = areaIndex % list?.length
    return list?.[adIndex]
  }, [adInfo, areaIndex, info.id])

  const onAdItemClick = useCallback(() => {
    if (adAreaBottom) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: adAreaBottom.id,
        material_name: adAreaBottom.name,
        oholo_site_id: adAreaBottom.site_id,
        oholoer_id: adAreaBottom.oholoer_id,
      })
      window.open(adAreaBottom?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [adAreaBottom])

  if (!TemplateComponent) return null

  return (
    <WorkSectionProvider SerialNumber={9} Event="cms_click" Trigger="点击区域中影片">
      <Wrapper {...props} ref={wrapRef}>
        <AreaWrapper>
          <ContentWrapper>
            <AreaTitleNew
              text={isShowTitle ? title : ''}
              hasDecoration={isShowTitle}
              hasMore={isShowMore}
              onMore={openViewMoreModal}
            />
            <TemplateComponent key={'0'} videoData={videoData} trigger={trigger} currentLimit={currentLimit} />

            {(finished || !TemplateComponent.lazy) && (
              <Bottom>
                <FunctionalArea>
                  {/** 是否顯示換一批 */}
                  {isShowButtonNext && isShowButtonMore ? (
                    <>
                      {/* use mono and colorful color when both showing */}
                      <BtnMono onClick={onReload}>换一批</BtnMono>
                      <BtnColorful onClick={openViewMoreModal}>查看更多</BtnColorful>
                    </>
                  ) : (
                    <>
                      {/* use colorful color when only one of them showing */}
                      {isShowButtonNext && <BtnColorful onClick={onReload}>换一批</BtnColorful>}

                      {/** 是否顯示查看更多 */}
                      {isShowButtonMore && <BtnColorful onClick={openViewMoreModal}>查看更多</BtnColorful>}
                    </>
                  )}
                </FunctionalArea>
              </Bottom>
            )}
          </ContentWrapper>
        </AreaWrapper>
        {(finished || !TemplateComponent.lazy) && (
          <BottomRow>
            {/** 視頻櫥窗下方廣告位 */}
            {!isEmpty(adAreaBottom) && (
              <AdImage src={adAreaBottom.icon_path} source="preview" aspectRatio={374 / 94} onClick={onAdItemClick} />
            )}
          </BottomRow>
        )}
      </Wrapper>
    </WorkSectionProvider>
  )
})
