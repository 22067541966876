import { fetchBinFile } from 'api'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { selectComicOrigin, selectNovelOrigin, selectPictureOrigin, selectPreviewOrigin } from 'redux/selector/app'
import { unpackAesImages } from 'utils/cryoto'
import { Image, ImageWrapper, NonLazyLoadWrapper } from './Styled'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

const AesImage = forwardRef(function AesImage(
  {
    decryptKey,
    src = '',
    noLazy = false,
    initialAspectRatio = null,
    aspectRatio = null,
    onImagesLoad,
    onImageBlobsLoad,
    draggable = false,
    hasPlaceholder = true,
    placeholder = null,
    // preview, picture, comic
    source = 'comic',
    cached = false,
    allowUnload = false,
    rootClassName = 'lazy-root',
    rootMargin = '100px',
    useExternalHeight = false,
    onDomUpdate = (images) => {},
    wrapperStyle,
    ...props
  },
  externalRef
) {
  const previewOrigin = useSelector(selectPreviewOrigin)
  const pictureOrigin = useSelector(selectPictureOrigin)
  const comicOrigin = useSelector(selectComicOrigin)
  const novelOrigin = useSelector(selectNovelOrigin)
  const currentOrigin =
    source === 'preview'
      ? previewOrigin
      : source === 'comic'
      ? comicOrigin
      : source === 'novel'
      ? novelOrigin
      : pictureOrigin

  const { ref, visible } = useIntersectionObserver({
    /* Optional options */
    defaultVisible: false,
    threshold: 0,
    triggerOnce: !allowUnload,
    rootClassName,
    rootMargin,
  })

  useImperativeHandle(externalRef, () => ref.current, [ref])

  const cache = useRef({})

  const [imageArrayBuffer, setImageArrayBuffer] = useState(/** @type { ArrayBuffer | null } */ (null))
  const [blobs, setBlobs] = useState([])
  const [urls, setUrls] = useState(/** @type {string[]} */ ([]))
  const [animationFinished, setAnimationFinished] = useState(false)
  const fetchedSrc = /** @type { React.MutableRefObject<null|string> } */ (useRef(null))

  useEffect(() => {
    if (!currentOrigin) return

    if (src === '') {
      fetchedSrc.current = src
      return
    }

    if (noLazy || visible) {
      if (fetchedSrc.current !== src) {
        const controller = new AbortController()
        if (cached && cache.current[src]) {
          fetchedSrc.current = src
          setImageArrayBuffer(/** @type {ArrayBuffer} */ (/** @type {any} */ (cache.current[src])))
        }
        fetchBinFile({
          responseType: 'arraybuffer',
          url: currentOrigin.replace(/\/?$/, '/')?.concat(src.replace(/^\//, '')),
          signal: controller.signal,
        })
          .then((arraybuffer) => {
            fetchedSrc.current = src
            setImageArrayBuffer(/** @type {ArrayBuffer} */ (/** @type {any} */ (arraybuffer)))
            if (cached) {
              cache.current[src] = arraybuffer
            }
          })
          .catch(catchPromiseCancel)

        return () => {
          controller.abort()
        }
      }
    } else {
      fetchedSrc.current = null
      setImageArrayBuffer(null)
    }
  }, [visible, noLazy, currentOrigin, src, cached])

  const decryptCache = useRef(new Map())

  useEffect(() => {
    let aborted = false
    let urls = []

    async function updateURL() {
      if (imageArrayBuffer == null) {
        setBlobs([])
        setUrls([])
        setAnimationFinished(false)
        return
      }
      let blobs
      if (cached && decryptCache.current.has(imageArrayBuffer)) {
        blobs = decryptCache.current.get(imageArrayBuffer)
      } else {
        blobs = await unpackAesImages(
          imageArrayBuffer,
          decryptKey,
          [0x00, 0x01, 0x05, 0x03, 0x04, 0x05, 0x09, 0x07, 0x08, 0x09, 0x0a, 0x0b, 0x0c, 0x0d, 0x0e, 0x0f]
        )
      }

      if (aborted) return

      if (cached && !decryptCache.current.has(imageArrayBuffer)) {
        decryptCache.current.set(imageArrayBuffer, blobs)
      }

      setBlobs(blobs)
      urls = blobs.map((b) => URL.createObjectURL(b))
      setUrls(urls)
    }

    updateURL()

    return () => {
      aborted = true
      urls.forEach((u) => URL.revokeObjectURL(u))
      // Do not clear images on cleanup because it cause flicking when switch image
      // setUrls([])
    }
  }, [imageArrayBuffer, decryptKey, cached])

  useEffect(() => {
    if (blobs.length > 0) {
      onImageBlobsLoad?.(blobs)
    }
  }, [onImageBlobsLoad, blobs])

  const tryEmitLoad = useCallback(() => {
    if (urls.length > 0 && loadingImages.current.length === 0) {
      onImagesLoad?.(urls)
    }
  }, [onImagesLoad, urls])

  useEffect(() => {
    tryEmitLoad()
  }, [tryEmitLoad])

  const placeholderRef = /** @type {React.MutableRefObject<HTMLDivElement | null>} */ (useRef(null))
  const imageRefs = useRef([])

  const onImageRefUpdate = (index, dom) => {
    imageRefs.current = Object.assign([], imageRefs.current, { [index]: dom })
  }

  useLayoutEffect(() => {
    if (urls.length === 0) {
      onDomUpdate([placeholderRef.current])
    } else {
      onDomUpdate(imageRefs.current)
    }
  })

  const loadingImages = useRef([])

  /**
   * @param {HTMLImageElement} el
   */
  const onImageElRefed = useCallback(
    (el) => {
      if (el == null) {
        return
      }

      if (el.complete) {
        return
      }

      const doneLoading = () => {
        el.removeEventListener('load', doneLoading)
        el.removeEventListener('error', doneLoading)

        loadingImages.current = loadingImages.current.filter((i) => i !== el)

        tryEmitLoad()
      }

      el.addEventListener('load', doneLoading)
      el.addEventListener('error', doneLoading)

      loadingImages.current = [...loadingImages.current.filter((i) => i !== el), el]
    },
    [tryEmitLoad]
  )

  const imageJSX = useMemo(() => {
    if ((aspectRatio != null || initialAspectRatio != null) && urls.length === 0) {
      // placeholder when aspect ratio is set but image not loaded yet */
      return [
        <ImageWrapper
          key={0}
          hasPlaceholder={hasPlaceholder}
          placeholder={placeholder}
          ref={placeholderRef}
          {...props}
          aspectRatio={initialAspectRatio ?? aspectRatio}
        >
          <NonLazyLoadWrapper
            aspectRatio={initialAspectRatio ?? aspectRatio}
            useExternalHeight={useExternalHeight}
          ></NonLazyLoadWrapper>
        </ImageWrapper>,
      ]
    }

    return urls.map((url, index) => (
      <ImageWrapper
        hasPlaceholder={hasPlaceholder}
        placeholder={placeholder}
        ref={(d) => onImageRefUpdate(index, d)}
        key={index + '_' + url}
        {...props}
        aspectRatio={aspectRatio}
      >
        <NonLazyLoadWrapper aspectRatio={aspectRatio} useExternalHeight={useExternalHeight}>
          <Image
            animation={Boolean(hasPlaceholder && !animationFinished)}
            draggable={draggable}
            aspectRatio={aspectRatio}
            src={url}
            ref={onImageElRefed}
            onAnimationEnd={() => setAnimationFinished(true)}
          />
        </NonLazyLoadWrapper>
      </ImageWrapper>
    ))
  }, [
    animationFinished,
    aspectRatio,
    draggable,
    hasPlaceholder,
    initialAspectRatio,
    onImageElRefed,
    placeholder,
    props,
    urls,
    useExternalHeight,
  ])

  return (
    <div ref={ref} style={wrapperStyle}>
      {imageJSX}
    </div>
  )
})

export default AesImage
