import { useImmer } from 'use-immer'
import { useCallback, useEffect, useRef } from 'react'
import { fetchNovelChapters } from 'api'

export default function useNovelChapters(apiParams) {
  const fetching = useRef(null)

  const [state, updateState] = useImmer(() => ({
    currentOptions: apiParams,
    list: [],
    nextPage: 1,
    hasMore: true,
    page_result: {},
  }))

  useEffect(() => {
    if (JSON.stringify(apiParams) !== JSON.stringify(state.currentOptions)) {
      fetching.current?.abort()
      fetching.current = null
      updateState((arg) => {
        arg.currentOptions = apiParams
        arg.list = []
        arg.hasMore = true
        arg.nextPage = 1
        arg.page_result = {}
        arg.tag_version = ''
      })
    }
  }, [apiParams, state.currentOptions, updateState])

  const fetchMore = useCallback(() => {
    if (!state.hasMore) return
    if (fetching.current != null) return
    const fetcherController = new AbortController()

    fetching.current = fetcherController

    const data = {
      signal: fetcherController.signal,
      current: state.nextPage,
      pageSize: 30,
      ...state.currentOptions,
    }

    fetchNovelChapters(data)
      .then((response) => {
        if (fetching.current === fetcherController) {
          fetching.current = null
        }
        updateState((draft) => {
          draft.list = draft.list.concat(response?.data)
          draft.hasMore =
            response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
          draft.nextPage = response?.page_result?.current + 1
          draft.page_result = response.page_result
        })
      })
      .catch(() => {
        if (fetching.current === fetcherController) {
          fetching.current = null
        }
      })
  }, [state.currentOptions, state.hasMore, state.nextPage, updateState])

  return {
    state,
    fetchMore,
  }
}
