import { RankTagN, RankTag1, RankTag2, RankTag3 } from "./Styled"

export default function RankTag({index}:{index: number}){
    if (index === 0) {
        return <RankTag1></RankTag1>
      }
      if (index === 1) {
        return <RankTag2></RankTag2>
      }
      if (index === 2) {
        return <RankTag3></RankTag3>
      }
      else {
        return <RankTagN>{index + 1}</RankTagN>
      }
}