const styles = {
  overlay: {
    zIndex: 99,
    backgroundColor: 'rgba(16, 4, 27, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'transparent',
    padding: 0,
    borderRadius: 0,
    color: 'var(--main-color)',
  },
}

export function useModalStyles() {
  return {
    styles,
  }
}
