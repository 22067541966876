import lottie from 'lottie-web'
import { useEffect, useRef } from 'react'
import { AnimationWrap } from './Styled'

const Animation = ({ animationData: animationDataI, paused = false, pausedAt = 0, ...props }) => {
  const animationDataRef = useRef()
  if (!animationDataRef.current) {
    animationDataRef.current = JSON.parse(JSON.stringify(animationDataI))
  }
  const animationData = animationDataRef.current
  const ref = useRef()
  /**
   * @type {import('react').MutableRefObject<import('lottie-web').AnimationItem>}
   */
  const animationInst = useRef()

  useEffect(() => {
    if (ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      })

      animationInst.current = animation

      return () => {
        animation.destroy()
        animationInst.current = null
      }
    }
    // IT IS INTENTIONAL TO MISS active HERE, DO NOT ADD
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData])

  useEffect(() => {
    // console.log('run', animationInst.current, animationInst.current.isPaused, paused)
    if (animationInst.current && animationInst.current.isPaused !== paused) {
      if (paused) {
        animationInst.current.pause()
        animationInst.current.goToAndStop(pausedAt, false)
      } else {
        animationInst.current.play()
      }
    }
  }, [paused, pausedAt])

  return <AnimationWrap {...props} ref={ref} />
}

export default Animation
