const getState = () => ({
  currentTransitionSpeed: 0,
})

/**
 *
 * @param {import('swiper').Swiper} swiper
 * @param {*} progress
 * @returns
 */
const progress = (swiper, progress) => {
  // console.log('progress', progress)
  // console.log(progress)
  /* 
  if you need to change something for each progress
  do it here (progress variable is always in range from 0 to 1) representing progress of the whole slider 
  */
  // convert slides object to plain array
  const slides = [...swiper.slides]
  if (slides.length === 0) {
    return
  }

  // get information about animation progress from the active slide - the active slide's value is always -1 to 1.
  /* 
  every slide has a progress attribute equal to the "distance" from the current active index.
  */
  // do magic with each slide

  // Start(disappear point) => Middle => End

  const centerOffset = `translateX(${swiper.width / 2}px) translateX(-50%)`
  const swiperWidth = swiper.isHorizontal() ? swiper.width : swiper.height
  const slideCount = slides.length
  const slideWidth = swiperWidth / swiper.params.slidesPerView

  // console.log('set', xCenterStart, xCenterMiddle, xCenterEnd)

  const maxDepth = swiper.params.flatEffect.depth
  const gap = swiper.params.spaceBetween ?? 0

  // slide progress order in 3 2 1 0 -1 ...
  slides.forEach((slide, index) => {
    // console.log(slide.swiperSlideOffset, slide.progress)
    // to put the slides behind each other we have to set their CSS translate accordingly since by default they are arranged in line.
    const offset = slide.swiperSlideOffset
    if (isNaN(offset) || isNaN(slide.progress)) {
      return
    }
    let x = -offset
    if (!swiper.params.virtualTranslate) x -= swiper.translate
    let y = 0
    if (!swiper.isHorizontal()) {
      y = x
      x = 0
    }

    slide.x = x
    slide.y = y

    const correctedProgress =
      slide.progress > slideCount / 2
        ? slide.progress - slideCount
        : slide.progress < -slideCount / 2
        ? slide.progress + slideCount
        : slide.progress

    const opacity = 1

    const xOffset = correctedProgress * -(slideWidth + gap)
    const zOffset = Math.abs(correctedProgress) * -maxDepth

    // console.log(slide.swiperSlideOffset, slide.progress, correctedProgress, scale)
    // you can do your CSS animation instead of using tweening.
    // slide.scale = scale
    slide.opacity = opacity
    const transform = `${centerOffset} translate(${slide.x}px, ${slide.y}px) translateX(${xOffset}px) translateZ(${zOffset}px)`
    // slide.dataset.transform = transform
    slide.style.transform = transform
    slide.style.zIndex = -Math.round(correctedProgress)
    slide.style.opacity = String(slide.opacity)
    slide.style.pointerEvents = 'auto'
    // slide.style.pointerEvents = Math.round(correctedProgress) < 0 ? 'none' : 'auto'

    const prevProgress = slide.dataset['progress'] ? Number(slide.dataset['progress']) : null
    if (prevProgress != null) {
      // we are doing a 'jump here'
      if (Math.abs(prevProgress - correctedProgress) > 1) {
        slide.dataset.savedTransition = slide.style.transition
        slide.style.transition = 'none'
        slide.getBoundingClientRect()
        // console.log('doing a hump', prevProgress, correctedProgress)
      } else {
        if (slide.dataset.savedTransition) {
          slide.style.transition = slide.dataset.savedTransition
          delete slide.dataset.savedTransition
        }
      }
    }
    slide.dataset['progress'] = String(correctedProgress)
  })
}
const setTransition = (state, swiper, transitionSpeed) => {
  // console.log('transition', transitionSpeed)
  state.currentTransitionSpeed = transitionSpeed
  // console.log("transition", transitionSpeed);
  const slides = [...swiper.slides]
  if (slides.length === 0) {
    return
  }
  slides.forEach((slide) => {
    if (transitionSpeed > 0) {
      slide.style.transition = `transform ${transitionSpeed / 1000}s`
    } else {
      slide.style.transition = `none ${transitionSpeed / 1000}s`
    }
  })
}

const setTranslate = (state, swiper, wrapperTranslate) => {
  // console.log('translate', wrapperTranslate)
}

export default function EffectFlat({ swiper, extendParams, on }) {
  extendParams({
    speed: 1000,
  })

  on('beforeInit', () => {
    if (swiper.params.effect !== 'flat') return
    const overwriteParamsResult = {
      virtualTranslate: true,
      watchSlidesProgress: true,
      centeredSlides: true,
    }
    Object.assign(swiper.params, overwriteParamsResult)
    Object.assign(swiper.originalParams, overwriteParamsResult)
    const originalOptions = swiper.params.flatEffect ?? {}
    const mergedOptions = {}
    Object.assign(mergedOptions, { depth: 300 }, originalOptions)
    swiper.params.flatEffect = mergedOptions
    swiper.originalParams.flatEffect = mergedOptions

    swiper.el.style.perspective = '1200px'
    swiper.el.style.perspectiveOrigin = '50% 50%'
    swiper.el.querySelector(':scope .swiper-wrapper').style.transformStyle = 'preserve-3d'
    swiper.el.querySelector(':scope .swiper-wrapper').style.pointerEvents = 'none'
  })

  on('progress', (swiper, curProgress) => {
    if (swiper.params.effect !== 'flat') return
    progress(swiper, curProgress)
  })

  on('setTransition', (swiper, transition) => {
    if (swiper.params.effect !== 'flat') return
    const state = swiper.__state_stack || (swiper.__state_stack = getState())
    setTransition(state, swiper, transition)
  })

  on('setTranslate', (swiper, translate) => {
    if (swiper.params.effect !== 'flat') return
    const state = swiper.__state_stack || (swiper.__state_stack = getState())
    setTranslate(state, swiper, translate)
  })
}
