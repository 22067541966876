import { EncryptedDiv } from 'component/EncryptedElements'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #10041b;
  font-size: 0.426rem;
  font-weight: bold;
`

export const BgImg = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/profile-icon/bg-account-credential.enc.png`,
})`
  position: relative;
  width: 8rem;
  height: 10.48rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const QRCodeWrapper = styled.div`
  position: absolute;
  right: 0.2915rem;
  bottom: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.7rem;
  height: 3.6rem;
  background-color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.2307rem;
`

export const TextBox = styled.div`
  position: absolute;
  left: 0.3076rem;
  bottom: 1.1rem;
  display: flex;
  flex-direction: column;
  gap: 0.2307rem;
  font-size: 0.266rem;
  color: #ffffff;
`

export const TextBoxTitle = styled.div`
  font-weight: bold;
  font-size: 0.928rem;
  letter-spacing: 0.513192px;

  text-shadow: 0px 1.06452px 4.25806px rgba(0, 0, 0, 0.25), 0px 2.12903px 8.51613px rgba(0, 0, 0, 0.36);
`

export const BtnCopySharingUrl = styled.button`
  width: 3.3076rem;
  height: 0.923rem;
  background: linear-gradient(180deg, #b351ff 0%, #cd69fe 100%);
  border: 1px solid #cd69fe;
  border-radius: 100px;
  font-size: 0.3589rem;
`

export const WebSitUrl = styled.div`
  color: #000;
  font-size: 0.2564rem;
  position: absolute;
  bottom: 0.5rem;
  text-align: center;
  left: 2rem;
`

export const Link = styled.a``
