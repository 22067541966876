import { useNavigateTo } from 'hooks/useNavigateTo'
import { useCallback } from 'react'

export const useOpenNovelChapter = () => {
  const { navigateToNovelChapter } = useNavigateTo()
  const openNovelChapter = useCallback(
    (novelId, chapterInfo) => {
      navigateToNovelChapter({
        novelId,
        chapterInfo,
      })
    },
    [navigateToNovelChapter]
  )

  return {
    openNovelChapter: openNovelChapter,
  }
}
