import { actions as app } from 'redux/slice/app'
import { actions as user } from 'redux/slice/user'
import { actions as modal } from 'redux/slice/modal'
import { actions as timer } from 'redux/slice/timer'
import { actions as deposit } from 'redux/slice/deposit'
import { actions as favourite } from 'redux/slice/favourite'
import { actions as collection } from 'redux/slice/collection'
import { actions as shortFavourite } from 'redux/slice/shortFavourite'
import { actions as shortCollection } from 'redux/slice/shortCollection'
import { actions as comicCollection } from 'redux/slice/comicCollection'
import { actions as novelCollection } from 'redux/slice/novelCollection'
import { actions as history } from 'redux/slice/history'
import { actions as game } from 'redux/slice/game'

const actions = {
  ...app,
  ...user,
  ...modal,
  ...timer,
  ...deposit,
  ...favourite,
  ...collection,
  ...shortFavourite,
  ...shortCollection,
  ...comicCollection,
  ...novelCollection,
  ...history,
  ...game,
}

export default actions
