import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'redux/action'
import { selectPreference } from 'redux/selector/user'

export const usePreferenceItem = (name, defaultValue) => {
  const preference = useSelector(selectPreference)
  const dispatch = useDispatch()
  const value = preference[name] ?? defaultValue ?? null
  const setValue = useCallback(
    (valueOrFunc) => {
      let nextValue
      if (typeof valueOrFunc === 'function') {
        nextValue = valueOrFunc(value)
      } else {
        nextValue = valueOrFunc
      }

      const newPref = { ...preference, [name]: nextValue }
      if (nextValue == null) {
        delete newPref[name]
      }
      dispatch(actions.updatePreference(newPref))
    },
    [dispatch, name, preference, value]
  )

  const obj = useMemo(() => [value, setValue], [setValue, value])
  return obj
}
