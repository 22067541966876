import Shortcut from './component/Shortcut'
import { TRUE } from 'constant/common'
//
import { Wrapper } from './Styled'
import { forwardRef } from 'react'

/**
 * 元件 - 金剛區
 * @param {import('../../../types/api').SchemaCmsTabDetail} tabInfo
 * @returns {import('react').ReactNode}
 */
export default forwardRef(function ShortcutBox(tabInfo, ref) {
  /** 是否顯示金剛區 */
  const isShowShortcut = tabInfo?.show_iconui === TRUE && !!tabInfo?.iconuis?.length

  /**
   * 金剛區模板
   * - 三個居中 - 1
   * - 四個居中 - 2
   * - 三個半 - 3 (停用)
   * - 四個半 - 4
   * - 四個換行 - 5
   * - 五個左右滑 - 6
   */
  const template = tabInfo?.iconui_template
  /** 金剛區資訊 */
  const items = tabInfo?.iconuis

  if (!isShowShortcut) return null

  return (
    <Wrapper template={template} ref={ref}>
      {items?.map((item, i) => {
        return <Shortcut key={item.id} info={item} />
      })}
    </Wrapper>
  )
})
