import styled, { css } from 'styled-components'
import IconHistoryRaw from './assets/history.svg'
import IconLikeRaw from './assets/like.svg'
import IconCollectionRaw from './assets/collection.svg'
import IconAppCenterRaw from './assets/app-center.svg'
import IconPromoteRaw from './assets/promote.svg'
import IconShareRaw from './assets/share.svg'
import IconPurchaseRaw from './assets/purchase.svg'
import { ColoredIcon } from 'component/ColoredIcon'
import { EncryptedDiv } from 'component/EncryptedElements'

const FlexBox = styled.div`
  display: flex;
`

export const Wrapper = styled.div`
  position: relative;
  padding: 0.64rem var(--cms-grid-column-gap);
  height: 100%;
  overflow: hidden auto;
  padding-top: calc(0.61rem + env(safe-area-inset-top, 0px));
`

export const ConfigButton = styled.div`
  position: relative;
  margin-bottom: 0.32rem;
  margin-left: auto;
  width: 0.5rem;
  height: 0.5rem;
  color: var(--page-profile--name-box-color);
  cursor: pointer;
  &:after {
    display: block;
    position: absolute;
    content: '';
    left: var(--button-live-zone-padding);
    right: var(--button-live-zone-padding);
    top: var(--button-live-zone-padding);
    bottom: var(--button-live-zone-padding);
  }
`

export const IconSystemConfig = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/garage.png`,
})`
  width: 100%;
  height: 100%;
`

export const TitleBox = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-size: 0.506rem;
  font-weight: 500;
  margin: 0.533rem 0 0.373rem 0;
`

export const Box1 = styled(FlexBox)`
  align-items: center;
  position: relative;
  margin-bottom: 0.853rem;
`

export const Box2 = styled(FlexBox)`
  flex-direction: column;
  position: relative;
  margin-bottom: 0.533rem;
  height: 2.08rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/profile_bg_1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const Box3 = styled(FlexBox)`
  gap: 0.666rem calc((100% - (1.76rem * 4)) / 3);
  flex-wrap: wrap;
  position: relative;
  font-size: 0.32rem;
  margin-bottom: 0.533rem;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.266rem;
  overflow: hidden;
  flex: 1 1 auto;
  min-width: 0;
  color: var(--page-profile--name-box-color);
`

export const Avatar = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`

export const NameBox = styled.div`
  font-size: 0.5128rem;
  font-weight: bold;
`

export const IDBox = styled.div`
  font-size: 0.3589rem;
`

export const VipInfoBox = styled.div`
  font-size: 0.25rem;
`

export const RegisterBox = styled.div`
  font-size: 0.3589rem;
  letter-spacing: 0.3px;
  cursor: pointer;
  padding: 0.2rem 0.4rem;
  align-self: center;
  background: var(--page-profile--register-box-background);
  color: var(--page-profile--register-box-color);
  border-radius: 24px;
`

export const GoldenLine = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 0.026rem;
  height: 0.426rem;
  background-color: #fbe4ad;
`

export const DepositArea = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 0.373rem;

  > div {
    flex: 1;
    display: flex;
    gap: 0.133rem;
    padding: 3% 0;
    cursor: pointer;
  }
`

export const DepositTitle = styled.div`
  font-size: 0.48rem;
  font-weight: 500;
  margin-bottom: 0.106rem;
`

export const GoldenBall = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #fbe4ad 10%, #db9151);
`

export const IconVip = styled.i`
  width: 0.32rem;
  height: 0.266rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/vip.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export const IconCoin = styled.i`
  width: 0.266rem;
  height: 0.266rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/coin.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export const VerticalBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1.76rem;
  cursor: pointer;
`

export const Box3Icon = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  margin-bottom: 0.32rem;
  background-repeat: no-repeat;
  background-position: center;

  &.icon-1 {
    background-image: url(${process.env.PUBLIC_URL}/icon/shopping-cart.png);
    background-size: contain;
  }
  &.icon-2 {
    background-image: url(${process.env.PUBLIC_URL}/icon/heart.png);
    background-size: contain;
  }
  &.icon-3 {
    background-image: url(${process.env.PUBLIC_URL}/icon/cloud.png);
    background-size: contain;
  }
  &.icon-4 {
    background-image: url(${process.env.PUBLIC_URL}/icon/promote.png);
    background-size: contain;
  }
  &.icon-5 {
    background-image: url(${process.env.PUBLIC_URL}/icon/customer-service.png);
    background-size: contain;
  }
  &.icon-6 {
    background-image: url(${process.env.PUBLIC_URL}/icon/chat.png);
    background-size: contain;
  }
  &.icon-7 {
    background-image: url(${process.env.PUBLIC_URL}/icon/btob.png);
    background-size: contain;
  }
`

export const VipBox = styled.div`
  justify-content: flex-end;
  margin-right: 1.12rem;
`

export const DepositBox = styled.div`
  justify-content: flex-start;
  margin-left: 1.12rem;
`

export const Area = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.4rem;
`

export const Area3Title = styled.div`
  margin-top: 0.5rem;
  font-weight: bold;
`

export const Area3 = styled(FlexBox)`
  margin-top: 0.2rem;
  align-items: center;
  flex-wrap: wrap;
  --item-count: 4;
  gap: var(--page-profile--area3-item-gap-y) var(--page-profile--area3-item-gap-x);
`

export const SmallButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: calc((100% - calc(var(--page-profile--area3-item-gap-x) * calc(var(--item-count) - 1))) / var(--item-count));
  height: 2.2435rem;
  font-weight: 400;
  font-size: 0.3589rem;
  line-height: 120%;
  letter-spacing: 0.38px;
  color: var(--page-profile--button-color);
  background: var(--page-profile--item-background);
  border-radius: 0.25rem;
  filter: var(--page-profile--button-filter);

  > img {
    width: 0.7692rem;
    height: 0.7692rem;
  }
`

export const Area2 = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin: 0.2rem 0;
  background: var(--page-profile--item-background);
  border-radius: 0.25rem;
  box-shadow: var(--page-profile--wallet-box-shadow);
`

export const Area2Separator = styled.div`
  width: 0.05rem;
  background: #f9f9f9;
  margin: 0.2rem 0;
`

export const LongButton = styled.button`
  padding: 0.8rem 0.4rem;
  padding-left: 1.2rem;
  background: no-repeat 0.2rem center;
  background-size: 1.2rem;
  &.icon-diamond {
    background-image: url(${process.env.PUBLIC_URL}/account/icon-diamond.svg);
  }
  &.icon-coin {
    background-image: url(${process.env.PUBLIC_URL}/account/icon-coin.svg);
  }
`

export const LongButtonText = styled.div`
  background: linear-gradient(97.69deg, #f4b30c 1.98%, #f47c0c 82.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
`

export const PageDecoration = styled(EncryptedDiv).attrs({
  backgroundImage: process.env.REACT_APP_PROFILE_BACKGROUND_ENCRYPTED_IMAGE_PATH
    ? `${process.env.PUBLIC_URL}${process.env.REACT_APP_PROFILE_BACKGROUND_ENCRYPTED_IMAGE_PATH}`
    : undefined,
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--page-profile--decoration-background-height, 4.5rem) + env(safe-area-inset-top));
  background: black;
  background: var(
    --page-profile--decoration-background,
    url(${process.env.PUBLIC_URL}/decorations/profile-decoration.jpg)
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
`

const commonGradientStyle = css`
  background: var(--page-profile--button-icon-color);
  mask-repeat: no-repeat;
  mask-size: contain;
  width: 0.641rem;
  height: 0.641rem;
`

export const IconHistory = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconHistoryRaw});
`
export const IconLike = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconLikeRaw});
`
export const IconCollection = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconCollectionRaw});
`
export const IconAppCenter = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconAppCenterRaw});
`
export const IconPromote = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconPromoteRaw});
`
export const IconShare = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconShareRaw});
`
export const IconPurchase = styled.div`
  ${commonGradientStyle}
  mask-image: url(${IconPurchaseRaw});
`

export const CopyButton = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/copy.png`,
})`
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.1rem;
`
