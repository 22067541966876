import { useCallback } from 'react'
//
import { Wrapper } from './Styled'
import Base64Image from 'component/Base64Image'
import { useNavigateTo } from 'hooks/useNavigateTo'

const loadingImage = `/loading/small-load-image.svg`

export default function StaticItem({ showName, name, params, icon_path, placeholder, tall = false }) {
  const { navigateToSecTagList } = useNavigateTo()
  const handleClick = useCallback(() => {
    navigateToSecTagList({
      headerTitle: name,
      params: params ?? {},
    })
  }, [name, navigateToSecTagList, params])

  return (
    <Wrapper onClick={handleClick}>
      {/* <Image ref={imgRef} src={loadingImage} tall={tall} /> */}
      <Base64Image
        src={icon_path}
        placeholder={placeholder ?? loadingImage}
        aspectRatio={tall ? 1 / 1.7 : 1}
        noLazy
      ></Base64Image>
      {showName && <span>{name}</span>}
    </Wrapper>
  )
}
