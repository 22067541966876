import { useCallback, useRef } from 'react'
import { useEvent } from './useEvent'
import styled from 'styled-components'

const HiddenElement = styled.div`
  display: contents;
`

/**
 *
 * @param {{ onScroll: (ev: UIEvent) => void; }} param0
 */
export const useAscenderScrollEvent = ({ onScroll }) => {
  const onScrollEvent = useEvent(onScroll)

  const observer = useRef(undefined)
  const setupObserver = useCallback(
    (el) => {
      if (observer.current) {
        observer.current()
        observer.current = null
      }

      const container = el.parentElement

      /**
       * @type {HTMLElement | undefined}
       */
      let scrollElement

      while (container != null) {
        const computedStyle = getComputedStyle(container)
        if (
          computedStyle.overflowX === 'auto' ||
          computedStyle.overflowX === 'scroll' ||
          computedStyle.overflowY === 'auto' ||
          computedStyle.overflowY === 'scroll'
        ) {
          scrollElement = container
          break
        }
      }

      if (scrollElement == null) {
        return
      }

      const handler = (ev) => {
        onScrollEvent(ev)
      }

      scrollElement.addEventListener('scroll', handler)

      observer.current = () => {
        scrollElement.removeEventListener('scroll', handler)
      }
    },
    [onScrollEvent]
  )

  const refCallback = useCallback(
    (el) => {
      if (el) {
        setupObserver(el)
      } else {
        observer.current?.()
        observer.current = undefined
      }
    },
    [setupObserver]
  )
  const jsx = <HiddenElement ref={refCallback} />

  return jsx
}
