import styled from 'styled-components'
import PageLoadingIcon from 'component/Loading/PageLoadingIcon'
export const TabWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const HeaderContents = styled.div``

export const TabOverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* background: var(--main-background); */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

export const LoadingWrapper = styled.div`
  display: contents;
  &.loading {
    display: none;
  }
`

export const StyledAnimation = PageLoadingIcon
