import { fetchComicChapters } from 'api'
import { useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

export const useAllComicChapters = (comicId, disabled) => {
  const apiParams = {
    comic_id: comicId,
  }

  const [state, updateState] = useImmer(() => {
    let resolve
    const promise = new Promise((r) => {
      resolve = r
    })
    return {
      currentOptions: apiParams,
      list: /** @type {import('../../types/api').SchemaComicChapterShow[]} */ ([]),
      nextPage: 1,
      hasMore: true,
      page_result: {},
      promise,
      resolve,
    }
  })

  useEffect(() => {
    const fetcherController = new AbortController()

    if (disabled) {
      return
    }

    if (!state.hasMore) {
      state.resolve(state.list)
      return
    }

    const data = {
      signal: fetcherController.signal,
      current: state.nextPage,
      pageSize: 100,
      ...state.currentOptions,
    }

    fetchComicChapters(data).then((response) => {
      updateState((draft) => {
        draft.list = draft.list.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result
      })
    })

    return () => {
      fetcherController.abort()
    }
  }, [disabled, state, state.currentOptions, state.hasMore, state.nextPage, updateState])

  const mappedList = useMemo(() => {
    return Object.assign([...state.list], { finished: false, promise: state.promise })
  }, [state.list, state.promise])

  return mappedList
}
