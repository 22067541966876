import { Fragment, useCallback, useMemo, useRef } from 'react'
import { useImmer } from 'use-immer'
//
//
import { fetchComicList, fetchNovelList, fetchShortList, fetchVideoList } from 'api'
//
//
import VideoBox from 'component/VideoBox'
import VideoBoxVertical from 'component/VideoBoxVertical'
//
import ComicBox from 'component/ComicBox'
import { AreaPanelCmsGridWrapper, InfiniteScrollWrapper, PageScrollWrap } from 'commonStyledComponents'
import NovelBox from 'component/NovelBox'
import PullToRefresh from 'react-simple-pull-to-refresh'
import PagePullLoad from 'component/Loading/PagePullLoad'
import VideoRankBox from 'component/VideoRankBox'
import EmptyResult from 'component/EmptyResult'
import PageLoadingIcon from 'component/Loading/PageLoadingIcon'
import { TabOverLay } from 'page/RankViewMore/Styled'

// 排行榜僅有前100筆
const MAX_VIDEO_COUNT = 100

export default function VideoTab({ type = 'video', params }) {
  // const adInfo = useSelector(selectAdInfo)
  // const adViewMore = useMemo(() => {
  //   return adInfo?.[AdvertisementType.ViewMore]
  // }, [adInfo])
  // 排行榜不需要亂數排序

  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],
    page_result: { current: null, pageSize: null, total: null },
  })
  const loading = useRef(false)

  const VideoBoxComponent =
    type === 'video'
      ? VideoRankBox
      : type === 'short'
      ? VideoBoxVertical
      : type === 'comic'
      ? ComicBox
      : type === 'novel'
      ? NovelBox
      : VideoBox // as default

  const itemPerRow = type === 'video' ? 1 : type === 'short' ? 2 : type === 'comic' ? 3 : type === 'novel' ? 3 : 2 // as default

  const fetchVideoAPi =
    type === 'video'
      ? fetchVideoList
      : type === 'short'
      ? fetchShortList
      : type === 'comic'
      ? fetchComicList
      : type === 'novel'
      ? fetchNovelList
      : fetchVideoList

  const handleFetchVideoList = useCallback(async () => {
    if (!state.hasMore) return

    const data = {
      current: state.nextPage,
      pageSize: MAX_VIDEO_COUNT, // 需要為100的因數
      ...params,
    }

    if (loading.current) return
    loading.current = true

    const response = await fetchVideoAPi(data)
    let total = response?.page_result?.total

    if (total) {
      let next = state.nextPage

      data.current = next

      let items = response?.data || []

      total = Math.min(total, MAX_VIDEO_COUNT)
      const hasMore = total > response?.page_result?.current * response?.page_result?.pageSize

      updateState((draft) => {
        draft.list = draft?.list?.concat(items).filter((m, i) => i < MAX_VIDEO_COUNT)
        draft.hasMore = hasMore
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result
        draft.isLoading = false
      })
      loading.current = false
    } else {
      updateState((draft) => {
        draft.list = []
        draft.hasMore = false
        draft.nextPage = 1
        draft.isLoading = false
      })
    }
  }, [fetchVideoAPi, params, state, updateState])

  const handleRefresh = useCallback(async () => {
    // load event will be triggered after update state
    updateState((draft) => {
      draft.list = []
      draft.hasMore = true
      draft.nextPage = 1
      draft.page_result = { current: null, pageSize: null, total: null }
      draft.isLoading = true
    })
  }, [updateState])

  const shortInfo = useMemo(() => {
    return {
      list: state.list,
    }
  }, [state.list])

  return (
    <PageScrollWrap className="lazy-root">
      <PullToRefresh
        onRefresh={handleRefresh}
        pullingContent={<PagePullLoad paused />}
        refreshingContent={<PagePullLoad />}
      >
        <InfiniteScrollWrapper
          pageStart={0}
          loadMore={handleFetchVideoList}
          hasMore={state?.hasMore}
          // loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {state.isLoading ? (
            <TabOverLay>
              <PageLoadingIcon />
            </TabOverLay>
          ) : (
            state.list.length === 0 && (
              <TabOverLay>
                <EmptyResult />
              </TabOverLay>
            )
          )}
          {state?.list?.length > 0 && (
            <AreaPanelCmsGridWrapper>
              {state?.list?.map((item, i) => {
                /** 每四行 放置一個 視頻櫥窗更多廣告位 */
                // const itemsPerAd = itemPerRow * 4
                // const isPutAdItem = showAd ? (i + 1) % itemsPerAd === 0 : false
                // const adIndex = ((i + 1) / itemsPerAd) % adViewMore?.length
                // const adItemInfo = adViewMore?.[adIndex]

                return (
                  <Fragment key={i}>
                    {type === 'video' && (
                      <VideoBoxComponent columns={itemPerRow} width={'100%'} index={i} videoInfo={item} />
                    )}
                    {type === 'short' && (
                      <VideoBoxComponent columns={itemPerRow} videoInfo={item} shortInfo={shortInfo} />
                    )}
                    {type === 'comic' && <VideoBoxComponent columns={itemPerRow} comicInfo={item} />}
                    {type === 'novel' && <VideoBoxComponent columns={itemPerRow} novelInfo={item} />}
                    {/* {isPutAdItem && !isEmpty(adItemInfo) && <AdItem {...adItemInfo} />} */}
                  </Fragment>
                )
              })}
            </AreaPanelCmsGridWrapper>
          )}
        </InfiniteScrollWrapper>
      </PullToRefresh>
    </PageScrollWrap>
  )
}
