import { useMutex } from './useMutex'
import { useToastAlert } from './useToastAlert'

export default function useAlertNotDeveloped() {
  const { toast } = useToastAlert()
  const { wrap: mutex } = useMutex()

  const alertNotDeveloped = mutex(() => {
    toast('敬请期待...')
    return new Promise((res) => {
      setTimeout(() => {
        res()
      }, 2000)
    })
  })

  return alertNotDeveloped
}
