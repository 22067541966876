import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay, Pagination, Thumbs } from 'swiper/modules'
import { selectAdInfo } from 'redux/selector/app'
//
import { TRUE } from 'constant/common'
import { AdvertisementType } from 'constant/advertisement'
//
import CarouselItem from './component/CarouselItem'
import { SwiperWrapper } from './Styled'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { report } from 'hooks/useAnalytics'

const SWIPER_STATE_CHANGE_DELAY = 500
const SWIPER_RESUME_DELAY = 3000
// wait for css snap to end
const TOUCH_END_DETECT_DELAY = 500

function Carousel(tabInfo) {
  const adInfo = useSelector(selectAdInfo)

  const adItems = useMemo(() => {
    return adInfo?.[AdvertisementType.NavBanner]
  }, [adInfo])

  /** 是否顯示廣告輪播圖 */
  const isShowCarouselAdvertisement = useMemo(() => {
    return tabInfo?.show_ad === TRUE
  }, [tabInfo])

  /** 自動切換延遲時間 */
  const delay = 1000 * (adItems?.[0]?.play_time || 2.5)
  const speed = 1000 * (adItems?.[0]?.carousel_time || 0.3)

  const { ref, visible } = useIntersectionObserver({ defaultVisible: false })

  const [loaded, setLoaded] = useState(false)

  const [hasInteraction, setHasInteraction] = useState(false)
  const [pauseUntil, setPauseUntil] = useState(0)

  const inst = useRef()

  useEffect(() => {
    if (inst.current) {
      /**
       * @type {import("swiper/react").SwiperRef['swiper']}
       */
      const swiper = inst.current.swiper
      if (hasInteraction) {
        return
      }

      const now = Date.now()
      const stateDelay = Math.max(pauseUntil - now, SWIPER_STATE_CHANGE_DELAY)
      const timeoutId = setTimeout(() => {
        if (visible) {
          swiper.params.autoplay.enabled = true
          swiper.params.autoplay.delay = delay

          if (swiper.autoplay.running) {
            swiper.autoplay.stop()
          }

          setLoaded(true)
          userStartIndex.current = null
          swiper.autoplay.start()
        } else {
          swiper.autoplay.stop()
        }
      }, stateDelay)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [delay, hasInteraction, pauseUntil, visible])

  const userStartIndex = useRef(null)

  const onAutoPlayStop = useCallback((swiper) => {
    setHasInteraction(true)
  }, [])

  const onTouchStart = useCallback((swiper) => {
    userStartIndex.current = swiper.realIndex
  }, [])

  const onTouchEnd = useCallback(
    /**
     * @param {import("swiper/react").SwiperRef['swiper']} swiper
     */
    (swiper) => {
      setTimeout(() => {
        const endIndex = swiper.realIndex
        if (userStartIndex.current != null && userStartIndex.current !== endIndex) {
          userStartIndex.current = null
          report({
            SerialNumber: 7,
            Event: 'cms_swipe',
            Trigger: '滑動頻道輪播',
            Parameters: 'channel_carousel_id',
            Value: adItems[endIndex].id,
          })
        }
      }, TOUCH_END_DETECT_DELAY)

      setHasInteraction(false)
      setPauseUntil(Date.now() + SWIPER_RESUME_DELAY)
    },
    [adItems]
  )
  const onSlideChange = useCallback(
    (swiper) => {
      const endIndex = swiper.realIndex
      if (userStartIndex.current != null && userStartIndex.current !== endIndex) {
        userStartIndex.current = null
        report({
          SerialNumber: 5,
          Event: 'cms_swipe',
          Trigger: '滑動頻道輪播',
          Parameters: 'channel_carousel_id',
          Value: adItems[endIndex].id,
        })
      }
    },
    [adItems]
  )

  // FIXME: workaround against swiper js internal bug
  const onLoopFix = useCallback(() => {
    /**
     * @type {import("swiper/react").SwiperRef['swiper']}
     */
    const swiper = inst.current.swiper
    const bugged = Math.abs(swiper.translate - -swiper.wrapperEl.scrollLeft) >= swiper.width

    if (bugged) {
      swiper.translate = -swiper.wrapperEl.scrollLeft

      // FIXME: random safari workaround to force rerender
      swiper.wrapperEl.style.visibility = 'hidden'
      swiper.wrapperEl.getBoundingClientRect()
      swiper.wrapperEl.style.visibility = ''
    }
  }, [])

  if (!isShowCarouselAdvertisement) return null

  return (
    <SwiperWrapper ref={ref} className="swiper-no-swiping">
      <Swiper
        key={adItems?.length ?? 0}
        ref={inst}
        modules={[Autoplay, Pagination, A11y, Thumbs]}
        autoplay={{
          disableOnInteraction: true,
        }}
        speed={speed}
        pagination={{ clickable: true }}
        loop={(adItems?.length ?? 0) > 1}
        nested
        onAutoplayStop={onAutoPlayStop}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onLoopFix={onLoopFix}
        onSlideChange={onSlideChange}
        // no swiping also affect swiper "in" the container, so we need to set a different one here
        noSwipingClass="non"
        noSwipingSelector=".non"
      >
        {adItems?.map((info, i) => (
          <SwiperSlide key={i}>
            <CarouselItem {...info} noLazy={loaded} allowUnload={!visible} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}

export default memo(Carousel)
