import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const FlexBox = styled.div`
  display: flex;
`

export const Line = styled.div`
  margin: 0 5px;
`

export const Wrapper = styled.div`
  --history-row-gap: 24px;
  font-size: 0.4rem;
  font-weight: bold;
`

export const BtnCancel = styled.button`
  position: absolute;
  right: 0;
  width: 14px;
  height: 14px;
  background-image: url(${process.env.PUBLIC_URL}/icon/cancel.png);
  background-repeat: no-repeat;
  background-size: contain;
`

export const TrashCan = styled.div`
  width: 0.641rem;
  height: 0.641rem;
  max-width: 25px;
  max-height: 25px;
  background: url(${process.env.PUBLIC_URL}/icon/trashcan.svg) center no-repeat;
`

export const HistoryWrapper = styled.div.attrs((props) => {
  return {
    style: {
      maxHeight: props.isExpand ? 'unset' : '2rem',
      margin: props.isExpand ? '18px var(--cms-grid-column-gap) 26px' : '18px var(--cms-grid-column-gap) 16px',
    },
  }
})`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.205rem var(--history-row-gap);
  font-size: 0.358rem;
  overflow: hidden;
`

export const HistoryItem = styled.div`
  position: relative;
  width: calc(50% - (var(--history-row-gap) / 2));
  height: 0.53rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`

export const NeverSearched = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--page-search--hint-text-color);
  font-size: 0.3589rem;
  font-weight: normal;
`

export const Expand = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  color: var(--page-search--hint-text-color);
  font-weight: bold;
`

export const ExpandIcon = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/expand-arrow.png`,
})`
  width: 0.4rem;
  height: 0.4rem;
  transform: rotate(90deg);
  margin-left: 0.1rem;
`
