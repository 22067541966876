import AesImage from 'component/AesImage'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0rem 0;
  color: white;
  background: var(--component-area-swiper--background);
`

export const StyledAesImage = styled(AesImage).attrs({ aspectRatio: null })`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`
