// 短視頻 一行兩個
import { CmsGridWrapper } from 'commonStyledComponents'
import VideoBox from 'component/VideoBoxVertical'
import { memo, useMemo } from 'react'

/**
 * 模板 ID: "101": 豎版一行兩個
 * */
function Template101({ videoData, trigger, currentLimit, lazy = false }) {
  const shortInfo = useMemo(
    () => ({
      list: videoData,
    }),
    [videoData]
  )

  return (
    <CmsGridWrapper>
      {videoData?.slice(0, currentLimit).map((item, i) => {
        return <VideoBox overflow={true} columns={2} key={i} videoInfo={item} shortInfo={shortInfo} allowUnload />
      })}
      {trigger}
    </CmsGridWrapper>
  )
}

// this indicate areaBox should hide bottom section until fully shown
Template101.lazy = true

export default memo(Template101)
