import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  BottomFunctionAreaChapters,
  BottomFunctionAreaProgress,
  BottomFunctionAreaSetting,
  BottomFunctionAreaSettingDarkModeDark,
  BottomFunctionAreaSettingDarkModeLight,
  BottomFunctionAreaSettingFontSizeDown,
  BottomFunctionAreaSettingFontSizeUp,
  BottomFunctionAreaSettingIconBrightness,
  BottomFunctionAreaSettingIconDarkMode,
  BottomFunctionAreaSettingIconFontSize,
  BottomFunctionAreaSettingItem,
  BottomFunctionAreaSettingOptionName,
  BottomFunctionAreaSettingOptions,
  BottomFunctionAreaText,
  BrightnessOverlay,
  ButtonFunctionAreaProgressButton,
  ButtonFunctionAreaProgressButtonIcon,
  ButtonFunctionAreaProgressButtonIconPrev,
  ControlOverlay,
  Footer,
  FooterButton,
  FooterButtonIconChapter,
  FooterButtonIconProgress,
  FooterButtonIconSetting,
  Header,
  HeaderClose,
  StyledAnimatedIcon,
  StyledLabeledSlider,
  StyledNovelChapters,
  StyledNovelPlayer,
  SwitchChapterHintPage,
  Wrapper,
} from './Styled'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import {
  fetchAddNovelCollectList,
  fetchBinFile,
  fetchNovelChapterKey,
  fetchNovelChapters,
  fetchNovelList,
  fetchNovelViewChapter,
  fetchRemoveNovelCollectList,
} from 'api'
import { useAllNovelChapters } from 'hooks/useAllNovelChapters'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import { useDispatch, useSelector } from 'react-redux'
import { RESET_NOVEL_COLLECTION } from 'redux/constant/novelCollection'
import { DISABLED, ENABLED } from 'constant/common'
import saveIconData from './animated-icons/save_outline.json'
import { selectNovelOrigin } from 'redux/selector/app'
import { decryptArrayBuffer } from 'utils/cryoto'
import Tutorial from './components/Tutorial'
import { usePreferenceItem } from 'hooks/usePreferenceItem'
import actions from 'redux/action'
import { selectNovelHistory } from 'redux/selector/history'
import useConfirmModal from 'hooks/useConfirmModal'
import { combinedStorage } from 'utils/combinedStorage'
import { checkDebugSecret } from 'page/SystemConfig'

let utf8decoder = new TextDecoder() // default 'utf-8' or 'utf8'
const TUTORIAL_SKIP_VARIABLE = 'viewedNovelTutorialAt'

const MAX_FONT_SIZE = 30
const MIN_FONT_SIZE = 14

const ViewNovelChapter = () => {
  const { chapterInfo: initialChapterInfo, novelId, initialPage: routerInitialPage } = useParamsOfPage('novel-chapter')
  const navigate = useAppNavigate()
  const { navigateToNovelChapter } = useNavigateTo()
  const dispatch = useDispatch()
  const novelCdn = useSelector(selectNovelOrigin)
  const novelReader = useRef()

  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [uiHidden, setUiHIdden] = useState(false)
  const [novelInfo, setNovelInfo] = useState(null)

  const [currentChapterInfo, setCurrentChapterInfo] = useState(
    /** @type {import('../../../types/api').SchemaNovelChapterShow | null} */ (null)
  )
  const chapterInfo = currentChapterInfo
    ? currentChapterInfo.id === initialChapterInfo.id
      ? currentChapterInfo
      : initialChapterInfo
    : initialChapterInfo

  const currentChapterId = chapterInfo.id

  const attemptToChangePage = useCallback((number) => {
    novelReader.current?.changePage(number - 1)
  }, [])

  const novelHistory = useSelector(selectNovelHistory)

  const [initialPageState, setInitialPageState] = useState(() => ({
    chapter: initialChapterInfo.id,
    initialPage: novelHistory[novelId]
      ? novelHistory[novelId][0] === initialChapterInfo.id
        ? novelHistory[novelId][1]
        : 0
      : 0,
  }))

  useEffect(() => {
    const con = new AbortController()
    const sig = con.signal
    fetchNovelList({
      signal: sig,
      id: novelId,
    }).then((res) => {
      if (res.data[0]) {
        setNovelInfo({
          ...res.data[0],
          // force set current chapter even it is not loaded yet
          last_read_chapter_id: initialChapterInfo.id,
        })
      }
    })

    return () => {
      con.abort()
    }
  }, [novelId, initialChapterInfo.id])

  useEffect(() => {
    const applyInitialPage = () =>
      setInitialPageState((oldState) =>
        oldState.chapter === chapterInfo.id
          ? oldState
          : {
              initialPage: routerInitialPage,
              chapter: chapterInfo.id,
            }
      )

    if (chapterInfo.page_url == null) {
      const con = new AbortController()
      const sig = con.signal
      fetchNovelChapters({ signal: sig, id: chapterInfo.id }).then((res) => {
        if (!sig.aborted) {
          applyInitialPage()
          setCurrentChapterInfo(res.data[0])
        }
      })

      return () => {
        con.abort()
      }
    } else {
      applyInitialPage()
    }
  }, [chapterInfo.id, chapterInfo.page_url, routerInitialPage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetchNovelViewChapter({ signal, chapter_id: chapterInfo.id })
  }, [chapterInfo.id])

  /**
   *
   * @param {React.SyntheticEvent<'img', MouseEvent>} ev
   */
  const onAreaClick = (ev) => {
    setUiHIdden((v) => !v)
  }

  /**
   *
   * @param {React.SyntheticEvent<'img', MouseEvent>} ev
   */
  const onReaderScroll = useCallback((ev) => {
    setUiHIdden(true)
  }, [])

  const onOpenChapter = useCallback(
    (chapter, initialPage = 0) => {
      setCurrentPanel(null)
      // setCurrentChapterInfo(chapter)
      navigateToNovelChapter(
        {
          chapterInfo: chapter,
          novelId,
          initialPage,
        },
        { replace: true }
      )

      setNovelInfo((v) => {
        if (v != null) {
          return {
            ...v,
            last_read_chapter_id: chapter.id,
          }
        } else {
          return v
        }
      })
    },
    [navigateToNovelChapter, novelId]
  )
  const fullChapterList = useAllNovelChapters(novelId)

  let chapterIndex = -1

  for (let i = 0; i < fullChapterList.length; i++) {
    if (fullChapterList[i].id === chapterInfo.id) {
      chapterIndex = i
      break
    }
  }

  const prevChapter = fullChapterList[chapterIndex - 1]
  const nextChapter = fullChapterList[chapterIndex + 1]

  const [newestChapter, setNewestChapter] = useState(null)
  useEffect(() => {
    const con = new AbortController()
    const sig = con.signal
    fetchNovelChapters({ novel_id: novelId, pageSize: 1, signal: sig }).then((info) => {
      const total = info.page_result.total
      fetchNovelChapters({ novel_id: novelId, current: total, pageSize: 1, signal: sig }).then((info) => {
        setNewestChapter(info.data?.[0])
      })
    })
  }, [novelId])

  const [keyState, setKeyState] = useState({
    chapter: null,
    key: null,
  })

  useEffect(() => {
    if (keyState.chapter === currentChapterId) return
    const controller = new AbortController()
    const signal = controller.signal

    fetchNovelChapterKey({ signal, id: currentChapterId })
      .then((data) => {
        setKeyState({
          chapter: currentChapterId,
          key: data.key,
        })
      })
      .catch((err) => {
        if (!signal.aborted) {
          throw err
        }
      })

    return () => {
      controller.abort()
    }
  }, [currentChapterId, keyState.chapter])

  const [blobState, setBlobState] = useState({
    chapter: null,
    blob: null,
  })

  useEffect(() => {
    if (chapterInfo.page_url?.[0] == null || blobState.chapter === currentChapterId) return
    const controller = new AbortController()
    const signal = controller.signal

    fetchBinFile({ signal, url: novelCdn + '/' + chapterInfo.page_url?.[0], responseType: 'arraybuffer' })
      .then((data) => {
        setBlobState({
          chapter: currentChapterId,
          blob: data,
        })
      })
      .catch((err) => {
        if (!signal.aborted) {
          throw err
        }
      })

    return () => {
      controller.abort()
    }
  }, [currentChapterId, blobState.chapter, chapterInfo.page_url, novelCdn])

  // const [paragraphs, setParagraphs] = useState([])
  const [content, setContent] = useState({
    chapter: null,
    paragraphs: [],
    initialPage: 0,
    prevChapter: /** @type {import('../../../types/api').SchemaNovelChapterShow | null} */ (null),
    nextChapter: /** @type {import('../../../types/api').SchemaNovelChapterShow | null} */ (null),
  })

  useEffect(() => {
    if (keyState.key == null) return
    if (
      currentChapterId !== keyState.chapter ||
      currentChapterId !== blobState.chapter ||
      currentChapterId !== initialPageState.chapter
    )
      return
    let canceled = false

    const run = async () => {
      const res = await decryptArrayBuffer(
        blobState.blob,
        keyState.key,
        [0x00, 0x01, 0x05, 0x03, 0x04, 0x05, 0x09, 0x07, 0x08, 0x09, 0x0a, 0x0b, 0x0c, 0x0d, 0x0e, 0x0f]
      )
      if (canceled) return
      const text = utf8decoder.decode(res)
      const paragraphs = [
        {
          text: chapterInfo.prefix_name + ' ' + chapterInfo.title,
          style: {
            fontWeight: 'bold',
          },
        },
        ...text.split(/\r?\n/g).map((i) => ({ text: i })),
      ]
      // attemptToChangePage(0)
      setContent({
        chapter: keyState.chapter,
        paragraphs,
        initialPage: initialPageState.initialPage,
        prevChapter: prevChapter ?? null,
        nextChapter: nextChapter ?? null,
      })
    }

    run()

    return () => {
      canceled = true
    }
  }, [
    currentChapterId,
    attemptToChangePage,
    blobState.blob,
    blobState.chapter,
    chapterInfo.prefix_name,
    chapterInfo.title,
    initialPageState.chapter,
    initialPageState.initialPage,
    keyState.chapter,
    keyState.key,
    nextChapter,
    prevChapter,
  ])

  const { favorite, last_read_chapter_id } = novelInfo ?? {}

  const alerts = useAlertWorksActionResult()

  const setFavorite = async (value, callback) => {
    if (value) {
      await fetchAddNovelCollectList({ novel_id: novelId })
    } else {
      await fetchRemoveNovelCollectList({ novel_id: novelId })
    }

    dispatch({ type: RESET_NOVEL_COLLECTION })

    setNovelInfo((v) => {
      if (v == null) return null
      return {
        ...v,
        favorite: v.favorite === ENABLED ? DISABLED : ENABLED,
      }
    })

    if (value) {
      alerts.alertSaved()
    } else {
      // alerts.alertUnsaved()
    }
    callback?.()
  }

  const nextPage = useCallback(() => {
    novelReader.current?.changePage(currentPage + 1)
  }, [currentPage])

  const prevPage = useCallback(() => {
    novelReader.current?.changePage(currentPage - 1)
  }, [currentPage])

  const [currentPanel, setCurrentPanel] = useState(/** @type { 'chapters' | 'progress' | 'setting' | null} */ (null))

  const changePanel = (target) => {
    if (target === currentPanel) {
      setCurrentPanel(null)
    } else {
      setCurrentPanel(target)
    }
  }

  const [fontSize, setFontSize] = usePreferenceItem('novel-reader-font-size', 20)
  const [currentMode, setCurrentMode] = usePreferenceItem(
    'novel-reader-theme',
    /** @type {'dark' | 'light'} */ ('dark')
  )

  const [skipTutorial] = useState(() => {
    return combinedStorage.getItem(TUTORIAL_SKIP_VARIABLE) === process.env.REACT_APP_VERSION
  })

  const setTutorialSkip = () => {
    combinedStorage.setItem(TUTORIAL_SKIP_VARIABLE, process.env.REACT_APP_VERSION)
  }

  const onCurrentPageChange = useCallback(
    (page) => {
      if (content.chapter !== chapterInfo.id) {
        if (process.env.NODE_ENV === 'development') {
          console.log('not save history because page is not loaded yet')
        }
        // not loaded yet
        return
      }
      if (process.env.NODE_ENV === 'development') {
        console.log('update novel progress', novelId, [chapterInfo.id, page])
      }
      dispatch(actions.updateNovelHistory({ id: novelId, progress: [chapterInfo.id, page] }))
      setCurrentPage(page)
    },
    [chapterInfo.id, content.chapter, dispatch, novelId]
  )

  const { openConfirm } = useConfirmModal()

  const onLeave = () => {
    if (novelInfo.favorite === DISABLED) {
      openConfirm({
        title: '加入收藏',
        message: '下次找书更方便',
        onConfirm() {
          setFavorite(true, () => {
            navigate(-1)
          })
        },
        onCancel() {
          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  const novelReaderAdditionPage = useMemo(() => {
    return {
      prepend: content.prevChapter ? [<SwitchChapterHintPage>回上一章</SwitchChapterHintPage>] : [],
      append: content.nextChapter
        ? [<SwitchChapterHintPage>{content.nextChapter.prefix_name ?? '下一章'}</SwitchChapterHintPage>]
        : [],
    }
  }, [content.nextChapter, content.prevChapter])

  const onEnterExtraPage = useCallback(
    (type) => {
      if (type === 'append') {
        if (nextChapter) {
          onOpenChapter(nextChapter)
        }
      }
      if (type === 'prepend') {
        if (prevChapter) {
          onOpenChapter(prevChapter, -1)
        }
      }
    },
    [nextChapter, onOpenChapter, prevChapter]
  )

  const parsedDebugInfo = useMemo(() => {
    try {
      const temp = JSON.parse(localStorage.getItem('DEBUG'))
      if (Array.isArray(temp) && temp.every((i) => typeof i === 'string')) {
        if (checkDebugSecret(temp[0])) {
          return temp.slice(1)
        }
      }
    } catch (err) {}

    return []
  }, [])

  const enableLocalNovelBrightness = parsedDebugInfo.includes('NOVEL_BRIGHTNESS')

  const [brightness, setBrightness] = usePreferenceItem('novel-reader-brightness', 100)

  return (
    <>
      <Wrapper onClick={onAreaClick} className={`mode-${currentMode}`}>
        <StyledNovelPlayer
          ref={novelReader}
          content={content}
          font={`${fontSize}px/1.7  sans-serif`}
          onPageCountChange={setTotalPage}
          onCurrentPageChange={onCurrentPageChange}
          onEnterExtraPage={onEnterExtraPage}
          onScroll={onReaderScroll}
        >
          {novelReaderAdditionPage}
        </StyledNovelPlayer>
      </Wrapper>
      {enableLocalNovelBrightness && <BrightnessOverlay style={{ '--opacity': 1 - brightness / 100 }} />}
      {!uiHidden && <ControlOverlay />}
      <Header className={uiHidden ? 'hidden' : ''}>
        <HeaderClose onClick={onLeave}></HeaderClose>
        {chapterInfo.prefix_name + (chapterInfo.title ? ` - ${chapterInfo.title}` : '')}
      </Header>
      <Footer className={uiHidden ? 'hidden' : ''}>
        <FooterButton onClick={() => changePanel('chapters')}>
          <FooterButtonIconChapter className={currentPanel === 'chapters' ? 'active' : ''} />
          目录
        </FooterButton>
        <FooterButton onClick={() => changePanel('progress')}>
          <FooterButtonIconProgress className={currentPanel === 'progress' ? 'active' : ''} />
          进度
        </FooterButton>
        <FooterButton onClick={() => changePanel('setting')}>
          <FooterButtonIconSetting className={currentPanel === 'setting' ? 'active' : ''} />
          设置
        </FooterButton>
        <FooterButton onClick={() => setFavorite(favorite === DISABLED)}>
          {novelInfo != null && <StyledAnimatedIcon animationData={saveIconData} active={favorite === ENABLED} />}
          {favorite === ENABLED ? '已收藏' : '收藏'}
        </FooterButton>

        <BottomFunctionAreaChapters className={currentPanel === 'chapters' ? '' : 'hidden'}>
          <StyledNovelChapters
            title={`更新到${newestChapter?.prefix_name ?? ''}`}
            novelId={novelId}
            isOpen={currentPanel === 'chapters'}
            onClose={() => setCurrentPanel(null)}
            onOpenChapter={onOpenChapter}
            activeChapter={last_read_chapter_id}
            preloadedList={fullChapterList}
            noOverlay
          />
        </BottomFunctionAreaChapters>
        <BottomFunctionAreaProgress className={currentPanel === 'progress' ? '' : 'hidden'}>
          <ButtonFunctionAreaProgressButton onClick={prevPage}>
            <ButtonFunctionAreaProgressButtonIconPrev />
            <BottomFunctionAreaText>上一页</BottomFunctionAreaText>
          </ButtonFunctionAreaProgressButton>
          <StyledLabeledSlider min={1} max={totalPage} value={currentPage + 1} onChange={attemptToChangePage} />
          <ButtonFunctionAreaProgressButton onClick={nextPage}>
            <BottomFunctionAreaText>下一页</BottomFunctionAreaText>
            <ButtonFunctionAreaProgressButtonIcon />
          </ButtonFunctionAreaProgressButton>
        </BottomFunctionAreaProgress>
        <BottomFunctionAreaSetting className={currentPanel === 'setting' ? '' : 'hidden'}>
          {enableLocalNovelBrightness && (
            <BottomFunctionAreaSettingItem>
              <BottomFunctionAreaSettingOptionName>
                <BottomFunctionAreaSettingIconBrightness />
                亮度
              </BottomFunctionAreaSettingOptionName>
              <BottomFunctionAreaSettingOptions
                style={{ flex: '1 1 auto', marginLeft: '0.56rem', alignSelf: 'stretch', alignItems: 'stretch' }}
              >
                <StyledLabeledSlider min={20} max={100} value={brightness} showLabel={false} onChange={setBrightness} />
              </BottomFunctionAreaSettingOptions>
            </BottomFunctionAreaSettingItem>
          )}
          <BottomFunctionAreaSettingItem>
            <BottomFunctionAreaSettingOptionName>
              <BottomFunctionAreaSettingIconFontSize />
              字号
            </BottomFunctionAreaSettingOptionName>
            <BottomFunctionAreaSettingOptions>
              <BottomFunctionAreaSettingFontSizeUp onClick={() => setFontSize((f) => Math.min(f + 1, MAX_FONT_SIZE))} />
              {fontSize}
              <BottomFunctionAreaSettingFontSizeDown
                onClick={() => setFontSize((f) => Math.max(f - 1, MIN_FONT_SIZE))}
              />
            </BottomFunctionAreaSettingOptions>
          </BottomFunctionAreaSettingItem>
          <BottomFunctionAreaSettingItem>
            <BottomFunctionAreaSettingOptionName>
              <BottomFunctionAreaSettingIconDarkMode />
              阅读模式
            </BottomFunctionAreaSettingOptionName>
            <BottomFunctionAreaSettingOptions>
              <BottomFunctionAreaSettingDarkModeDark
                className={currentMode === 'dark' ? 'active' : ''}
                onClick={() => setCurrentMode('dark')}
              />
              <BottomFunctionAreaSettingDarkModeLight
                className={currentMode === 'light' ? 'active' : ''}
                onClick={() => setCurrentMode('light')}
              />
            </BottomFunctionAreaSettingOptions>
          </BottomFunctionAreaSettingItem>
        </BottomFunctionAreaSetting>
      </Footer>
      <Tutorial isOpen={!skipTutorial} onFinished={setTutorialSkip} />
    </>
  )
}

export default ViewNovelChapter
