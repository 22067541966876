import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal() {
  const { navigateToViewMore, navigateToEnableVip, navigateToProducerList, navigateToActorList } = useNavigateTo()

  const openProducerListModal = (props) => {
    const producerIds = props?.video_producer_id ? [props?.video_producer_id] : undefined
    const headerTitle = props?.headerTitle

    navigateToProducerList({
      producerIds,
      headerTitle,
    })
  }

  const openActorListModal = (props) => {
    const actorIds = props?.tab_ids?.split(',')
    const headerTitle = props?.headerTitle

    navigateToActorList({
      actorIds,
      headerTitle,
    })
  }

  const openEnableVipModal = () => {
    navigateToEnableVip({})
  }

  const openViewMoreModal = (props) => {
    const actorIds = props?.video_actor_id
    const headerTitle = props?.headerTitle

    navigateToViewMore({
      params: {
        actor_ids: actorIds,
      },
      headerTitle,
      showAd: false,
    })
  }

  return {
    openProducerListModal,
    openActorListModal,
    openEnableVipModal,
    openViewMoreModal,
  }
}
