import { useState, useMemo, useCallback } from 'react'
//
import {
  fetchPostAdEvent,
} from 'api'
//
import { VideoTypeEnum } from 'constant/common'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
//
import { useOpenRankModal } from 'component/AreaBox/hooks/useOpenModal'
//
import {
  Wrapper,
  ContentWrapper,
  TabButton,
  TabButtonRow,
  TopRow,
  BottomRow,
  AdImage,
  StyledButtonSearchMore,
} from './Styled'

import AreaWrapper from 'component/AreaWrapper'

import { SchemaCmsSectionShow } from '../../../types/api'
import { useInView } from 'react-intersection-observer'
import { useTemplates } from 'component/AreaBox/hooks/useTemplates'
import { selectAdInfo } from 'redux/selector/app'
import { AdvertisementType } from 'constant/advertisement'
import { formatDate } from 'utils/timeFormat'
import { useViewport } from 'component/AreaBox/hooks/useViewport'
import { WorkSectionProvider } from 'hooks/useAnalytics'

// 這個是AreaBox的集合版
export default function GroupedAreaBox({ infoList, areaIndex, listData, tabIndex, onTabClick, lazy, ...props }) {
  const [currentTab, setCurrentTab] = useState(tabIndex)
  const currentInfo = useMemo<SchemaCmsSectionShow>(() => {
    if (!infoList) return null;
    return infoList[currentTab]
  }, [currentTab, infoList])
  // const { finishLoading } = useAreaLoading()
  const adInfo = useSelector(selectAdInfo)
  const {templates} = useTemplates();
  const { finished } = useViewport({ enabled: lazy, count: 0 })

  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })

  // /** 如果後端回傳 video_sortby 為 random, 則由前端實作 random 邏輯 */
  // const isVideoSortByRandom = useMemo(() => currentInfo?.video_sortby === SORT_BY_RANDOM, [currentInfo?.video_sortby])
  const isMoreVisible = useMemo(() => currentInfo?.more_isvisible == 1, [currentInfo])
  // const isShowTitle = title_is_visible === TRUE
  // /** 是否顯示區域上方的更多 (更多>) */
  // const isShowMore = more_isvisible === TRUE
  // /** 是否顯示區域下方的更多按鈕 */
  // const isShowButtonMore = show_more_isvisible === TRUE
  // /** 是否顯示區域下方的換一批按鈕 */
  // const isShowButtonNext = next_isvisible === TRUE

  const { openViewMoreModal } = useOpenRankModal({
    title: '排行榜',
    infoList,
    tabIndex: currentTab,
    type:
      currentInfo?.type === VideoTypeEnum.LongVideo
        ? 'video'
        : currentInfo?.type === VideoTypeEnum.ShortVideo
          ? 'short'
          : currentInfo?.type === VideoTypeEnum.Comic
            ? 'comic'
            : currentInfo?.type === VideoTypeEnum.Novel
              ? 'novel'
              : 'video',
  })

  const infoData = useMemo(() => {
    return listData[currentTab] || []
  }, [currentTab, listData])

  const Template = useMemo(()=>{
    if(!currentInfo || !currentInfo.template_id) return null
    return templates[currentInfo.template_id]
  }, [currentInfo, templates])

  const TemplateComponent = useMemo(()=>{
    return Template ? <Template videoData={infoData} /> : <div></div>;
  }, [Template, infoData])

  /** 視頻櫥窗下方廣告位 */
  const adAreaBottom = useMemo(() => {
    const adIndex = areaIndex % adInfo?.[AdvertisementType.AreaBottom]?.length
    return adInfo?.[AdvertisementType.AreaBottom]?.[adIndex]
  }, [adInfo, areaIndex])


  const onAdItemClick = useCallback(() => {
    if (adAreaBottom) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: adAreaBottom.id,
        material_name: adAreaBottom.name,
        oholo_site_id: adAreaBottom.site_id,
        oholoer_id: adAreaBottom.oholoer_id,
      })
      window.open(adAreaBottom?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [adAreaBottom])

  const onMoreClick = useCallback(() => {
    openViewMoreModal()
  }, [openViewMoreModal])

  const handleTabClick = useCallback((index)=>{
    setCurrentTab(index)
    onTabClick(index)
  }, [onTabClick])

  // if (!TemplateComponent) return null

  return (
    <WorkSectionProvider SerialNumber={9} Event="cms_click" Trigger="點擊區域中影片">
      <Wrapper {...props} ref={ref}>
        <AreaWrapper>
          <ContentWrapper>
            <TopRow>
              <TabButtonRow>
                {infoList.map((m, index) => {
                  return (
                    <TabButton
                      key={'tab_' + index}
                      className={currentTab == index ? 'active' : ''}
                      onClick={() => {
                        handleTabClick(index)
                      }}
                    >
                      {m.title}
                    </TabButton>
                  )
                })}
              </TabButtonRow>
              {isMoreVisible && <StyledButtonSearchMore handleClick={onMoreClick} />}
            </TopRow>
            
            {TemplateComponent}
            
          </ContentWrapper>
        </AreaWrapper>
        {(finished || !Template?.lazy) && (
          <BottomRow>
            {/** 視頻櫥窗下方廣告位 */}
            {!isEmpty(adAreaBottom) && (
              // @ts-expect-error ignore type check
              <AdImage src={adAreaBottom.icon_path} source="preview" aspectRatio={374 / 94} onClick={onAdItemClick} />
            )}
          </BottomRow>
        )}
      </Wrapper>
    </WorkSectionProvider>
  )
}
