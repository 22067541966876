import { useMemo } from 'react'
// import { useNavigate } from "react-router-dom";
// import useAlertNotDeveloped from 'hooks/useAlertNotDeveloped'
// import { APP_CENTER } from "constant/routers";
import {
  IconAppCenter,
  IconCollection,
  IconHistory,
  IconLike,
  IconPromote,
  IconPurchase,
  IconShare,
} from '../../../Styled'
import { useHasPayment } from 'hooks/useHasPayment'

export default function useMoreServices({
  openFavouriteModal,
  openCollectionModal,
  openBrowseHistoryModal,
  openBusinessRelationshipModal,
  openAppCenterModal,
  openSharePageModal,
  openPurchaseHistoryModal,
  openWalletModal,
  openEnableVipModal,
}) {
  const hasPayment = useHasPayment()

  const largeBoxItems = useMemo(
    () => [
      {
        title: '情色专区',
        content: '华语原创',
      },
      {
        title: '玩游戏',
        content: '返利最高享70％分成',
      },
    ],
    []
  )

  const smallBoxItems = useMemo(() => {
    const paymentExclusive = hasPayment
      ? [
          {
            title: '我的购买',
            iconComponent: IconPurchase,
            handleClick: openPurchaseHistoryModal,
          },
        ]
      : []
    return [
      ...paymentExclusive,
      {
        title: '我的足迹',
        iconComponent: IconHistory,
        handleClick: openBrowseHistoryModal,
      },
      {
        title: '我的喜欢',
        iconComponent: IconLike,
        handleClick: openFavouriteModal,
      },
      {
        title: '我的收藏',
        iconComponent: IconCollection,
        handleClick: openCollectionModal,
      },
      {
        title: '应用中心',
        iconComponent: IconAppCenter,
        handleClick: openAppCenterModal,
      },
      {
        title: '商务合作',
        iconComponent: IconPromote,
        handleClick: openBusinessRelationshipModal,
      },
      {
        title: '推广分享',
        iconComponent: IconShare,
        handleClick: openSharePageModal,
      },
    ]
  }, [
    openPurchaseHistoryModal,
    openBrowseHistoryModal,
    openFavouriteModal,
    openCollectionModal,
    openAppCenterModal,
    openBusinessRelationshipModal,
    openSharePageModal,
    hasPayment,
  ])

  const longBoxItems = useMemo(
    () =>
      hasPayment
        ? [
            {
              title: '开通会员',
              icon: 'icon-diamond',
              handleClick: openEnableVipModal,
            },
            {
              title: '充值金币',
              icon: 'icon-coin',
              handleClick: openWalletModal,
            },
          ]
        : [],
    [openEnableVipModal, openWalletModal, hasPayment]
  )

  return { largeBoxItems, smallBoxItems, longBoxItems }
}
