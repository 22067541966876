import styled from 'styled-components'

export const Wrapper = styled.div`
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.visible {
    display: flex;
  }
`

export const AdImage = styled.img`
  width: 7.8974rem;
  height: 9.9487rem;
  border-radius: 0.2564rem;
`

export const BtnCancel = styled.button`
  margin-top: 0.4rem;
  width: 0.8rem;
  height: 0.8rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/close.png);
  background-repeat: no-repeat;
  background-size: cover;
`
