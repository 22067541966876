import { useNavigateTo } from 'hooks/useNavigateTo'
import { SORT_BY_RANDOM } from '../constant'
import { report } from 'hooks/useAnalytics'

const VIDEO_BOX_TEMPLATE_WITH_VERTICAL_VIDEO = ['8', '9', '14', '15', '16', '17', '18']

/**
 *
 * @param {import("../../../../types/api").SchemaCmsSectionShow} info
 * @returns
 */
export default function useOpenModal(info) {
  const { navigateToViewMore } = useNavigateTo()
  const openViewMoreModal = () => {
    report({
      SerialNumber: 12,
      Event: 'cms_click',
      Trigger: '點擊查看更多',
      Parameters: 'see_more',
      Value: info.id,
    })

    const category_ids = info?.video_category?.join(',')
    const actor_ids = info?.video_actor?.join(',')
    const tag_ids = info?.video_tag?.join(',')
    const region_ids = info?.video_region_ids?.join(',')
    const templateType = info?.template_id

    const params = {
      isVerticalVideoBox: VIDEO_BOX_TEMPLATE_WITH_VERTICAL_VIDEO.includes(templateType),
      // 使用大圖的 VideoLargeBox
      isLargeVideoBox: true,
      category_ids: category_ids,
      actor_ids: actor_ids,
      tag_ids: tag_ids,
      region_ids: region_ids,
      // producer_id: info?.video_producer_id,
      producer_ids: Array.isArray(info?.video_producer_ids) ? info?.video_producer_ids?.join(',') : '',
      // video的sortby不支援random，因此這邊在早期的code內設定先把他轉為updated_at
      sortby: info?.isVideoSortByRandom ? 'updated_at' : info?.video_sortby,
      // 將用於viewMore內容之中表示需要隨機排序
      random: info?.isVideoSortByRandom,
      payment_type: info?.video_payment_type,
      ...(info.type === 'short'
        ? {
            uploader_id: info?.video_uploader_id,
          }
        : {}),
      ...(info.type === 'comic'
        ? {
            serial: info?.serial,
          }
        : {}),
    }

    navigateToViewMore({
      params,
      headerTitle: info?.title,
      type: info.type,
    })
  }

  return {
    openViewMoreModal,
  }
}

//聚合版面
export function useOpenRankModal({ title, infoList, type, tabIndex }) {
  const { navigateToRankViewMore } = useNavigateTo()
  const openViewMoreModal = () => {
    const list = infoList.map((info) => {
      // 按原本的機制調整資料參數
      const category_ids = info?.video_category?.join(',')
      const actor_ids = info?.video_actor?.join(',')
      const tag_ids = info?.video_tag?.join(',')
      const region_ids = info?.video_region_ids?.join(',')
      const templateType = info?.template_id

      const params = {
        special_search: 1,
        isVerticalVideoBox: VIDEO_BOX_TEMPLATE_WITH_VERTICAL_VIDEO.includes(templateType),
        // 使用大圖的 VideoLargeBox
        isLargeVideoBox: true,
        category_ids: category_ids,
        actor_ids: actor_ids,
        tag_ids: tag_ids,
        region_ids: region_ids,
        // producer_id: info?.video_producer_id,
        producer_ids: Array.isArray(info?.video_producer_ids) ? info?.video_producer_ids?.join(',') : '',
        // video的sortby不支援random，因此這邊在早期的code內設定先把他轉為updated_at
        sortby: info?.video_sortby === SORT_BY_RANDOM ? 'updated_at' : info?.video_sortby,
        // 將用於viewMore內容之中表示需要隨機排序
        random: info?.isVideoSortByRandom,
        payment_type: info?.video_payment_type,
        ...(info.type === 'short'
          ? {
              uploader_id: info?.video_uploader_id,
            }
          : {}),
        ...(info.type === 'comic'
          ? {
              serial: info?.serial,
            }
          : {}),
      }
      return {
        title: info.title,
        params,
      }
    })

    navigateToRankViewMore({
      infoList: list,
      headerTitle: title,
      type: type,
      tabIndex: tabIndex,
    })
  }

  return {
    openViewMoreModal,
  }
}
