import styled from 'styled-components'

export const LoadingWrapper = styled.div`
  display: contents;
  &.loading {
    opacity: 0;
    /* display: none; */
  }
`

export const LoadingPlaceholder = styled.div`
  height: 1rem;
`
