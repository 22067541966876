import { forwardRef } from 'react'
import styled from 'styled-components'
import LoadingEffect from './components/LoadingEffect'

export const PlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: stretch;
  justify-content: stretch;

  &.loading video-js {
    opacity: 0;
  }

  .pre-render video {
    transform: translateX(-100%);
  }
`

export const PlayerLoadingIndicator = styled(LoadingEffect)`
  > svg {
    height: 100%;
    width: 100%;
    display: block;
  }
  .loading & {
    display: block;
  }
  display: none;
  height: calc(4rem / 39);
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  left: calc(12rem / 39);
  right: calc(12rem / 39);
  pointer-events: none;
`

export const PlayerLoadingIndicatorBackground = styled.div`
  .loading & {
    display: block;
  }
  display: none;
  height: calc(1rem / 39);
  position: absolute;
  background-color: black;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  transform-origin: center bottom;
  transform: scaleY(0.5);
`

export const StyledVideoJs = styled(
  forwardRef(({ children, className, ...props }, ref) => {
    return (
      <video-js {...props} class={className} ref={ref}>
        {children}
      </video-js>
    )
  })
)`
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
  background: transparent;

  video {
    background: black;
  }

  /* > video {
    object-fit: cover;
  } */

  /* safari trigger waiting when speed change */
  &.vjs-waiting.pre-render {
    opacity: 0;
    pointer-events: none;
  }

  .vjs-control-bar {
    transform: translateY(calc(50% - 1px));
    opacity: 1 !important;
    pointer-events: all !important;
    background: transparent;
    z-index: 3;
    padding-left: calc(12rem / 39);
    padding-right: calc(12rem / 39);
  }

  .vjs-progress-holder {
    margin: 0 !important;
    background: rgba(255, 255, 255, 0.2);
    height: calc(2rem / 39) !important;
  }

  &.vjs-user-active .vjs-progress-holder {
    height: calc(4rem / 39) !important;
  }

  .vjs-load-progress {
    opacity: 0;
  }
  .vjs-play-progress {
    background-color: white;
  }
  .vjs-play-progress:before {
    top: 50%;
    transform: translateY(-50%);
  }
  .vjs-play-progress::before {
    color: white;
  }
  .vjs-poster {
    background-color: transparent;
  }

  &.vjs-user-inactive .vjs-play-progress::before {
    color: transparent;
  }
`
