import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0.87rem;
  height: 0.61rem;
  line-height: 0.56rem;
  background-image: url('${process.env.PUBLIC_URL}/icon-video/pay.png');
  background-size: 100% 100%;
`

export const Text = styled.div`
  /* margin-left: 0.56rem; */
  /* margin-right: 0.56rem; */
  text-align: left;
  margin: 0 0.05rem;

  font-family: 'Roboto';
  font-style: italic;
  font-weight: 800;

  font-size: 0.384rem;

  background: linear-gradient(180deg, #fff7db 0%, #ffdbc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  filter: drop-shadow(0px 1.16667px 1.94444px rgba(40, 0, 47, 0.45));
`
