import { createSlice } from '@reduxjs/toolkit'
import { combinedStorage } from 'utils/combinedStorage'
import { TOKEN_KEY } from 'redux/constant/user'

const INVITE_CODE_KEY = 'invited_by'
const USER_PREFERENCE_KEY = 'user_pref'

let initialPreference = {}

try {
  initialPreference = JSON.parse(combinedStorage.getItem(USER_PREFERENCE_KEY) ?? '{}')
} catch (err) {
  if (process.env.NODE_ENV === 'development') {
    console.error(err)
  }
}

const initialState = {
  userInfo: {
    name: '',
    id: '',
  },
  /** Token 資訊 */
  tokenInfo: {},
  /** 代理邀請碼 */
  inviteCode: combinedStorage.getItem(INVITE_CODE_KEY) ?? null,
  preference: initialPreference,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.userInfo = action.payload
    },
    clearUser: (state, action) => {
      state.userInfo = initialState.userInfo
      localStorage.removeItem(TOKEN_KEY)
    },
    updateTokenInfo: (state, action) => {
      state.tokenInfo = action.payload
      localStorage.setItem(TOKEN_KEY, JSON.stringify(action.payload))
    },
    updateInviteCode: (state, action) => {
      combinedStorage.setItem(INVITE_CODE_KEY, action.payload)
      state.inviteCode = action.payload
    },
    updatePreference: (state, action) => {
      const data = action.payload

      if (typeof data === 'object' && data != null) {
        const serialized = JSON.stringify(data)
        combinedStorage.setItem(USER_PREFERENCE_KEY, serialized)
        state.preference = data
      }
    },
  },
})

export const { actions } = slice
export default slice.reducer
