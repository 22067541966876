import { useCallback } from 'react'
import useOpenModal from './hooks/useOpenModal'
//
import { Wrapper, Area, IconBrand, ButtonFilter, IconFilter, IconSearch, IconPlace } from './Styled'
import { report } from 'hooks/useAnalytics'

/** 元件 Header - CMS View 最上方的 Header */
export default function Header({ searchTypes }) {
  const { openSearchVideoViaViaSpecificModal, openFilterModal } = useOpenModal()

  const onOpenSearch = useCallback(
    (searchTypes) => {
      report({
        SerialNumber: 2,
        Event: 'cms_click',
        Trigger: '點擊搜尋',
        Parameters: 'content',
        Value: 'search',
      })
      openSearchVideoViaViaSpecificModal(searchTypes)
    },
    [openSearchVideoViaViaSpecificModal]
  )

  const onOpenFilterModal = useCallback(() => {
    report({
      SerialNumber: 3,
      Event: 'cms_click',
      Trigger: '點擊篩選',
      Parameters: 'content',
      Value: 'filter',
    })
    openFilterModal()
  }, [openFilterModal])

  return (
    <Wrapper>
      <IconPlace></IconPlace>
      <IconBrand src={`${process.env.PUBLIC_URL}/icon/branding.png`} />

      <Area onClick={() => onOpenSearch(searchTypes)}>
        输入关键字搜寻
        <IconSearch />
      </Area>

      <ButtonFilter onClick={onOpenFilterModal}>
        <IconFilter src={`${process.env.PUBLIC_URL}/icon/filter.svg`} />
        筛选
      </ButtonFilter>
    </Wrapper>
  )
}
