import { useEvent } from 'hooks/useEvent'
import { useMemo } from 'react'
import { createContext, useCallback, useContext, useEffect, useRef } from 'react'

const AreaBoxLoadingContext = createContext({
  // loadingList: [],
  // loadedList: [],
  addTicket: (id) => {},
  removeTicket: (id) => {},
  finishTicket: (id) => {},
})

export const AreaLoadingProvider = ({ onLoaded = () => {}, children }) => {
  const loadingTickets = useRef(new Set())
  const finishedTickets = useRef(new Set())

  const onLoadedEvent = useEvent(onLoaded)

  const addTicket = useCallback((id) => {
    if (finishedTickets.current.has(id)) {
      return
    }
    if (loadingTickets.current.has(id)) {
      return
    }
    loadingTickets.current.add(id)
  }, [])

  const removeTicket = useCallback((id) => {
    if (finishedTickets.current.has(id)) {
      return
    }
    loadingTickets.current.delete(id)
  }, [])

  const finishTicket = useCallback(
    (id) => {
      if (finishedTickets.current.has(id)) {
        return
      }
      loadingTickets.current.delete(id)
      finishedTickets.current.add(id)

      if (loadingTickets.current.size === 0) {
        onLoadedEvent()
      }
    },
    [onLoadedEvent]
  )

  const state = useMemo(
    () => ({
      addTicket,
      removeTicket,
      finishTicket,
    }),
    [addTicket, finishTicket, removeTicket]
  )

  return <AreaBoxLoadingContext.Provider value={state}>{children}</AreaBoxLoadingContext.Provider>
}

export const useAreaLoading = () => {
  const { finishTicket, addTicket, removeTicket } = useContext(AreaBoxLoadingContext)
  const loaded = useRef(false)
  const idRef = useRef('area-' + Math.random().toString(16).slice(2))
  const id = idRef.current

  useEffect(() => {
    if (loaded.current) {
      return
    }

    addTicket(id)

    return () => {
      removeTicket(id)
    }
  })

  const finishLoading = useCallback(() => {
    finishTicket(id)
  }, [finishTicket, id])

  return {
    finishLoading,
  }
}
