import { useState } from 'react'

import {
  Wrapper,
  VideoName,
  BookDecoration,
  BookDecorationItem1,
  BookDecorationItem2,
  StyledAesImage,
  VideoTags,
  VideoInfo,
  VideoNameWhite,
  // VideoInfoOnTop,
  // VideoInfoOnTopBackground,
  // VideoNameWhite
} from './Styled'
import { useSelector } from 'react-redux'
import { selectStation } from 'redux/selector/app'
import { useNavigateTo } from 'hooks/useNavigateTo'

/**
 * @typedef {Object} NovelInfo
 * @property {import('../../../types/api').SchemaNovelShow} novelInfo
 * @property {number} [columns]
 * @property {string} [width]
 * @property {boolean} [titleInSlide]
 * @property {boolean} [noDecoration]
 */

/**
 * 元件 - 影片 Item
 * @param { NormalVideoInfo | ShortVideoInfo } param0
 * @returns
 */
export default function NovelBox(
  /** @type { NovelInfo } */ {
    novelInfo,
    width,
    columns = 2,
    overflow,
    titleInSlide = false,
    noDecoration = false,
    noLazy = false,
    allowUnload = true,
    hideTags = false,
    fontSize,
    ...props
  }
) {
  const { navigateToNovel } = useNavigateTo()
  const station = useSelector(selectStation)

  const { title, tag_names = [], cover_url } = novelInfo

  const [blobs, setBlobs] = useState(/** @type {Blob[]} */ ([]))

  const onVideoBoxClick = async () => {
    let url = null
    const imgBlob = blobs[0]
    if (imgBlob) {
      url = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(imgBlob)
        reader.onload = () => {
          resolve(reader.result)
        }
      })
    }
    navigateToNovel({
      posterImg: url,
      novelInfo: {
        ...novelInfo,
      },
    })
  }

  return (
    <Wrapper {...props} onClick={onVideoBoxClick} width={width} columns={columns}>
      {!noDecoration && (
        <BookDecoration>
          <BookDecorationItem1 />
          <BookDecorationItem2 />
        </BookDecoration>
      )}
      <StyledAesImage
        source="novel"
        decryptKey={station.cover_key}
        aspectRatio={1 / 1.414}
        src={cover_url}
        onImageBlobsLoad={setBlobs}
        noLazy={noLazy}
        allowUnload={allowUnload}
      />

      <VideoInfo>
        {!titleInSlide && <VideoName>{title}</VideoName>}
        {titleInSlide && <VideoNameWhite fontSize={fontSize}>{title}</VideoNameWhite>}
        {/* limit visible tag to 2 tags */}
        {!hideTags && tag_names.length > 0 && <VideoTags>{tag_names.slice(0, 2).join(' ')}</VideoTags>}
      </VideoInfo>
    </Wrapper>
  )
}
