import VideoRankBox from 'component/VideoRankBox'
import { SchemaVideoShow } from '../../../../../types/api'
import { VideoList } from './Styled'
import { memo } from "react"

interface Template19Prop {
  videoData: SchemaVideoShow[]
}

/**
 * 模板 ID: "19": 排行榜
 * */
function Template19({ videoData }:Template19Prop) {

  return (
    <VideoList>
      {videoData?.map((m, index) => {
        return <VideoRankBox key={index} videoInfo={m} index={index}></VideoRankBox>
      })}
    </VideoList>

  )
}

export default memo(Template19)
