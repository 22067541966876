import { createSlice } from '@reduxjs/toolkit'

const COMIC_HISTORY_KEY = 'comic_history'
const NOVEL_HISTORY_KEY = 'novel_history'

const initialState = {
  comic: {},
  novel: {},
}

try {
  const res = JSON.parse(localStorage.getItem(COMIC_HISTORY_KEY))
  if (res && typeof res === 'object') {
    initialState.comic = res
  }
} catch (err) {}

try {
  const res = JSON.parse(localStorage.getItem(NOVEL_HISTORY_KEY))
  if (res && typeof res === 'object') {
    initialState.novel = res
  }
} catch (err) {}

const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    updateComicHistory: (state, { payload: { id, progress } }) => {
      const newComicHistory = { ...state.comic, [id]: progress }
      localStorage.setItem(COMIC_HISTORY_KEY, JSON.stringify(newComicHistory))
      state.comic = newComicHistory
    },
    updateNovelHistory: (state, { payload: { id, progress } }) => {
      const newNovelHistory = { ...state.novel, [id]: progress }
      localStorage.setItem(NOVEL_HISTORY_KEY, JSON.stringify(newNovelHistory))
      state.novel = newNovelHistory
    },
  },
})

export const { actions } = slice
export default slice.reducer
