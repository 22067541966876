import { createBrowserRouter, createHashRouter } from 'react-router-dom'
import PageCmsView from 'page/CmsView'
import PageProfile from 'page/Profile'
import PageUnknown from 'page/Unknown'
import PageAppCenter from 'page/AppCenter'
import PageCategory from 'page/Category'
import GameLobby from 'page/GameLobby'

import { UNDEFINED, INDEX, PROFILE, APP_CENTER, CATEGORY, GAME } from 'constant/routers'
import { AppLayout } from 'app-layered-layout/AppLayout'
import App from 'App'
import { Layout } from 'layout/Layout'
import { BareLayout } from 'layout/BareLayout'
import { namedRouteDefinitions } from 'hooks/useNavigateTo'

const childRoutes = namedRouteDefinitions.map((d) => {
  return {
    path: d.name,
    element: d.component,
  }
})

export const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <AppLayout />,
        children: [
          {
            path: '',
            element: <Layout />,
            children: [
              {
                path: INDEX,
                element: <PageCmsView />,
              },
              {
                path: CATEGORY,
                element: <PageCategory />,
              },
              {
                path: APP_CENTER,
                element: <PageAppCenter />,
              },
              {
                path: PROFILE,
                element: <PageProfile />,
                children: [
                  {
                    path: ':deviceCode',
                    element: <PageProfile />,
                  },
                ],
              },
              {
                path: GAME,
                element: <GameLobby />,
              },
            ],
          },
          {
            path: '',
            element: <BareLayout />,
            children: [...childRoutes],
          },
        ],
      },
      {
        path: UNDEFINED,
        element: <PageUnknown />,
      },
    ],
  },
]

export const router = process.env.REACT_APP_USE_HASH_ROUTER ? createHashRouter(routes) : createBrowserRouter(routes)
