if (process.env.REACT_APP_SITE_CODE === 'spring') {
  module.exports = require('./type.spring')
} else if (process.env.REACT_APP_SITE_CODE === 'fx') {
  module.exports = require('./type.fx')
} else if (process.env.REACT_APP_SITE_CODE === 'seman') {
  module.exports = require('./type.seman')
} else if (process.env.REACT_APP_SITE_CODE === 'lls') {
  module.exports = require('./type.lls')
} else if (process.env.REACT_APP_SITE_CODE === 'quick') {
  module.exports = require('./type.quick')
} else if (process.env.REACT_APP_SITE_CODE === 'lsav') {
  module.exports = require('./type.lsav')
} else if (process.env.REACT_APP_SITE_CODE === 'djr88') {
  module.exports = require('./type.djr88')
} else if (process.env.REACT_APP_SITE_CODE === 'xlav') {
  module.exports = require('./type.xlav')
} else {
  module.exports = require('./type.shy')
}
