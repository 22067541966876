import VideoLargeBox from 'component/VideoLargeBox'

import { ColWrap, Wrapper } from './Styled'
import { memo, useMemo } from 'react'

/**
 * 模板 ID: "11": 橫版兩行兩個半向左滑動
 * */
function Template11({ videoData }) {
  const wrappedList = useMemo(() => {
    const list = []
    for (let i = 0; i < videoData.length; i++) {
      list[~~(i / 2)] = list[~~(i / 2)] ?? []
      list[~~(i / 2)].push(videoData[i])
    }
    return list
  }, [videoData])
  return (
    /* 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能 */
    <Wrapper className={'swiper-no-swiping'}>
      {wrappedList.map((group, i) => {
        return (
          <ColWrap key={i}>
            {group.map((item, j) => (
              <VideoLargeBox key={j} videoInfo={item} width="auto" allowUnload />
            ))}
          </ColWrap>
        )
      })}
    </Wrapper>
  )
}
export default memo(Template11)
