import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-size: 0.35rem;

  transition: all 0.5s;
  &.hidden {
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
  }
`

export const Screen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  transition: all 0.5s;
  &.hidden {
    display: none;
    opacity: 0;
  }
`
export const Screen1 = styled(Screen)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const ScreenDescription = styled.div`
  position: absolute;
  top: calc(0.66rem + env(safe-area-inset-top));
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);
`
export const Screen1Icon = styled.div`
  width: 1.69rem;
  height: 1.89rem;
  background-image: url('${process.env.PUBLIC_URL}/novel-icons/tutorial-hand.svg');
  background-size: 100% 100%;
  position: relative;
`

export const Screen1IconDecorate = styled.div`
  position: absolute;
  background-size: 100% 100%;
`

export const Screen1IconDecorateArrowLeft = styled(Screen1IconDecorate)`
  position: absolute;
  left: -0.25rem;
  top: -0.05rem;
  width: 1.87044rem;
  height: 0.58974rem;
  background-image: url('${process.env.PUBLIC_URL}/novel-icons/tutorial-arrow-left.svg');
  background-size: 100% 100%;
`

export const Screen1IconDecorateArrowClick = styled(Screen1IconDecorate)`
  position: absolute;
  left: 0.05rem;
  top: -0.13rem;
  width: 0.71rem;
  height: 0.71rem;
  background-image: url('${process.env.PUBLIC_URL}/novel-icons/tutorial-click.svg');
  background-size: 100% 100%;
`

export const Screen1IconDecorateArrowRight = styled(Screen1IconDecorate)`
  position: absolute;
  left: -0.25rem;
  top: -0.05rem;
  width: 1.87044rem;
  height: 0.49344rem;
  background-image: url('${process.env.PUBLIC_URL}/novel-icons/tutorial-arrow-right.svg');
  background-size: 100% 100%;
`

export const ScreenItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.15rem;
`
