import { useCallback, useMemo, useRef } from 'react'
import { useAlert, positions } from 'react-alert'

// workaround for react alert calling onClose too early
const AFTER_CLOSE_DELAY = 300

export default function useAlertComicChapterChange() {
  const alert = useAlert()

  const lastAlertFinished = useRef()

  if (!lastAlertFinished.current) {
    lastAlertFinished.current = Promise.resolve()
  }

  const alertChapterChange = useCallback(
    (name) => {
      const prevAnimation = lastAlertFinished.current
      lastAlertFinished.current = new Promise((r) => {
        return prevAnimation.then(() => {
          alert.info(name, {
            position: positions.MIDDLE,
            offset: '300px',
            timeout: 1500,
            onClose: () => setTimeout(r, AFTER_CLOSE_DELAY),
          })
        })
      })
    },
    [alert]
  )

  return useMemo(
    () => ({
      alertChapterChange,
    }),
    [alertChapterChange]
  )
}
