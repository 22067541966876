import AreaTitle from 'component/AreaTitle'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  gap: 0 0.2051rem;
  padding: 0 var(--cms-grid-column-gap);
`

export const StyledAreaTitle = styled(AreaTitle)`
  margin: 0.307rem 0;
`
