import styled from 'styled-components'

export const SwiperWrapper = styled.div`
  flex: 1;
  overflow: hidden;

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-css-mode .swiper-wrapper {
    overscroll-behavior: contain;
  }

  .swiper-slide__content {
    height: 300px;
  }

  // pagination
  .swiper-pagination {
    bottom: 0;

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.8);

      &.swiper-pagination-bullet-active {
        background: #ffffff;
      }
    }
  }

  .swiper-scrollbar {
    display: none;
  }

  // 效果 左右兩側圖片在下方, 凸顯中間圖的效果
  //.swiper-container {
  //  position:relative;
  //  width: 90vw;
  //  height: 300px;
  //}
  //.swiper-container > .swiper-slide__content {
  //  position:absolute;
  //  top:0;
  //}
  //
  //.swiper-slide {
  //  display:flex;
  //  align-items: center;
  //  justify-content:center;
  //  transition:all 200ms linear;
  //
  //  transform: scale(0.8);
  //  &.swiper-slide-prev {
  //    transform: scale(0.8) translateX(3.2rem);
  //  }
  //  &.swiper-slide-next {
  //    transform: scale(0.8) translateX(-3.2rem);
  //  }
  //  &.swiper-slide-active {
  //    transform: scale(1);
  //  }
  //}
  //.swiper-slide__content {
  //  height: 300px;
  //}
  //
  //// pagination
  //.swiper-pagination-bullet {
  //  background: rgba(255, 255, 255, 0.8);
  //
  //  &.swiper-pagination-bullet-active {
  //    background: #ffffff;
  //  }
  //}
`
