import styled from 'styled-components'
import Base64Image from 'component/Base64Image'

export const Button = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.153rem;
  padding: 0.2051rem 0;
  font-weight: 400;
  font-size: 0.3589rem;
  border-radius: 0.564rem;
`

export const Wrapper = styled.div``

export const ContentWrapper = styled.div`
  display: flex;
  gap: var(--cms-grid-row-gap) var(--cms-grid-column-gap);
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  align-items: stretch;
  min-height: 2.8rem;
  flex-direction: column;
`

export const AdImage = styled(Base64Image).attrs({
  placeholder: '/loading/square-loading.svg',
  source: 'picture',
  allowUnload: true,
})`
  /* width: calc(100% - calc(var(--cms-grid-gap-width, 0.186rem) * 2)); */
  /* margin: 0 var(--cms-grid-gap-width, 0.186rem); */
  /* width: 100%; */
  /* height: 2.393rem; */
  border-radius: 0.2564rem;
`

export const FunctionalArea = styled.div`
  width: 100%;
  display: flex;
  gap: 0.2051rem;
`

export const BtnMono = styled(Button)`
  color: var(--component-area-box--button-mono-color);
  background: var(--component-area-box--button-mono-background);
`

export const BtnColorful = styled(Button)`
  color: var(--component-area-box--button-colorful-color);
  background: var(--component-area-box--button-colorful-background);
`

export const Bottom = styled.div`
  padding: 0 var(--cms-grid-column-gap);
  width: 100%;
  display: block;
`

export const BottomRow = styled.div`
  padding: var(--cms-grid-row-gap) var(--cms-grid-column-gap) 0 var(--cms-grid-column-gap);
  width: 100%;
  display: block;
`

export const TriggerElement = styled.div`
  height: 200vh;
  width: 100%;
`
