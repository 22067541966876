import { useEffect, useMemo, useRef } from 'react'
//

import { PageWrapperNonScroll } from 'commonStyledComponents'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import PageTabs, { PageTab } from 'component/PageTabs'
import VideoTab from './component/Tab/VideoTab'

export default function RankViewMore() {
  const { infoList, headerTitle = '排行榜', type, tabIndex } = useParamsOfPage('rank-view-more')

  const tabRef = useRef()

  // const loading = useRef(false)

  const tabs = useMemo(() => {
    if (!infoList) return []
    return infoList.map((m, index) => {
      return {
        id: index,
        name: m.title,
      }
    })
  }, [infoList])

  useEffect(() => {
    tabRef.current?.changeTab(tabIndex)
  }, [tabIndex])

  return (
    <PageWrapperNonScroll>
      <PageTabs ref={tabRef} title={headerTitle} tabs={tabs}>
        {infoList?.map((m, index) => (
          <PageTab key={'p_' + index}>
            <VideoTab type={type} params={m.params} />
          </PageTab>
        ))}
      </PageTabs>
    </PageWrapperNonScroll>
  )
}
