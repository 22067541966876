import { Container, StyledCmsGridWrapper } from '../../Styled'
import NullResult from '../NullResult'
import RecommendTagList from '../RecommendTagList'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from 'component/Loading'
import VideoBox from 'component/VideoBox'
import VideoBoxVertical from 'component/VideoBoxVertical'
import ComicBox from 'component/ComicBox'
import NovelBox from 'component/NovelBox'
import HistoryPanel from '../HistoryPanel'
import { AreaPanelWrapper, LoadingWrap, SlideContent } from './Styled'

export function SearchTab({ mode, searchTitle, state, handleFetchVideoList, shortInfo, onHistoryClick }) {
  return (
    <SlideContent>
      {searchTitle ? (
        <>
          {!state?.list?.length && !state.hasMore && !state.isPlaceHolder && (
            <Container
              onLoadCapture={(e) => {
                e.stopPropagation()
              }}
            >
              <NullResult />
              {/* 無搜尋結果時也顯示搜索推薦 */}
              {mode === 'video' && <RecommendTagList mode={mode} />}
            </Container>
          )}
          {/* {state.isPlaceHolder && <Loading.Bounce key={'loader'} />} */}
          <div style={{ flex: 1, overflow: 'auto', width: '100%', height: '100%' }}>
            <InfiniteScroll pageStart={0} loadMore={handleFetchVideoList} hasMore={state?.hasMore} useWindow={false}>
              <AreaPanelWrapper>
                {(state.isPlaceHolder || state.isLoading) && (
                  <LoadingWrap>
                    <Loading.Bounce key={'loader'} />
                  </LoadingWrap>
                )}
                <StyledCmsGridWrapper
                  onLoadCapture={(e) => {
                    e.stopPropagation()
                  }}
                >
                  {state?.list?.map((item, i) => {
                    if (mode === 'video') {
                      return <VideoBox key={i} videoInfo={item}></VideoBox>
                    }
                    if (mode === 'short') {
                      return <VideoBoxVertical key={i} videoInfo={item} shortInfo={shortInfo}></VideoBoxVertical>
                    }
                    if (mode === 'comic') {
                      return <ComicBox key={i} comicInfo={item}></ComicBox>
                    }
                    if (mode === 'novel') {
                      return <NovelBox key={i} novelInfo={item} columns={3}></NovelBox>
                    }
                    console.warn('unknown type ' + mode)
                    return null
                  })}
                </StyledCmsGridWrapper>
              </AreaPanelWrapper>
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <Container
          onLoadCapture={(e) => {
            e.stopPropagation()
          }}
        >
          {/* 搜索面板 */}
          <HistoryPanel
            searchWords={searchTitle}
            isLoading={state.isLoading}
            searchResult={state?.list}
            onHistoryClick={onHistoryClick}
            mode={mode}
          />
          {/* 搜索推薦 */}
          <RecommendTagList mode={mode} />
        </Container>
      )}
    </SlideContent>
  )
}
