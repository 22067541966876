import Video from './components/Video'
import Short from './components/Short'
import Comic from './components/Comic'
import PageTabs from 'component/PageTabs'
import Novel from './components/Novel'
import { useContentTab } from 'hooks/useContentTab'

export default function BrowseCollection({ ...props }) {
  const { tabs, tabContents } = useContentTab({
    video: Video,
    short: Short,
    comic: Comic,
    novel: Novel,
  })

  return (
    <PageTabs {...props} title="我的收藏" tabs={tabs}>
      {tabContents}
    </PageTabs>
  )
}
