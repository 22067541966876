import EmptyResult from 'component/EmptyResult'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_COMIC_COLLECTION, RESET_COMIC_COLLECTION } from 'redux/constant/comicCollection'
import { selectComicCollectionData } from 'redux/selector/comicCollection'
import ComicBox from 'component/ComicBox'
import { AreaPanelCmsGridWrapper, InfiniteScrollWrapper, PageScrollWrap } from 'commonStyledComponents'

const Comic = () => {
  const dispatch = useDispatch()
  const videoComicCollections = useSelector(selectComicCollectionData)

  const runOnce = useRef(false)
  const handleFetchCollectionList = useCallback(() => {
    if (!runOnce.current) {
      runOnce.current = true
      dispatch({ type: RESET_COMIC_COLLECTION })
    }

    dispatch({ type: FETCH_COMIC_COLLECTION })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: RESET_COMIC_COLLECTION })
    dispatch({ type: FETCH_COMIC_COLLECTION })
  }, [dispatch])

  return !videoComicCollections?.list?.length && !videoComicCollections.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollWrapper
        pageStart={0}
        loadMore={handleFetchCollectionList}
        hasMore={videoComicCollections?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {videoComicCollections?.list?.length > 0 && (
          <AreaPanelCmsGridWrapper>
            {videoComicCollections?.list?.map((item, i) => (
              <ComicBox key={i} comicInfo={item} columns={3}>
                {i}
              </ComicBox>
            ))}
          </AreaPanelCmsGridWrapper>
        )}
      </InfiniteScrollWrapper>
    </PageScrollWrap>
  )
}

export default Comic
