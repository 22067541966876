import { useCallback } from 'react'
//
import { fetchPostAdEvent } from 'api'
import { Image } from './Styled'
import { formatDate } from 'utils/timeFormat'
import { report } from 'hooks/useAnalytics'

export default function CarouselItem({ noLazy, allowUnload = false, ...info }) {
  const onItemClick = useCallback(() => {
    if (info) {
      report({
        SerialNumber: 6,
        Event: 'cms_click',
        Trigger: '點擊頻道輪播',
        Parameters: 'channel_carousel_id',
        Value: info.id,
      })
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: info.id,
        material_name: info.name,
        oholo_site_id: info.site_id,
        oholoer_id: info.oholoer_id,
      })
      window.open(info?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [info])

  return <Image src={info.icon_path} noLazy={noLazy} onClick={onItemClick} allowUnload={allowUnload} />
}
