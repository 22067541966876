import { useRef, useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { useImmer } from 'use-immer'

import HeaderBox from 'component/PageModalHeaderBox'
import VideoBox from 'component/VideoBox'

import { fetchVideoList } from 'api'

import { CategoryBox, CategoryContent, Content, CategoryType, NoDataWrapper, HintBoxWrapper, HintBox } from './Styled'
import { useSelector } from 'react-redux'
import { selectProducerList, selectRegionList } from 'redux/selector/app'
import EmptyResult from 'component/EmptyResult'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { HeaderWrap, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useHasPayment } from 'hooks/useHasPayment'
import { videoPaymentType } from 'constant/video'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { ENABLED } from 'constant/common'

const getDefaultId = (list) => {
  return list.find((i) => i.default)?.id
}

const isDefaultId = (list, id) => {
  return list.find((i) => i.default)?.id === id
}

const withDefaultId = (value, list) => {
  if (value == null) {
    return getDefaultId(list)
  }
  return value
}

const withoutDefaultId = (value, list) => {
  if (isDefaultId(list, value)) {
    return undefined
  }
  return value
}
const selectItem = (list, value) => {
  const item = list.find((i) => i.id === value)
  const defaultItem = list.find((i) => i.default)
  return item ?? defaultItem
}

export default function ViaCategory() {
  // the fetcher controller
  const fetching = useRef(null)
  const { sort, payment, region, producer } = useParamsOfPage('filter-video')
  const { navigateToSearchViaCategory } = useNavigateTo()

  const regionListRaw = useSelector(selectRegionList)
  const regionList = useMemo(() => {
    return regionListRaw?.filter((i) => i.status === ENABLED).sort((i, j) => j.seqno - i.seqno) ?? []
  }, [regionListRaw])
  const producerListRaw = useSelector(selectProducerList)?.data
  const producerList = useMemo(() => {
    return producerListRaw ?? []
  }, [producerListRaw])

  const hasPayment = useHasPayment()

  const sortOptions = useMemo(
    () => [
      {
        name: '综合排序',
        id: 'complex',
        default: true,
        params: {
          sortby: 'complex',
        },
      },
      {
        name: '最新',
        id: 'new',
        params: {
          sortby: 'on_shelf_at',
        },
      },
      {
        name: '最热',
        id: 'hot',
        params: {
          sortby: 'hot',
        },
      },
    ],
    []
  )

  const paymentTypeOptions = useMemo(
    () => [
      {
        name: '全部',
        id: 'all',
        default: true,
        params: {},
      },
      {
        name: '免费',
        id: 'free',
        params: {
          payment_type: videoPaymentType.FREE,
        },
      },
      {
        name: 'VIP',
        id: 'vip',
        params: {
          payment_type: videoPaymentType.VIP,
        },
      },
      {
        name: '金币',
        id: 'paid',
        params: {
          payment_type: videoPaymentType.COIN,
        },
      },
    ],
    []
  )

  const regionOptions = useMemo(
    () => [
      {
        name: '全部地区',
        id: 'all',
        default: true,
        params: {},
      },
      ...regionList.map((item) => ({
        name: item.name,
        id: String(item.id),
        params: {
          region_id: item.id,
        },
      })),
    ],
    [regionList]
  )

  const producerOptions = useMemo(
    () => [
      {
        name: '全部片商',
        id: 'all',
        default: true,
        params: {},
      },
      ...producerList.map((item) => ({
        name: item.name,
        id: String(item.id),
        params: {
          producer_ids: item.id,
        },
      })),
    ],
    [producerList]
  )

  const sortMode = withDefaultId(sort, sortOptions)
  const paymentMode = withDefaultId(payment, paymentTypeOptions)
  const regionMode = withDefaultId(region, regionOptions)
  const producerMode = withDefaultId(producer, producerOptions)

  const setSortMode = (mode) => {
    navigateToSearchViaCategory(
      {
        sort: withoutDefaultId(mode, sortOptions),
        payment,
        region,
        producer,
      },
      { replace: true }
    )
  }
  const setPaymentMode = (mode) => {
    navigateToSearchViaCategory(
      {
        sort,
        payment: withoutDefaultId(mode, paymentTypeOptions),
        region,
        producer,
      },
      { replace: true }
    )
  }
  const setRegionMode = (mode) => {
    navigateToSearchViaCategory(
      {
        sort,
        payment,
        region: withoutDefaultId(mode, regionOptions),
        producer,
      },
      { replace: true }
    )
  }
  const setProducerMode = (mode) => {
    navigateToSearchViaCategory(
      {
        sort,
        payment,
        region,
        producer: withoutDefaultId(mode, producerOptions),
      },
      { replace: true }
    )
  }

  const currentParams = useMemo(() => {
    return {
      ...selectItem(sortOptions, sortMode).params,
      ...selectItem(paymentTypeOptions, paymentMode).params,
      ...selectItem(regionOptions, regionMode).params,
      ...selectItem(producerOptions, producerMode).params,
    }
  }, [sortOptions, sortMode, paymentTypeOptions, paymentMode, regionOptions, regionMode, producerOptions, producerMode])

  const [videoData, updateVideoData] = useImmer(() => ({
    currentOptions: currentParams,
    list: [],
    nextPage: 1,
    hasMore: true,
    page_result: {},
    tag_version: '',
  }))

  useEffect(() => {
    if (JSON.stringify(currentParams) !== JSON.stringify(videoData.currentOptions)) {
      fetching.current?.abort()
      fetching.current = null
      updateVideoData((arg) => {
        arg.currentOptions = currentParams
        arg.list = []
        arg.hasMore = true
        arg.nextPage = 1
        arg.page_result = {}
        arg.tag_version = ''
      })
    }
  }, [currentParams, updateVideoData, videoData.currentOptions])

  const filterSummary =
    selectItem(sortOptions, sortMode).name +
    (!selectItem(paymentTypeOptions, paymentMode).default
      ? ` / ${selectItem(paymentTypeOptions, paymentMode).name}`
      : '') +
    (!selectItem(regionOptions, regionMode).default ? ` / ${selectItem(regionOptions, regionMode).name}` : '') +
    (!selectItem(producerOptions, producerMode) ? ` / ${selectItem(producerOptions, producerMode).name}` : '')

  const handleFetchVideoList = () => {
    if (!videoData.hasMore) return
    if (fetching.current != null) return
    const fetcherController = new AbortController()

    fetching.current = fetcherController

    const data = {
      signal: fetcherController.signal,
      current: videoData.nextPage,
      pageSize: 30,
      ...videoData.currentOptions,
    }

    fetchVideoList(data)
      .then((response) => {
        if (fetching.current === fetcherController) {
          fetching.current = null
        }
        updateVideoData((draft) => {
          draft.list = draft.list.concat(response?.data)
          draft.hasMore =
            response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
          draft.nextPage = response?.page_result?.current + 1
          draft.page_result = response.page_result
        })
      })
      .catch(() => {
        if (fetching.current === fetcherController) {
          fetching.current = null
        }
      })
  }

  const style = useMemo(
    () => ({
      display: 'flex',
      gap: 'var(--cms-grid-row-gap, 0.093rem) var(--cms-grid-column-gap, 0.093rem)',
      flexWrap: 'wrap',
      flex: '0 0 auto',
      padding: '0 var(--cms-grid-column-gap, 0.093rem)',
    }),
    []
  )

  const expandRange = (current, [rangeMin, rangeMax], [toMin, toMax]) => {
    const expanded = ((current - rangeMin) / (rangeMax - rangeMin)) * (toMax - toMin) + toMin
    const capped = Math.min(toMax, Math.max(toMin, expanded))
    return capped
  }

  const { ref, containerRef, ratio } = useIntersectionObserver({
    threshold: Array.from({ length: 19 }).map((_, index) => (index + 1) / 19),
    defaultVisible: true,
  })

  const mappedRatio = expandRange(ratio, [0.5, 0.3], [0, 1])

  return (
    <PageWrapperNonScroll ref={containerRef}>
      {/* Duplicated header with transparent background to support linear gradient header */}
      <HintBoxWrapper style={{ opacity: mappedRatio }}>
        <HeaderWrap style={{ background: 'transparent' }}>
          <HeaderBox headerTitle={'筛选'} />
        </HeaderWrap>
        <HintBox>{filterSummary}</HintBox>
      </HintBoxWrapper>
      <HeaderWrap>
        <HeaderBox headerTitle={'筛选'} />
      </HeaderWrap>
      <PageScrollWrap>
        <CategoryBox ref={ref}>
          <CategoryContent>
            <Content>
              {sortOptions.map((item, index) => {
                return (
                  <CategoryType
                    className={item.id === sortMode ? 'selected' : ''}
                    key={index}
                    onClick={() => (item.id === sortMode ? null : setSortMode(item.id))}
                  >
                    {item.name}
                  </CategoryType>
                )
              })}
            </Content>

            {hasPayment && (
              <Content>
                {paymentTypeOptions.map((item, index) => {
                  return (
                    <CategoryType
                      className={item.id === paymentMode ? 'selected' : ''}
                      key={index}
                      onClick={() => (item.id === paymentMode ? null : setPaymentMode(item.id))}
                    >
                      {item.name}
                    </CategoryType>
                  )
                })}
              </Content>
            )}

            <Content>
              {regionOptions.map((item, index) => {
                return (
                  <CategoryType
                    className={item.id === regionMode ? 'selected' : ''}
                    key={index}
                    onClick={() => (item.id === regionMode ? null : setRegionMode(item.id))}
                  >
                    {item.name}
                  </CategoryType>
                )
              })}
            </Content>

            <Content>
              {producerOptions.map((item, index) => {
                return (
                  <CategoryType
                    className={item.id === producerMode ? 'selected' : ''}
                    key={index}
                    onClick={() => (item.id === producerMode ? null : setProducerMode(item.id))}
                  >
                    {item.name}
                  </CategoryType>
                )
              })}
            </Content>
          </CategoryContent>
        </CategoryBox>
        <InfiniteScroll
          style={style}
          pageStart={0}
          loadMore={handleFetchVideoList}
          hasMore={videoData?.hasMore}
          // loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {videoData.list.map((item, i) => {
            return <VideoBox key={i} videoInfo={item} />
          })}
        </InfiniteScroll>
        {videoData.list.length === 0 && !videoData.hasMore && (
          <NoDataWrapper>
            <EmptyResult></EmptyResult>
          </NoDataWrapper>
        )}
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
