import { PageTab } from 'component/PageTabs'
import { VideoTypeEnum } from 'constant/common'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectNavigationContent } from 'redux/selector/app'

export const SEARCH_TYPE_ID = {
  video: VideoTypeEnum.LongVideo,
  short: VideoTypeEnum.ShortVideo,
  comic: VideoTypeEnum.Comic,
  novel: VideoTypeEnum.Novel,
}

export const SEARCH_TYPE_TABS = [
  {
    id: 'video',
    name: '长视频',
  },
  {
    id: 'short',
    name: '短视频',
  },
  {
    id: 'comic',
    name: '漫画',
  },
  {
    id: 'novel',
    name: '小说',
  },
]

/**
 *
 * @param {{
 * video: JSX.Element,
 * short: JSX.Element,
 * comic: JSX.Element,
 * novel: JSX.Element} components
 * @returns
 */
export const useContentTab = (components = {}, params = {}) => {
  const navs = useSelector(selectNavigationContent)
  const searchTypes = useMemo(() => {
    const list = navs[0]?.search_types ?? []
    if (Array.isArray(params.filter)) return list.filter((m) => params.filter.includes(m))
    return list
  }, [navs, params])
  const tabs = useMemo(() => SEARCH_TYPE_TABS.filter((i) => searchTypes.includes(SEARCH_TYPE_ID[i.id])), [searchTypes])

  const hasVideo = useMemo(() => {
    return searchTypes.findIndex((m) => m === SEARCH_TYPE_ID.video) >= 0
  }, [searchTypes])

  const hasShort = useMemo(() => {
    return searchTypes.findIndex((m) => m === SEARCH_TYPE_ID.short) >= 0
  }, [searchTypes])

  const hasComic = useMemo(() => {
    return searchTypes.findIndex((m) => m === SEARCH_TYPE_ID.comic) >= 0
  }, [searchTypes])

  const hasNovel = useMemo(() => {
    return searchTypes.findIndex((m) => m === SEARCH_TYPE_ID.novel) >= 0
  }, [searchTypes])

  const tabContents = useMemo(() => {
    if (!components) return <></>
    return (
      <>
        {components.video && hasVideo && (
          <PageTab>
            <components.video />
          </PageTab>
        )}
        {components.short && hasShort && (
          <PageTab>
            <components.short />
          </PageTab>
        )}
        {components.comic && hasComic && (
          <PageTab>
            <components.comic />
          </PageTab>
        )}
        {components.novel && hasNovel && (
          <PageTab>
            <components.novel />
          </PageTab>
        )}
      </>
    )
  }, [components, hasComic, hasNovel, hasShort, hasVideo])

  return { tabs, hasVideo, hasShort, hasComic, hasNovel, tabContents }
}
