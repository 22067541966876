/**
 *   jump_type  integer
 *   跳轉類型(1:片商, 2:演員, 3:片商列表, 4:演員列表, 5:開通VIP, 6: 鏈結, 7: NAV, 8: 遊戲)
 */
export const ShortcutJumpType = {
  Producer: 1,
  Actor: 2,
  ProducerList: 3,
  ActorList: 4,
  EnabledVIP: 5,
  Link: 6,
  Nav: 7,
  Game: 8,
}
