import { CSSProperties } from 'styled-components'
import styles from './Style.module.css'
import {
  Fragment,
  ReactNode,
  UIEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { useEvent } from 'hooks/useEvent'
import { pos } from './effectUtils'
import { createEffectRight } from './effect'

const PARAGRAPH_GAP = 0.0
const MIN_PARAGRAPH_LINE_HEIGHT = 1
// const PAGE_OVERLAP = 2
// const EXTRA_PAGE_DISTANCE = 3
const RESIZE_DEFER_DURATION_MS = 100
type PageSlice = [pIndex: number, offset: number, wrapAt: number | null]
type Page = PageSlice[]

// Effect => ResizeObserver: DOM => readerSize
// LayoutEffect: readerSize, paragraphs, fonts => readerContext
// ~~useMemo: readerContext => offsets~~
// useMemo readerContex ~~, offsets~~ => pages

interface ReaderContext {
  height: number
  width: number
  lineHeight: number
  paragraphSizes: number[]
  paragraphs: Paragraph[]
  state: ReaderState
  font: string
  currentChildren:
    | {
        prepend?: ReactNode[]
        append?: ReactNode[]
      }
    | undefined
}

interface Paragraph {
  text: string
  style?: CSSProperties
}

export interface NovelReaderInstance {
  changePage(page: number): void
}

type ReaderState = 'initial' | 'wait' | 'read-progress' | 'change-dimension' | 'write-progress'

/**
 *         size change / font change ...etc
 * ┌────────────────┐            ┌────────────────┐
 * │    wait        ├───────────►│  read-progress │
 * └───────▲────────┘            └────────┬───────┘
 *         │ progress applied             │ apply size change, trigger on prev step finished
 * ┌───────┴────────┐            ┌────────▼──────────┐            ┌─────────┐
 * │ write-progress │◄───────────┤ change-dimension  │◄───────────┤ initial |
 * └────────────────┘            └───────────────────┘            └─────────┘
 *         change applied + page rerendered             initial load
 */

export interface NovelContent {
  paragraphs: []
  /**
   * could also be a negative number to indicate position from end
   */
  initialPage: number
}

export interface NovelReaderProps {
  // paragraphs: Paragraph[],
  content: NovelContent
  font?: string
  onPageCountChange?: (pageCount: number) => void
  onCurrentPageChange?: (currentPage: number) => void
  onEnterExtraPage?: (type: 'prepend' | 'append', index: number) => void
  onScroll: () => void
  className?: string
  style?: React.CSSProperties
  children?: {
    prepend?: ReactNode[]
    append?: ReactNode[]
  }
}

const debugLog =
  process.env.NODE_ENV === 'development' ? (...args: any[]) => console.log('NovelReader: ', ...args) : () => {}

function ParagraphElement({
  style,
  children,
  skipLines,
  lineHeight,
  ...props
}: Omit<React.ComponentProps<'div'>, 'ref'> & { lineHeight: number; skipLines: number }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLSpanElement>(null)
  const [lineCount, setLineCount] = useState(0)
  const [padLeft, setPadLeft] = useState(0)
  const [padRight, setPadRight] = useState(0)
  const [pixelBase, setPixelBase] = useState(0)
  const [hasContent, setHasContent] = useState(false)

  const resizeEvent = useEvent((el: HTMLDivElement) => {
    const height = textRef.current?.getBoundingClientRect().height ?? 0
    const box = el.getBoundingClientRect()
    setPixelBase(box.y % (1 / devicePixelRatio))

    const computedStyle = getComputedStyle(el)

    setPadLeft(Number(computedStyle.paddingLeft.replace('px', '')))
    setPadRight(Number(computedStyle.paddingRight.replace('px', '')))

    setLineCount(Math.ceil(height / lineHeight))
    setHasContent(height > 0)
  })
  useEffect(() => {
    if (containerRef.current == null) return
    const el = containerRef.current

    const cb: ResizeObserverCallback = () => {
      resizeEvent(el)
    }
    const obs = new ResizeObserver(cb)
    obs.observe(el)
    return () => {
      obs.disconnect()
    }
  }, [resizeEvent])

  const mergedStyle = useMemo(
    (): React.CSSProperties => ({
      ...(style ?? {}),
      ...(!hasContent
        ? { minHeight: `${lineHeight + 1}px` }
        : { minHeight: style?.height ? `calc(${style?.height} + 1px)` : `${lineCount * lineHeight + 1}px` }),
    }),
    [hasContent, lineCount, lineHeight, style]
  )

  const lineHeightDevice = Math.round(lineHeight * devicePixelRatio) / devicePixelRatio

  return (
    <div {...props} style={mergedStyle} ref={containerRef}>
      {Array.from({ length: hasContent ? lineCount : 1 }).map((_, index) => {
        if (index >= skipLines) {
          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: `${padLeft}px`,
                right: `${padRight}px`,
                top: `0`,
                height: '1px',
                transform: `translateY(-50%) translateY(${(index + 1) * lineHeightDevice - pixelBase}px)`,
                background: 'var(--line-color, currentColor)',
              }}
            ></div>
          )
        } else {
          return <Fragment key={index}></Fragment>
        }
      })}
      <span ref={textRef}>{children}</span>
    </div>
  )
}

export default forwardRef<NovelReaderInstance, NovelReaderProps>(function NovelReader(
  {
    // paragraphs: initialParagraphs = [] as Paragraph[] ,
    content: { paragraphs: initialParagraphs = [] as Paragraph[], initialPage },
    font: initialFont = '16px sans-serif',
    onPageCountChange = (pageCount) => {},
    onCurrentPageChange = (currentPage) => {},
    onEnterExtraPage = (type, index) => {},
    className,
    style,
    onScroll: onScrollProp,
    children,
    ...props
  },
  ref
) {
  const [state, setState] = useState<ReaderState>('initial')

  useImperativeHandle(ref, () => ({
    changePage(page: number) {
      const normalizedPage = Math.min(Math.max(page, 0), (pages?.length ?? 1) - 1)
      setCurrentProgress(normalizedPage)
      setCurrentProgressMode('page')
      setState('write-progress')
    },
  }))

  // temp zone for reader size that haven't applied to layout
  const [readerSizeStash, setReaderSizeStash] = useState({ width: 0, height: 0 })

  // current actual readerSize that you will see on screen
  const [readerSize, setReaderSize] = useState({ width: 0, height: 0 })

  const [currentRawPage, setCurrentRawPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [font, setFont] = useState(initialFont)
  const [paragraphs, setParagraphs] = useState(initialParagraphs)
  const [readerContext, setReaderContext] = useState<ReaderContext | null>(null)

  const prependPageCount = readerContext?.currentChildren?.prepend?.length ?? 0
  const appendPageCount = readerContext?.currentChildren?.append?.length ?? 0

  // Update readerSize
  const readerRef = useRef<HTMLDivElement>(null)
  const scrollerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (font !== initialFont) {
      setState('read-progress')
    }
  }, [font, initialFont])

  useLayoutEffect(() => {
    if (state === 'wait') {
      if (paragraphs !== initialParagraphs) {
        debugLog('== Reinitialize due to content change ==')
        debugLog('== initial page: ' + initialPage + ' ==')
        setCurrentProgressMode('initial')
        setCurrentProgress(initialPage)
        setParagraphs(initialParagraphs)
        setState('initial')
      }
    }
  }, [initialPage, initialParagraphs, paragraphs, state])

  useLayoutEffect(() => {
    if (readerRef.current == null) return
    if (state !== 'wait' && state !== 'initial') {
      return
    }

    const el = readerRef.current
    let timer: ReturnType<typeof setTimeout>

    const resizeObserver = new ResizeObserver((entries) => {
      for (const item of entries) {
        const size = item.contentRect
        const newDimension = {
          width: size.width,
          height: size.height,
        }
        if (state === 'initial') {
          if (timer != null) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            setReaderSizeStash(newDimension)
            // first run
            setReaderSize(newDimension)
            setState('change-dimension')
            setCurrentProgressMode('initial')
            setCurrentProgress(initialPage)

            resizeObserver.disconnect()
          }, RESIZE_DEFER_DURATION_MS)
        }
        if (newDimension.width !== readerSize.width || newDimension.height !== readerSize.height) {
          if (state === 'wait') {
            if (timer != null) {
              clearTimeout(timer)
            }
            timer = setTimeout(() => {
              setReaderSizeStash(newDimension)
              setState('read-progress')

              resizeObserver.disconnect()
            }, RESIZE_DEFER_DURATION_MS)
          }
        }
      }
    })

    resizeObserver.observe(el)

    return () => {
      if (timer != null) {
        clearTimeout(timer)
      }
      resizeObserver.disconnect()
    }
  }, [initialPage, readerSize.height, readerSize.width, state])

  // Update readerContext
  const rulerLineEl = useRef<HTMLDivElement>(null)
  const rulerEls = useRef([] as HTMLElement[])
  useLayoutEffect(() => {
    if (readerSize.height === 0) {
      return
    }
    if (rulerLineEl.current == null) {
      return
    }

    debugLog('run sample ', state)

    if (state !== 'change-dimension') {
      setReaderContext((ctx) =>
        ctx != null
          ? {
              ...ctx,
              state,
            }
          : null
      )
      return
    }

    const box = rulerLineEl.current.getBoundingClientRect()
    const lineHeight = box.height

    const start = Date.now()
    const minParagraphHeight = lineHeight * MIN_PARAGRAPH_LINE_HEIGHT
    const sizes = rulerEls.current.map((i) => {
      return Math.max(i.getBoundingClientRect().height, minParagraphHeight)
    })

    debugLog(`Sampled ${rulerEls.current.length} paragraphs, total ${Date.now() - start} ms`)

    setReaderContext({
      height: readerSize.height,
      width: readerSize.width,
      lineHeight,
      paragraphSizes: sizes,
      paragraphs: paragraphs,
      state,
      font,
      currentChildren: children,
    })
  }, [readerSize.height, readerSize.width, font, paragraphs, state, children])

  const pages = useMemo(() => {
    if (readerContext?.paragraphSizes === null || readerContext?.lineHeight == null || readerContext?.height == null) {
      return null
    }

    const pages: Page[] = []
    const gap = PARAGRAPH_GAP * readerContext.lineHeight
    let currentPage = 0
    let heightSum = 0
    for (let i = 0; i < readerContext.paragraphSizes.length; i++) {
      const currentParagraphSize = readerContext.paragraphSizes[i]

      if (pages[currentPage] == null) {
        pages[currentPage] = []
      }
      const currentPageItem = pages[currentPage]
      const newOffset = heightSum + gap
      const newHeightSum = heightSum + gap + currentParagraphSize
      if (newHeightSum > readerContext.height) {
        // wrapAt
        const space = readerContext.height - (newHeightSum - currentParagraphSize)
        const fullLines = Math.floor(space / readerContext.lineHeight) * readerContext.lineHeight
        currentPageItem.push([i, newOffset, fullLines])
      } else {
        currentPageItem.push([i, newOffset, null])
      }
      heightSum = newHeightSum
      // special handling for page that overflow
      const fixRemaining = (h) => Math.ceil(h / readerContext.lineHeight) * readerContext.lineHeight
      if (heightSum > readerContext.height) {
        let remaining = fixRemaining(heightSum - readerContext.height)
        while (heightSum > readerContext.height) {
          pages[currentPage + 1] = []
          currentPage++
          heightSum = remaining
          if (remaining > readerContext.height) {
            const maxFit = Math.floor(readerContext.height / readerContext.lineHeight) * readerContext.lineHeight
            pages[currentPage].push([
              i,
              -(currentParagraphSize - remaining),
              maxFit + (currentParagraphSize - remaining),
            ])
          } else {
            pages[currentPage].push([i, -(currentParagraphSize - remaining), null])
          }
          remaining = fixRemaining(remaining - readerContext.height)
        }
      }
    }
    debugLog(`parsing finished, total ${pages.length} pages`)
    return pages
  }, [readerContext?.height, readerContext?.lineHeight, readerContext?.paragraphSizes])

  const capPageNumber = useCallback(
    (page: number) => {
      return Math.min(Math.max(page, 0), (pages?.length ?? 0) - 1)
    },
    [pages?.length]
  )

  const emitCurrentPage = useCallback(
    (number: number) => {
      onCurrentPageChange(capPageNumber(number))
    },
    [onCurrentPageChange, capPageNumber]
  )

  const emitExtraPage = useCallback(
    (number: number) => {
      if (number < 0) {
        onEnterExtraPage('prepend', number + prependPageCount)
      }

      if (number >= (pages?.length ?? 0)) {
        onEnterExtraPage('append', number - (pages?.length ?? 0))
      }
    },
    [onEnterExtraPage, pages?.length, prependPageCount]
  )

  const userInteractionSinceLastRead = useRef(true)
  const [currentProgressMode, setCurrentProgressMode] = useState<'page' | 'paragraph' | 'initial'>('paragraph')
  const [currentProgress, setCurrentProgress] = useState(0)

  useLayoutEffect(() => {
    if (readerContext?.state === 'read-progress') {
      if (userInteractionSinceLastRead.current) {
        const firstParagraph = pages?.[currentPage]?.[0][0] ?? 0
        setCurrentProgressMode('paragraph')
        setCurrentProgress(firstParagraph)
        debugLog('read paragraph ', pages?.[currentPage]?.[0][0] ?? 0)
        userInteractionSinceLastRead.current = false
      } else {
        debugLog('skip read paragraph due to no recent user interaction')
      }
      // apply any change before measuring
      setReaderSize(readerSizeStash)
      setFont(initialFont)
      setState('change-dimension')
    }
  }, [currentPage, initialFont, pages, readerContext?.state, readerSizeStash])

  useLayoutEffect(() => {
    if (readerContext?.state === 'change-dimension') {
      setState('write-progress')
    }
  }, [initialFont, readerContext?.state])

  useLayoutEffect(() => {
    if (readerContext?.state === 'write-progress') {
      debugLog('write progress ', currentProgressMode, currentProgress)
      let targetPage: number
      if (currentProgressMode === 'paragraph') {
        const targetPageFirst =
          pages?.findIndex((i) => i.find(([pIndex, offset]) => pIndex === currentProgress) != null) ?? 0
        targetPage = targetPageFirst < 0 ? 0 : targetPageFirst
      } else if (currentProgressMode === 'initial') {
        targetPage = currentProgress < 0 ? (pages?.length ?? 0) + currentProgress : currentProgress
      } else {
        targetPage = currentProgress
      }
      setCurrentRawPage(targetPage)
      setCurrentPage(targetPage)
      emitCurrentPage(targetPage)
      // emitExtraPage(targetPage)

      const scrollerEl = scrollerRef.current

      if (scrollerEl) {
        scrollerEl.style.overflow = 'hidden'
        scrollerEl.scrollLeft = (targetPage + prependPageCount) * readerSize.width
      }

      let id: null | ReturnType<typeof setTimeout> = setTimeout(() => {
        // we need this to force stop safari scroll animation
        if (scrollerEl) {
          scrollerEl.style.overflow = ''
        }
        debugLog('change to wait ')
        setState('wait')
        id = null
      }, 0)

      return () => {
        if (id) {
          clearTimeout(id)
          if (scrollerEl) {
            scrollerEl!.style.overflow = ''
          }
        }
      }
    }
  }, [currentProgress, currentProgressMode, emitCurrentPage, emitExtraPage, pages, prependPageCount, readerContext?.state, readerSize.width, readerSizeStash])

  useEffect(() => {
    onPageCountChange(pages?.length ?? 0)
  }, [onPageCountChange, pages?.length])

  const onRulerElLoaded = (index: number, el: HTMLDivElement | null) => {
    if (el) {
      rulerEls.current[index] = el
    } else {
      rulerEls.current.length = Math.max(index - 1, 0)
    }
  }

  const rulerJSX = useMemo(() => {
    return (
      <div className={`${styles.ruler}`}>
        <div className={`${styles.rulerItem}`} ref={rulerLineEl}>
          {/* text for measure line height */}
          中文ABIJjph
        </div>

        {paragraphs.map((paragraph, index) => (
          <div
            key={index}
            className={`${styles.rulerItem}`}
            style={paragraph.style}
            ref={(el) => onRulerElLoaded(index, el)}
          >
            {paragraph.text}
          </div>
        ))}
      </div>
    )
  }, [paragraphs])

  const fillerJSX = useMemo(() => {
    const res = (
      <div
        className={styles.fillerWrap}
        style={{ '--page-count': String((pages?.length ?? 0) + prependPageCount + appendPageCount) } as any}
      >
        {Array.from({ length: (pages?.length ?? 0) + prependPageCount + appendPageCount }).map((_, index) => (
          <div key={'filler -' + index} className={styles.filler}></div>
        ))}{' '}
      </div>
    )
    return res
  }, [appendPageCount, pages?.length, prependPageCount])

  const pageList = useMemo(() => {
    const prepends = readerContext?.currentChildren?.prepend ?? []
    const appends = readerContext?.currentChildren?.append ?? []
    const pageItems = pages ?? []

    const pageList = [
      ...prepends.map((i, index) => (
        <div
          key={`page-${index - prependPageCount}`}
          data-key={`page-${index - prependPageCount}`}
          className={classNames(styles.fixedPage, styles.extraPage)}
          style={{
            width: `${readerContext?.width}px`,
            height: `${readerContext?.height}px`,
          }}
        >
          {i}
        </div>
      )),
      ...pageItems.map((page, index) => (
        <div
          key={`page-${index + prepends.length}`}
          data-key={`page-${index + prepends.length}`}
          className={styles.fixedPage}
          style={{
            width: `${readerContext?.width}px`,
            height: `${readerContext?.height}px`,
          }}
        >
          {page.map(([pIndex, offset, wrapAt], index) => (
            <ParagraphElement
              key={index}
              className={`${styles.paragraphItem}`}
              skipLines={offset < 0 ? -offset / (readerContext?.lineHeight ?? 0) : 0}
              lineHeight={readerContext?.lineHeight ?? 0}
              style={{
                top: `${offset}px`,
                ...readerContext?.paragraphs[pIndex].style,
                ...(wrapAt !== null ? { height: wrapAt + 'px' } : {}),
              }}
            >
              {readerContext?.paragraphs[pIndex].text}
            </ParagraphElement>
          ))}
        </div>
      )),
      ...appends.map((i, index) => (
        <div
          key={`page-${index + prepends.length + pageItems.length}`}
          className={classNames(styles.fixedPage, styles.extraPage)}
          style={{
            width: `${readerContext?.width}px`,
            height: `${readerContext?.height}px`,
          }}
        >
          {i}
        </div>
      )),
    ]
    return pageList
  }, [
    pages,
    prependPageCount,
    readerContext?.currentChildren?.append,
    readerContext?.currentChildren?.prepend,
    readerContext?.height,
    readerContext?.lineHeight,
    readerContext?.paragraphs,
    readerContext?.width,
  ])

  const pageJSX = useMemo(() => {
    const fixedRawPage = prependPageCount + currentRawPage
    const centerPage = Math.ceil(fixedRawPage)
    const prevPage = centerPage - 1

    let progress = fixedRawPage - prevPage
    if (progress === 0) {
      progress = 1
    }

    const initialAngle = ((Math.PI * 2) / 360) * 15
    const targetAngle = 0
    const currentAngle = targetAngle * progress + initialAngle * (1 - progress)
    const currentCenter = pos((readerContext?.width ?? 0) * (1 - progress), readerContext?.height ?? 0)
    const effect = createEffectRight(
      readerContext?.width ?? 0,
      readerContext?.height ?? 0,
      currentCenter[0],
      currentCenter[1],
      currentAngle,
      30
    )

    // const effect = creat

    const transparentPage = (
      <div
        style={{
          width: `${readerContext?.width}px`,
          height: `${readerContext?.height}px`,
        }}
      ></div>
    )

    const emptyPage = (
      <div
        style={{
          width: `${readerContext?.width}px`,
          height: `${readerContext?.height}px`,
          position: 'relative',
        }}
      >
        <div className={styles.fixedPage}></div>
      </div>
    )

    const effectPage = (
      <div
        style={{
          width: `${readerContext?.width}px`,
          height: `${readerContext?.height}px`,
          position: 'relative',
        }}
      >
        <div className={styles.pageEffect}></div>
      </div>
    )

    if (prevPage < 0) {
      return [<div key={'page-center'}>{pageList[centerPage]}</div>]
    } else {
      return (
        <>
          {[
            <div key={centerPage} className={styles.itemMask}>
              <div
                className={styles.itemWrap}
                style={{
                  width: `${readerContext?.width}px`,
                  height: `${readerContext?.height}px`,
                }}
              >
                {pageList[centerPage]}
              </div>
            </div>,
            <div key={prevPage} className={styles.itemMask}>
              <div
                className={styles.itemWrap}
                style={{
                  transform: 'translateZ(0px)',
                  clipPath: effect.clipPathRemain,
                  width: `${readerContext?.width}px`,
                  height: `${readerContext?.height}px`,
                }}
              >
                {pageList[prevPage]}
              </div>
            </div>,
            <div key="page-shadow" className={styles.itemMask}>
              <div
                className={styles.itemWrap}
                style={{
                  willChange: 'transform',
                  contain: 'strict',
                  clipPath: effect.clipPathFlipShadow,
                  transform: effect.transformFlip,
                  boxShadow: effect.boxShadow,
                  width: `${readerContext?.width}px`,
                  height: `${readerContext?.height}px`,
                }}
              >
                {transparentPage}
              </div>
            </div>,
            <div key="page-empty" className={styles.itemMask}>
              <div
                className={styles.itemWrap}
                style={{
                  willChange: 'transform',
                  contain: 'strict',
                  clipPath: effect.clipPathFlip,
                  transform: effect.transformFlip + ' translateZ(0px)',
                  width: `${readerContext?.width}px`,
                  height: `${readerContext?.height}px`,
                }}
              >
                {emptyPage}
              </div>
            </div>,
            <div key="page-effect" className={styles.itemMask}>
              <div
                className={styles.itemWrap}
                style={{
                  willChange: 'transform',
                  contain: 'strict',
                  clipPath: effect.clipPathEffect,
                  transform: effect.transformEffect,
                  width: `${readerContext?.width}px`,
                  height: `${readerContext?.height}px`,
                }}
              >
                {effectPage}
              </div>
            </div>,
          ]}
        </>
      )
    }
  }, [currentRawPage, pageList, prependPageCount, readerContext?.height, readerContext?.width])

  const onScroll: UIEventHandler<HTMLDivElement> = (ev) => {
    if (readerContext?.state !== 'wait') {
      return
    }
    onScrollProp()
    userInteractionSinceLastRead.current = true
    const el = ev.currentTarget
    const newCurrentPage = Math.round(el.scrollLeft / readerSize.width) - prependPageCount
    debugLog('on user scroll', currentPage, newCurrentPage, el.scrollLeft)
    // debugLog(el.scrollLeft, newCurrentPage, Date.now())
    setCurrentRawPage(el.scrollLeft / readerSize.width - prependPageCount)

    if (newCurrentPage >= 0 && newCurrentPage < (pages?.length ?? 0)) {
      if (currentPage !== newCurrentPage) {
        setCurrentPage(newCurrentPage)
        emitCurrentPage(newCurrentPage)
        emitExtraPage(newCurrentPage)
      }
    } else if (newCurrentPage < 0) {
      // delay the change point when in extra page
      const newCurrentPage =
        Math.round((el.scrollLeft + readerSize.width / 2 - 1) / readerSize.width) - prependPageCount
      if (currentPage !== newCurrentPage) {
        setCurrentPage(newCurrentPage)
        emitCurrentPage(newCurrentPage)
        emitExtraPage(newCurrentPage)
      }
    } else {
      // delay the change point when in extra page
      const newCurrentPage = Math.round((el.scrollLeft - readerSize.width / 2) / readerSize.width) - prependPageCount
      if (currentPage !== newCurrentPage) {
        setCurrentPage(newCurrentPage)
        emitCurrentPage(newCurrentPage)
        emitExtraPage(newCurrentPage)
      }
    }
  }

  return (
    <div ref={readerRef} className={classNames(`${styles.reader}`, className)} style={{ ...(style ?? {}), font }}>
      <div className={styles.readerInner} style={{ width: `${readerSize.width}px`, height: `${readerSize.height}px` }}>
        {rulerJSX}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
            // overflow: 'hidden',
          }}
        >
          {pageJSX}
        </div>
        <div ref={scrollerRef} className={styles.pages} onScroll={onScroll}>
          {fillerJSX}
          {/* {pageJSX} */}
        </div>
      </div>
    </div>
  )
})
