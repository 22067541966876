import { memo } from 'react'
import { StyledButtonSearchMore, TitleDecoration, TitleExtra, TitleSubText, TitleText, Wrapper } from './Styled'

function AreaTitle({
  text = '',
  subText = '',
  hasDecoration = false,
  hasMore = false,
  onMore = () => {},
  children = <></>,
  ...props
}) {
  if (!hasDecoration && !hasMore) return null

  // wrapper has extra left padding when it have decoration
  return (
    <Wrapper {...props} hasDecoration={hasDecoration}>
      {hasDecoration && <TitleDecoration />}
      <TitleText>{text}</TitleText>
      <TitleSubText>{subText}</TitleSubText>
      {hasMore && <StyledButtonSearchMore handleClick={onMore} />}
      <TitleExtra>{children}</TitleExtra>
    </Wrapper>
  )
}

export default memo(AreaTitle, (prevProps, nextProps) => {
  return prevProps.hasDecoration === nextProps.hasDecoration && prevProps.hasMore === nextProps.hasMore
})
