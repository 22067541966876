import PageTabs from 'component/PageTabs'
import Short from './components/Short'
import Video from './components/Video'
import Comic from './components/Comic'
import Novel from './components/Novel'
import { useContentTab } from 'hooks/useContentTab'

export default function BrowseHistory({ ...props }) {
  const { tabs, tabContents } = useContentTab({
    video: Video,
    short: Short,
    comic: Comic,
    novel: Novel,
  })
  return (
    <PageTabs {...props} title="浏览纪录" tabs={tabs}>
      {tabContents}
    </PageTabs>
  )
}
