const getState = () => ({
  currentTransitionSpeed: 0,
})

/**
 *
 * @param {import('swiper').Swiper} swiper
 * @param {*} progress
 * @returns
 */
const progress = (swiper, progress) => {
  // console.log('progress', progress)
  // console.log(progress)
  /* 
  if you need to change something for each progress
  do it here (progress variable is always in range from 0 to 1) representing progress of the whole slider 
  */
  // convert slides object to plain array
  const slides = [...swiper.slides]
  if (slides.length === 0) {
    return
  }

  // get information about animation progress from the active slide - the active slide's value is always -1 to 1.
  /* 
  every slide has a progress attribute equal to the "distance" from the current active index.
  */
  // do magic with each slide

  // Start(disappear point) => Middle => End

  const centerOffset = `translateX(${swiper.width / 2}px) translateX(-50%)`
  const swiperWidth = swiper.isHorizontal() ? swiper.width : swiper.height
  const slideCount = slides.length
  const slideWidth = swiperWidth / swiper.params.slidesPerView
  const xLeftEnd = (swiperWidth - slideWidth) / 2
  const xLeftStart = xLeftEnd / 2
  const xLeftMiddle = (xLeftEnd + xLeftStart) / 2
  const xCenterStart = xLeftStart
  const xCenterMiddle = xLeftMiddle + slideWidth / 2 / 2
  const xCenterEnd = swiperWidth / 2

  // console.log('set', xCenterStart, xCenterMiddle, xCenterEnd)

  const getXOffset = (ratio) => {
    const loggedMiddle = Math.log(xCenterMiddle - xCenterStart)
    const loggedEnd = Math.log(xCenterEnd - xCenterStart)
    const mapped = loggedMiddle * ratio + loggedEnd * (1 - ratio)
    return Math.pow(Math.E, mapped) + xCenterStart - xCenterEnd
  }

  const MAX_VISIBLE = 5
  const cappedCount = Math.min(slideCount, MAX_VISIBLE)

  const scalingFactor = Math.pow(0.5, -1 / cappedCount) - 1

  // slide progress order in 3 2 1 0 -1 ...
  slides.forEach((slide, index) => {
    // console.log(slide.swiperSlideOffset, slide.progress)
    // to put the slides behind each other we have to set their CSS translate accordingly since by default they are arranged in line.
    const offset = slide.swiperSlideOffset
    if (isNaN(offset) || isNaN(slide.progress)) {
      return
    }
    let x = -offset
    if (!swiper.params.virtualTranslate) x -= swiper.translate
    let y = 0
    if (!swiper.isHorizontal()) {
      y = x
      x = 0
    }

    slide.x = x
    slide.y = y

    const correctedProgress = slide.progress < -1 ? slide.progress + slideCount : slide.progress

    const ZOOM_FACTOR = scalingFactor

    const opacity =
      correctedProgress >= 0
        ? Math.min(Math.max(1 - (Math.abs(correctedProgress) - 1) / (cappedCount - 2), 0), 1)
        : 1 + correctedProgress

    const scale = 1 * Math.pow(1 + ZOOM_FACTOR, -correctedProgress)

    const xOffset =
      correctedProgress < 0
        ? correctedProgress * -1 * slideWidth
        : getXOffset(Math.abs(correctedProgress) / (cappedCount - 1))

    // console.log(slide.swiperSlideOffset, slide.progress, correctedProgress, scale)
    // you can do your CSS animation instead of using tweening.
    slide.scale = scale - ZOOM_FACTOR
    slide.opacity = opacity
    const transform = `${centerOffset} translate(${slide.x}px, ${slide.y}px) translateX(${xOffset}px)  scale(${slide.scale})`
    slide.style.transform = transform
    slide.style.zIndex = -Math.round(correctedProgress)
    slide.style.opacity = String(slide.opacity)
    slide.style.pointerEvents = Math.round(correctedProgress) < 0 ? 'none' : 'auto'

    slide.dataset['progress'] = String(correctedProgress)

    if (correctedProgress > 0.5) {
      slide.setAttribute('data-slide-stack-inactive', '')
    } else {
      slide.removeAttribute('data-slide-stack-inactive')
    }
  })
}
const setTransition = (state, swiper, transitionSpeed) => {
  // console.log('transition', transitionSpeed)
  state.currentTransitionSpeed = transitionSpeed
  // console.log("transition", transitionSpeed);
  const slides = [...swiper.slides]
  if (slides.length === 0) {
    return
  }
  slides.forEach((slide) => {
    if (transitionSpeed > 0) {
      slide.style.transition = `transform ${transitionSpeed / 1000}s`
    } else {
      slide.style.transition = `none ${transitionSpeed / 1000}s`
    }
  })
}

const setTranslate = (state, swiper, wrapperTranslate) => {
  // console.log('translate', wrapperTranslate)
}

export default function EffectStack({ swiper, extendParams, on }) {
  extendParams({
    speed: 1000,
  })

  on('beforeInit', () => {
    if (swiper.params.effect !== 'stack') return
    const overwriteParamsResult = {
      virtualTranslate: true,
      watchSlidesProgress: true,
      slidesPerView: 1.3,
      centeredSlides: true,
    }
    Object.assign(swiper.params, overwriteParamsResult)
    Object.assign(swiper.originalParams, overwriteParamsResult)
  })

  on('progress', (swiper, curProgress) => {
    if (swiper.params.effect !== 'stack') return
    progress(swiper, curProgress)
  })

  on('setTransition', (swiper, transition) => {
    if (swiper.params.effect !== 'stack') return
    const state = swiper.__state_stack || (swiper.__state_stack = getState())
    setTransition(state, swiper, transition)
  })

  on('setTranslate', (swiper, translate) => {
    if (swiper.params.effect !== 'stack') return
    const state = swiper.__state_stack || (swiper.__state_stack = getState())
    setTranslate(state, swiper, translate)
  })
}
