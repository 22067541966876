import AesImage from 'component/AesImage'
import AnimatedIcon from 'component/AnimatedIcon'
import AreaTitle from 'component/AreaTitle'
import ExpandableMultiLineText from 'component/ExpandableMultiLineText'
import styled, { css } from 'styled-components'

export const StatusBar = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: calc(0.75rem + env(safe-area-inset-top, 0px));
  color: var(--page-view-comic--statusbar-color);
`

export const StatusBarContent = styled.div`
  background: var(--page-view-comic--statusbar-background);
  padding-top: env(safe-area-inset-top, 0px);
  height: calc(0.75rem + env(safe-area-inset-top, 0px));
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.4rem;
  transition: all 0.3s;
`

export const IconBack = styled.button`
  position: absolute;
  top: calc(50% + calc(env(safe-area-inset-top, 0px) / 2));
  left: 0;
  width: 1rem;
  height: 1rem;
  transform: translateY(-50%);
  background-image: url(${process.env.PUBLIC_URL}/icon/arrow-white.svg);
  background-size: 28% 46%;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
`

export const Wrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  background: var(--main-background);
`

/**
 * The real scroll area
 */
export const WrapperInner = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
`

export const TopCover = styled.div`
  min-height: 8.87rem;
  position: relative;
  background-position: 50% 0;
  background-size: cover;

  display: flex;
  flex-direction: column;
`

export const TopCoverFiller = styled.div`
  min-height: 4.51rem;
  flex: 1 0 auto;
`

export const TitleCard = styled.div`
  position: relative;
  margin: 0 0.2rem;
  padding: 0.4rem 0;
  border-radius: 0.12rem;
  background: var(--page-view-comic--card-background);
`
export const Title = styled.h1`
  font-size: 0.4rem;
  color: var(--page-view-comic--title-color);
  position: relative;
  padding: 0 0.4rem;
  line-height: 130%;
  font-weight: 700;
`

export const TitleObserveArea = styled.div`
  position: absolute;
  pointer-events: none;
  top: calc(env(safe-area-inset-top, 0px) * -1 - 0.5rem);
  left: 0;
  right: 0;
  bottom: 0;
  /** Test area */
  /* background: rgba(255, 0, 0, 0.3); */
`

export const Desc = styled(ExpandableMultiLineText).attrs({
  lineCount: 4,
  backgroundColor: 'var(--page-view-comic--card-background)',
})`
  margin-top: 0.2rem;
  color: var(--page-view-comic--desc-color);
  font-size: 0.35rem;
  line-height: 0.5rem;
  padding: 0 0.4rem;
`
export const TagArea = styled.div`
  margin-top: 0.2rem;
  display: flex;
  gap: 0.1rem;
  padding: 0 0.4rem;
  overflow-x: auto;
`
export const Tag = styled.button`
  background: var(--page-view-comic--tag-background);
  color: var(--page-view-comic--tag-color);
  font-size: 0.3rem;
  padding: 0.1rem 0.41rem;
  border-radius: 2.5rem;
  white-space: nowrap;
`
export const Status = styled(AreaTitle)`
  margin: 0.4rem 0;
`
export const ChapterList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: var(--cms-grid-row-gap) var(--cms-grid-column-gap);
  margin: var(--cms-grid-row-gap) 0 0.8rem;
  padding: 0 var(--cms-grid-column-gap);
  padding-bottom: 0.1rem;
  overflow-x: auto;
  height: 0.9rem;
`
export const ChapterItem = styled.button`
  flex-shrink: 0;
  width: 2.74rem;
  font-size: 0.35rem;
  border-radius: 0.12rem;
  background: var(--page-view-comic--chapter-background);
  color: var(--page-view-comic--chapter-color);
  border: var(--page-view-comic--chapter-border, 1px solid transparent);
  padding: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.active
      ? css`
          background: var(--page-view-comic--chapter-active-background);
          border: var(--page-view-comic--chapter-active-border, 1px solid var(--page-view-comic--chapter-active-color));
          color: var(--page-view-comic--chapter-active-color);
        `
      : ''}
`
export const ChapterTrigger = styled.button`
  flex-shrink: 0;
  width: 0;
`
export const Footer = styled.div`
  flex: 0 0 auto;
  height: 1.75rem;
  padding: var(--cms-grid-row-gap, 0px) var(--cms-grid-column-gap, 0px) var(--cms-grid-row-gap, 0px) 0.6rem;
  height: calc(env(safe-area-inset-bottom) + 1.75rem);
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--cms-grid-column-gap, 0px));
  border-top: 1px solid var(--page-view-comic--footer-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StartReading = styled.button`
  width: 5.2rem;
  background: var(--page-view-comic--start-read-background);
  color: var(--page-view-comic--start-read-color);
  height: 0.9rem;
  border-radius: 2rem;
  font-size: 0.36rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.43rem;
`
export const NoStartReading = styled(StartReading)`
  background: var(--button-disabled-background);
  color: var(--button-disabled-color);
`
export const CollectionButton = styled.button`
  /* width: 5.2rem; */
  color: var(--page-view-comic--start-collection-color);
  min-height: 0.9rem;
  font-size: 0.36rem;
  display: flex;
  align-items: center;
  /* gap: 0.1rem; */
`

export const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 0.75rem;
  width: 0.75rem;
  margin: -0.03rem;
  margin-right: calc(-0.03rem + 5px);
`

export const StyledAesImage = styled(AesImage).attrs({ aspectRatio: null })`
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
`

export const CoverDecoration = styled.div`
  position: absolute;
  height: 4.61538rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--page-view-comic-novel-cover-decoration);
`
