import { PageScrollWrap } from 'commonStyledComponents'
import styled from 'styled-components'

export const StyledPageScrollWrap = styled(PageScrollWrap)`
  font-size: 0.48rem;
  font-weight: bold;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 10px 25px 50px 25px;
`

export const BtnSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.8rem;
  min-height: 0.923rem;
  margin-top: 8px;
  background: var(--page-login-register--submit-background);
  border-radius: 0.923rem;
  color: var(--page-login-register--submit-color);
  font-size: 0.3589rem;
  font-weight: 400;
`

export const BtnSwitchLogin = styled.button`
  color: #2e2e2e;
  position: relative;
  padding-left: 12px;

  &:before {
    position: absolute;
    left: 4.5px;
    content: ' | ';
    color: #2e2e2e;
    height: 100%;
    width: 1px;
  }
`

export const BottomText = styled.div`
  color: #aaaaaa;
  margin-top: 0.266rem;
  font-weight: bold;
  letter-spacing: 0.36px;
  font-size: 0.32rem;
`

export const IconRefresh = styled.div`
  display: inline-block;
  width: 0.4102rem;
  height: 0.4102rem;
  background: url(/icon/refresh-icon.png) no-repeat center;
  background-size: contain;
  margin-right: 2px;
`
