import styled, { css, keyframes } from 'styled-components'
import LazyLoad from 'react-lazyload'

const fadeIn = keyframes`
 from { opacity: 0; }
 to { opacity: 1; }
`

export const StyledLazyLoad = styled(LazyLoad)`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`

export const NonLazyLoadWrapper = styled.div`
  ${(props) =>
    props.aspectRatio == null
      ? ''
      : css`
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;
        `}
  ${(props) =>
    props.useExternalHeight
      ? css`
          height: 100%;
        `
      : ''}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`
const ImageDiv = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const Image = styled.img`
  min-height: 0;
  ${(props) =>
    props.aspectRatio == null
      ? css`
          min-width: 0;
          width: 100%;
        `
      : 'height: 0;'}
  flex-grow: 1;
  flex-shrink: 1;
  object-fit: cover;
  ${(props) =>
    props.animation ?? true
      ? css`
          animation: ${fadeIn} 0.4s ease-in;
        `
      : ''}
`

export const ImageWrapper = styled(ImageDiv)`
  ${(props) =>
    props.hasPlaceholder
      ? props.placeholder === null
        ? css`
            background-image: url(${process.env.PUBLIC_URL}/loading/load-image.svg);
          `
        : props.placeholder
        ? css`
            background-image: url(${process.env.PUBLIC_URL}/${props.placeholder});
          `
        : ''
      : css``}

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;

  /* backface-visibility: hidden; */
  /* transform: translate3d(0, 0, 0); */

  ${(props) =>
    props.aspectRatio != null
      ? css`
          padding-bottom: ${(1 / props.aspectRatio) * 100}%;
        `
      : ''}
`
