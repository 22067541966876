import { useEffect, useState, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
//
import { fetchVideoList, fetchVideoTotalQty } from 'api'
//
import Template5 from 'component/AreaBox/component/Template5'
//
import { Wrapper, Title } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { fetchRandomWithMultiPage } from 'utils/randomList'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import AreaWrapper from 'component/AreaWrapper'

export default function RelativeActorVideo({ videoInfo }) {
  const currentVideo = videoInfo.id
  const [relativeVideoList, setRelativeVideoList] = useState([])
  const actorIds = useMemo(() => {
    return videoInfo?.actor_ids
  }, [videoInfo])
  const { navigateToActor } = useNavigateTo()

  function handleOnMore() {
    const actor = actorIds[0]
    if (!actor) return
    navigateToActor({
      actorId: actor,
    })
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const params = {
      actor_ids: actorIds?.join(','),
      pageSize: 6,
      signal,
    }

    // 隨機結果
    fetchRandomWithMultiPage(fetchVideoTotalQty, fetchVideoList, params)
      .then((data) => setRelativeVideoList(data))
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [actorIds])

  const filteredVideos = relativeVideoList.filter((i) => i.id !== currentVideo)

  if (filteredVideos.length === 0) return null
  if (isEmpty(actorIds)) return null

  return (
    <Wrapper>
      <AreaWrapper>
        <Title hasDecoration text="她的其他作品" hasMore={true} onMore={handleOnMore} />
        <Template5 videoData={filteredVideos} />
      </AreaWrapper>
    </Wrapper>
  )
}
