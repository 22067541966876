import { useMemo, useState } from 'react'

import {
  Wrapper,
  ImageWrapper,
  BoxWrap,
  VideoItemWrap,
  VideoTitle,
  TagList,
  VideoDescription,
  TagItem,
  VideoInfoWrap,
} from './Styled'
import Base64Image from 'component/Base64Image'
import { useHasPayment } from 'hooks/useHasPayment'
import { usePaymentTypeImage } from 'component/PaymentTypeImages/hooks/usePaymentTypeImage'
import { useNavigateTo } from 'hooks/useNavigateTo'
import RankTag from 'component/VideoRankBox/RankTag'
import { SchemaVideoShow } from '../../../types/api'
import { report, useCurrentWorkSection } from 'hooks/useAnalytics'

interface VideoRankRowProp {
  videoInfo: SchemaVideoShow;
  index: number;
  width?: number;
  columns?: number;
  allowUnload?: boolean;
  noLazy?: boolean
}

/** 元件 - 影片 Item */
export default function VideoRankBox(
{
    index,
    videoInfo,
    width = 1,
    columns = 1,
    allowUnload = true,
    noLazy,
    ...props
  }:VideoRankRowProp
) {
  const hasPayment = useHasPayment()
  const { navigateToViewVideo } = useNavigateTo()
  const {
    coverphoto_h,
  } = videoInfo

  const imageJSX = usePaymentTypeImage(videoInfo)

  const [img, setImg] = useState()

  const workSection = useCurrentWorkSection()

  const onVideoBoxClick = () => {
    if (workSection) {
      report({
        ...workSection,
        Parameters: 'video_id',
        Value: videoInfo.id!,
      })
    }

    navigateToViewVideo({
      ...videoInfo,
      posterImg: img,
    })
  }

  const tags = useMemo(() => {
    const tagName = Array.isArray(videoInfo.tag_names) ? videoInfo.tag_names : []
    if (tagName.length <= 2) {
      return tagName
    }
    else{
      return [...tagName].sort((a, b)=> a ? a.length - b.length : 1).filter((m, i) => i < 2)
    }
  }, [videoInfo.tag_names])

  return (
    <Wrapper {...props} columns={columns} width={width} onClick={onVideoBoxClick}>
      <RankTag index={index} />
      <VideoItemWrap>
        <BoxWrap>
          <ImageWrapper>
            <Base64Image
              aspectRatio={16 / 9}
              src={coverphoto_h}
              onImageLoad={setImg}
              noLazy={noLazy}
              allowUnload={allowUnload}
              onBase64ImageLoad={undefined}
              overflow={undefined}
              imageStyle={undefined}
            />
            {hasPayment && imageJSX}
          </ImageWrapper>
        </BoxWrap>
        <VideoInfoWrap>
          <VideoTitle>{videoInfo.title}</VideoTitle>
          <TagList>
            {tags?.map((m, k) => (
              <TagItem key={k}>{m}</TagItem>
            ))}
          </TagList>
          <VideoDescription>{videoInfo.description}</VideoDescription>
        </VideoInfoWrap>
      </VideoItemWrap>
    </Wrapper>
  )
}
