import AesImage from 'component/AesImage'
import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

// 用於平時需要貼齊螢幕外圍又需要保持彈性的area box template
export const AreaBoxSwiperTemplateWrapper = styled.div`
  padding: var(--component-area-swiper-outside-padding);
  width: 100%;
  position: relative;
  > div {
    border-radius: var(--component-area-swiper-border-radius);
    overflow: hidden;
  }
`

export const MaskedBase64Background = styled(Base64Image).attrs({ aspectRatio: null })`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: var(--component-area-swiper--background-opacity);
`

export const MaskedAesBackground = styled(AesImage).attrs({ aspectRatio: null })`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: var(--component-area-swiper--background-opacity);
`
