import styled from 'styled-components'

export const IconBack = styled.button`
  position: absolute;
  top: 0.426rem;
  margin-top: env(safe-area-inset-top);
  left: 0.266rem;
  width: 1.333rem;
  height: 1.333rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/arrow-white.svg);
  background-size: 22% 36%;
  background-repeat: no-repeat;
  background-position: left top;
`

export const Wrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`
