export const UNDEFINED = '*'

export const INDEX = '/'

export const PROFILE = '/profile'

export const UNKNOWN = 'unknown'

export const APP_CENTER = '/app-center'

export const CATEGORY = '/category'

export const GAME = '/game'
