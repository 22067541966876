import { useCallback } from 'react'
import { useImmer } from 'use-immer'
//
import { fetchProducerInfoList } from 'api'
import HeaderBox from 'component/PageModalHeaderBox'
import EmptyResult from 'component/EmptyResult'

import ListItem from './component/ListItem'
import { ListBox, ItemWrapper } from './Styled'
import { HeaderWrap, InfiniteScrollCmsGridWrapper, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'

/** headerTitle 為傳參因為有用在金剛區, 如果金剛區有名稱設定的話, 則以金剛區的名稱為 Title */
export default function ProducerList() {
  const { navigateToProducer } = useNavigateTo()

  const { producerIds, headerTitle = '片商列表' } = useParamsOfPage('producers')

  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const onProducerItemClick = useCallback(
    (itemInfo) => {
      navigateToProducer({
        producerId: itemInfo?.id,
        // producerImage: itemInfo?.icon_path,
        // producerName: itemInfo?.name,
      })
    },
    [navigateToProducer]
  )

  const handleFetchProducerList = () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
      pageSize: 30,
      id: producerIds.join(','),
    }

    fetchProducerInfoList(data).then((response) => {
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    })
  }

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={headerTitle} />
      </HeaderWrap>
      <PageScrollWrap noPadding>
        {!state?.list?.length && !state.isLoading ? (
          <EmptyResult />
        ) : (
          <ListBox>
            <InfiniteScrollCmsGridWrapper
              pageStart={0}
              loadMore={handleFetchProducerList}
              hasMore={state?.hasMore}
              // loader={<div className="loader" key={0}>Loading ...</div>}
              useWindow={false}
            >
              {state?.list?.map((item, i) => {
                return (
                  <ItemWrapper key={i} onClick={() => onProducerItemClick(item)}>
                    <ListItem imageUrl={item?.icon_path} name={item?.name} />
                  </ItemWrapper>
                )
              })}
            </InfiniteScrollCmsGridWrapper>
          </ListBox>
        )}
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
