// 短視頻 豎版三個單行左滑
import VideoBox from 'component/VideoBoxVertical'
import { memo, useMemo } from 'react'

import { Wrapper } from './Styled'

function Template103({ videoData }) {
  const shortInfo = useMemo(
    () => ({
      list: videoData,
    }),
    [videoData]
  )

  return (
    // 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能
    <Wrapper className={'swiper-no-swiping'}>
      {videoData?.map((item, i) => {
        return <VideoBox overflow={true} key={i} videoInfo={item} columns={3.03} shortInfo={shortInfo} allowUnload />
      })}
    </Wrapper>
  )
}
export default memo(Template103)
