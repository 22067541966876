import {
  fetchVideoList,
  fetchVideoTotalQty,
  fetchShortList,
  fetchShortTotalQty,
  fetchComicList,
  fetchComicTotalQty,
  fetchNovelList,
  fetchNovelTotalQty,
} from 'api'
import { fetchRandomWithMultiPage } from 'utils/randomList'
import { VideoTypeEnum } from 'constant/common'
import { SORT_BY_RANDOM } from '../../../component/AreaBox/constant'

export function getContentApi(type) {
  switch (type) {
    case VideoTypeEnum.LongVideo:
      return [fetchVideoList, fetchVideoTotalQty]
    case VideoTypeEnum.ShortVideo:
      return [fetchShortList, fetchShortTotalQty]
    case VideoTypeEnum.Comic:
      return [fetchComicList, fetchComicTotalQty]
    case VideoTypeEnum.Novel:
      return [fetchNovelList, fetchNovelTotalQty]
    default:
      return [fetchVideoList, fetchVideoTotalQty]
  }
}

const fetchWithMultiPage = async (fetchApi, params) => {
  const originalSize = params.pageSize
  const pageSize = Math.min(originalSize, 100)

  const data = []
  const requests = []

  for (let i = 0; i < originalSize; i += pageSize) {
    requests.push(
      (async () => {
        const limit = Math.min(originalSize - i, pageSize)
        const partial = (
          await fetchApi({
            ...params,
            pageSize: pageSize,
            current: i / pageSize + 1,
          })
        ).data
        return partial.slice(0, limit)
      })()
    )
  }
  const results = await Promise.all(requests)
  for (const partial of results) {
    data.push(...partial)
  }

  return { data: data }
}

export async function loadData({ type, info, random, signal }) {
  const requestParams = getRequestParam(info)
  const data = {
    signal: signal,
    ...requestParams,
  }

  const [fetchListApi, fetchListTotalQtyApi] = getContentApi(type)

  if (!random) {
    const response = await fetchWithMultiPage(fetchListApi, data)
    return response?.data
  } else {
    /** 如果後端回傳 video_sortby 為 random, 則由前端實作 random 邏輯 */
    const randomData = await fetchRandomWithMultiPage(fetchListTotalQtyApi, fetchListApi, { ...data })
    return randomData
  }
}

export async function loadGroupData({ infoList, signal }) {
  const listData = {}
  const promiseList = []
  for (let i = 0; i < infoList.length; i++) {
    const info = infoList[i]
    const [fetchListApi] = getContentApi(info.type)
    // 排行榜第一層顯示最多100筆
    const data = {
      signal: signal,
      ...getRequestParam(info, 100),
    }
    const fetchPromise = fetchWithMultiPage(fetchListApi, data).then((response) => {
      listData[i] = response?.data
    })
    promiseList.push(fetchPromise)
  }
  return Promise.all(promiseList).then(() => {
    return listData
  })
}

export function getRequestParam(areaInfo, maxCount) {
  if (!areaInfo) {
    console.log('area is empty')
    return {}
  }
  const category_ids = areaInfo.video_category?.join(',')
  const actor_ids = areaInfo.video_actor?.join(',')
  const tag_ids = areaInfo.video_tag?.join(',')
  const video_regions = areaInfo.video_region_ids?.join(',')
  const video_producers = Array.isArray(areaInfo.video_producer_ids) ? areaInfo.video_producer_ids.join(',') : ''
  const size = maxCount > 0 ? (areaInfo.displayno <= maxCount ? areaInfo.displayno : maxCount) : areaInfo.displayno
  const { type, serial } = areaInfo

  if (!areaInfo) return
  return {
    pageSize: size,
    special_search: 1, //排行榜
    category_ids: category_ids,
    actor_ids: actor_ids,
    tag_ids: tag_ids,
    region_ids: video_regions,
    producer_ids: video_producers,
    sortby: areaInfo.video_sortby == SORT_BY_RANDOM ? 'updated_at' : areaInfo.video_sortby,
    payment_type: areaInfo.video_payment_type,
    ...(type === VideoTypeEnum.ShortVideo
      ? {
          uploader_id: areaInfo.video_uploader_id,
        }
      : {}),
    ...(type === VideoTypeEnum.Comic
      ? {
          serial: serial,
        }
      : {}),
    ...(type === VideoTypeEnum.Novel
      ? {
          serial: serial,
        }
      : {}),
  }
}
