import EmptyResult from 'component/EmptyResult'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectNovelCollectionData } from 'redux/selector/novelCollection'
import NovelBox from 'component/NovelBox'
import { AreaPanelCmsGridWrapper, InfiniteScrollWrapper, PageScrollWrap } from 'commonStyledComponents'
import { FETCH_NOVEL_COLLECTION, RESET_NOVEL_COLLECTION } from 'redux/constant/novelCollection'

const Novel = () => {
  const dispatch = useDispatch()
  const novelCollections = useSelector(selectNovelCollectionData)

  const runOnce = useRef(false)
  const handleFetchCollectionList = useCallback(() => {
    if (!runOnce.current) {
      runOnce.current = true
      dispatch({ type: RESET_NOVEL_COLLECTION })
    }

    dispatch({ type: FETCH_NOVEL_COLLECTION })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: RESET_NOVEL_COLLECTION })
    dispatch({ type: FETCH_NOVEL_COLLECTION })
  }, [dispatch])

  return !novelCollections?.list?.length && !novelCollections.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollWrapper
        pageStart={0}
        loadMore={handleFetchCollectionList}
        hasMore={novelCollections?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {novelCollections?.list?.length > 0 && (
          <AreaPanelCmsGridWrapper>
            {novelCollections?.list?.map((item, i) => (
              <NovelBox key={i} novelInfo={item} columns={3}>
                {i}
              </NovelBox>
            ))}
          </AreaPanelCmsGridWrapper>
        )}
      </InfiniteScrollWrapper>
    </PageScrollWrap>
  )
}

export default Novel
