import { fetchNovelCollectionList } from 'api'
import actions from 'redux/action'
import { FETCH_NOVEL_COLLECTION, NOVEL_COLLECTION, RESET_NOVEL_COLLECTION } from 'redux/constant/novelCollection'
import { createListSaga } from 'redux/utils'

const handleCollection = createListSaga(
  NOVEL_COLLECTION,
  FETCH_NOVEL_COLLECTION,
  RESET_NOVEL_COLLECTION,
  actions.patchNovelCollectionData,
  actions.clearNovelCollectionData,
  fetchNovelCollectionList
)

export default handleCollection
