/**
 * 廣告版位類型
 * 1. 入站廣告位(一開始進入的廣告圖片, 會有n個, 機率出現只會有一個)
 * 2. 頻道輪播廣告位(所有Nav/所有頻道共用)
 * 3. 視頻櫥窗下方廣告位
 * 4. 視頻櫥窗更多廣告位 (四個顯示一個廣告)
 * 5. 視頻櫥窗中廣告位
 * 6. 撥放詳情廣告位 (n個會輪播)
 * 7. 個人中心廣告位 (n個會輪播)
 * 8. 公告彈窗廣告位(公告圖片, n個)
 * 9. 應用中心輪播廣告位(n個會輪播)
 * 10. 應用中心圖標廣告位
 * 11. 發現廠商演員廣告位
 * 12. 應用中心官方推薦
 * 13. 應用中心熱門遊戲
 * 14. 短視頻廣告位
 * */
export const AdvertisementType = {
  Entrance: 1, // 入站廣告位 --> OK
  NavBanner: 2, // 頻道輪播廣告位 --> OK
  AreaBottom: 3, // 視頻櫥窗下方廣告位 --> OK
  ViewMore: 4, // 視頻櫥窗更多廣告位 (四個顯示一個廣告) --> OK
  AreaCentral: 5, // 視頻櫥窗中廣告位
  ViewVideoBanner: 6, // 撥放詳情廣告位 --> OK
  Profile: 7, // 個人中心廣告位 --> OK
  Announcement: 8, // 彈窗廣告公告位 --> OK
  AppCenterBanner: 9, // 應用中心輪播廣告位 --> OK
  AppCenterIcon: 10, // 應用中心圖標廣告位 --> OK
  Category: 11, // 發現廠商演員廣告位 --> OK
  OfficialRecommendation: 12, // 應用中心 - 官方推薦 --> OK
  PopularGames: 13, // 應用中心 - 熱門遊戲 --> OK
  ViewShort: 14, // 短视频广告位
}

export const LINK_TYPE_INTERNAL = 1
export const LINK_TYPE_EXTERNAL = 2

export const INTERNAL_TYPE_NAV = 1
export const INTERNAL_TYPE_GAME = 2
