import {
  fetchAddComicCollectList,
  fetchComicChapterKey,
  fetchComicChapters,
  fetchComicList,
  fetchComicViewChapter,
  fetchRemoveComicCollectList,
} from 'api'
import { DISABLED, ENABLED } from 'constant/common'
import { useAllComicChapters } from 'hooks/useAllComicChapters'
import { Chapters } from 'page/ViewComic/components/Chapters'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  Header,
  Footer,
  Wrapper,
  FooterButton,
  FooterButtonIconChapter,
  StyledAnimatedIcon,
  FooterButtonIconChapterPrev,
  FooterButtonIconChapterNext,
  HeaderClose,
  Overlay,
  StyledAesImage,
} from './Styled'
import saveOutline from './animated-icons/save_outline.json'
import Tutorial from './components/Tutorial'
import { useDispatch, useSelector } from 'react-redux'
import { RESET_COMIC_COLLECTION } from 'redux/constant/comicCollection'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import actions from 'redux/action'
import { selectComicHistory } from 'redux/selector/history'
import PageLoadingIcon from 'component/Loading/PageLoadingIcon'
import { combinedStorage } from 'utils/combinedStorage'
import { DynamicScroll } from 'component/DynamicScroll/DynamicScroll'
import { END_OF_STREAM } from 'component/DynamicScroll/DynamicScrollUtils'
import useAlertComicChapterChange from 'hooks/useAlertComicChapterChange'

const TUTORIAL_SKIP_VARIABLE = 'viewedComicTutorialAt'

const HISTORY_VERSION = 2

export default function ViewComicChapter() {
  const { alertChapterChange } = useAlertComicChapterChange()
  const dispatch = useDispatch()

  const { chapterInfo: initialChapterInfo, comicId } = useParamsOfPage('comic-chapter')
  const navigate = useAppNavigate()
  const { navigateToViewComicChapter } = useNavigateTo()

  const [state, setState] = useState(/** @type {'initial' | 'normal'} */ ('initial'))
  const currentState = useRef(state)

  useLayoutEffect(() => {
    currentState.current = state
  }, [state])

  const [currentChapterInfo, setCurrentChapterInfo] = useState(null)
  const chapterInfo = currentChapterInfo ? currentChapterInfo : initialChapterInfo

  const [comicInfo, setComicInfo] = useState(null)
  const [chapterListVisible, setChapterListVisible] = useState(false)

  const fullChapterList = useAllComicChapters(comicId)

  const [readerKey, setReaderKey] = useState(0)

  let chapterIndex = -1

  for (let i = 0; i < fullChapterList.length; i++) {
    if (fullChapterList[i].id === chapterInfo.id) {
      chapterIndex = i
      break
    }
  }

  const prevChapter = fullChapterList[chapterIndex - 1]
  const nextChapter = fullChapterList[chapterIndex + 1]

  const { favorite, last_read_chapter_id, latest_chapter_prefix_name } = comicInfo ?? {}

  useEffect(() => {
    const con = new AbortController()
    const sig = con.signal
    fetchComicList({
      signal: sig,
      id: comicId,
    }).then((res) => {
      if (res.data[0]) {
        setComicInfo({
          ...res.data[0],
          // force set current chapter even it is not loaded yet
          last_read_chapter_id: initialChapterInfo.id,
        })
      }
    })

    return () => {
      con.abort()
    }
  }, [comicId, initialChapterInfo.id])

  const comicHistory = useSelector(selectComicHistory)[comicId]
  const comicHistoryInitial = useRef(
    comicHistory && comicHistory.version === HISTORY_VERSION ? comicHistory : undefined
  )

  const targetChapter = comicHistoryInitial.current?.chapter ?? null
  const targetPage = comicHistoryInitial.current?.page ?? 0
  const targetOffset = comicHistoryInitial.current?.offset ?? 0

  useEffect(() => {
    if (chapterInfo.page_url == null) {
      const con = new AbortController()
      const sig = con.signal

      fetchComicChapters({ signal: sig, id: chapterInfo.id }).then((res) => {
        if (!sig.aborted) {
          setCurrentChapterInfo(res.data[0])
        }
      })

      return () => {
        con.abort()
      }
    }
  }, [chapterInfo.id, chapterInfo.page_url])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetchComicViewChapter({ signal, chapter_id: chapterInfo.id })
  }, [chapterInfo.id])

  const [keyState, setKeyState] = useState({
    chapter: null,
    key: null,
  })

  useEffect(() => {
    if (keyState.chapter === chapterInfo.id) return
    const controller = new AbortController()
    const signal = controller.signal

    fetchComicChapterKey({ signal, id: chapterInfo.id })
      .then((data) => {
        setKeyState({
          chapter: chapterInfo.id,
          key: data.key,
        })
      })
      .catch((err) => {
        if (!signal.aborted) {
          throw err
        }
      })

    return () => {
      controller.abort()
    }
  }, [chapterInfo.id, keyState.chapter])

  const onOpenChapter = (chapter) => {
    setChapterListVisible(false)
    setCurrentChapterInfo(chapter)
    navigateToViewComicChapter(
      {
        chapterInfo: chapter,
        comicId,
      },
      { replace: true }
    )

    setComicInfo((v) => {
      if (v != null) {
        return {
          ...v,
          last_read_chapter_id: chapter.id,
        }
      } else {
        return v
      }
    })

    setState('initial')
    setReaderKey((k) => k + 1)

    comicHistoryInitial.current = undefined
  }

  const scrollArea = useRef(/** @type {undefined|HTMLDivElement} */ (undefined))

  /**
   *
   * @param {React.SyntheticEvent<'img', MouseEvent>} ev
   */
  const onAreaClick = (ev) => {
    setUiHIdden((v) => !v)
  }
  const [uiHidden, setUiHIdden] = useState(false)

  const alerts = useAlertWorksActionResult()

  const setFavorite = async (value) => {
    if (value) {
      await fetchAddComicCollectList({ comic_id: comicId })
    } else {
      await fetchRemoveComicCollectList({ comic_id: comicId })
    }

    dispatch({ type: RESET_COMIC_COLLECTION })

    setComicInfo((v) => {
      if (v == null) return null
      return {
        ...v,
        favorite: v.favorite === ENABLED ? DISABLED : ENABLED,
      }
    })

    if (value) {
      alerts.alertSaved()
    } else {
      // alerts.alertUnsaved()
    }
  }

  const [skipTutorial] = useState(() => {
    return combinedStorage.getItem(TUTORIAL_SKIP_VARIABLE) === process.env.REACT_APP_VERSION
  })

  const setTutorialSkip = () => {
    combinedStorage.setItem(TUTORIAL_SKIP_VARIABLE, process.env.REACT_APP_VERSION)
  }

  /**
   * @typedef {import('component/DynamicScroll/DynamicScroll').DataBase & {
   *   fullChapterList: import('../../../types/api').SchemaComicChapterShow[],
   *   fullChapter: import('../../../types/api').SchemaComicChapterShow,
   *   pageIndex: number
   *   key: string
   * }} DataComic
   */

  /**
   * @type {import('component/DynamicScroll/DynamicScroll').LoadHandler<DataComic>}
   */
  const onLoadMore = useCallback(
    async (dir, factory, data) => {
      const loadedFullChapterList = await fullChapterList.promise
      if (dir === 'next') {
        if (data.length === 0) {
          const currentChapter = loadedFullChapterList.find((i) => i.id === chapterInfo.id)
          const { key } = await fetchComicChapterKey({ id: currentChapter.id })

          const targetPageIndex =
            initialChapterInfo.id === chapterInfo.id && targetChapter === currentChapter.id ? targetPage : 0
          const targetPageOffset =
            initialChapterInfo.id === chapterInfo.id && targetChapter === currentChapter.id ? targetOffset : 0
          const initialPageSize = targetPageOffset + 550

          const pages = currentChapter.page_url
          return [
            [
              <StyledAesImage
                ref={factory(0, 1).resizeRef}
                src={pages[targetPageIndex]}
                decryptKey={key}
                initialAspectRatio={window.innerWidth / Math.max(550, initialPageSize)}
                wrapperStyle={{
                  minHeight: `${targetPageOffset + 1}px`,
                }}
                noLazy
              />,
              {
                index: factory(0, 1).index,
                initialHeight: Math.max(550, initialPageSize),
                fullChapterList: loadedFullChapterList,
                fullChapter: currentChapter,
                pageIndex: targetPageIndex,
                key,
              },
            ],
          ]
        } else {
          const last = data[data.length - 1]
          const pageIndex = last.data.pageIndex
          if (pageIndex < last.data.fullChapter.page_url.length - 1) {
            return [
              [
                <StyledAesImage
                  ref={factory(0, 1).resizeRef}
                  src={last.data.fullChapter.page_url[pageIndex + 1]}
                  decryptKey={last.data.key}
                  initialAspectRatio={390 / 550}
                  noLazy
                />,
                {
                  index: factory(0, 1).index,
                  key: last.data.key,
                  initialHeight: 550,
                  fullChapterList: loadedFullChapterList,
                  fullChapter: last.data.fullChapter,
                  pageIndex: pageIndex + 1,
                },
              ],
            ]
          } else {
            // load next chapter
            const currentChapterIndex = last.data.fullChapterList.findIndex((i) => i.id === last.data.fullChapter.id)
            const currentChapter = last.data.fullChapterList[currentChapterIndex + 1]
            if (!currentChapter) {
              console.log('end')
              return END_OF_STREAM
            }

            const { key } = await fetchComicChapterKey({ id: currentChapter.id })
            const pages = currentChapter.page_url
            return [
              [
                <StyledAesImage
                  ref={factory(0, 1).resizeRef}
                  src={pages[0]}
                  decryptKey={key}
                  initialAspectRatio={390 / 550}
                  noLazy
                />,
                {
                  index: factory(0, 1).index,
                  initialHeight: 550,
                  fullChapterList: loadedFullChapterList,
                  fullChapter: currentChapter,
                  pageIndex: 0,
                  key,
                },
              ],
            ]
          }
        }
      } else if (dir === 'prev') {
        if (data.length === 0) {
          const nextOrCurrentChapter = loadedFullChapterList.find((i) => i.id === chapterInfo.id)
          const currentOrNextChapterIndex = loadedFullChapterList.findIndex((i) => i.id === nextOrCurrentChapter.id)
          const targetNextPageIndex = targetChapter === nextOrCurrentChapter.id ? targetPage : 0

          let currentChapter

          let targetPageIndex
          if (targetNextPageIndex !== 0) {
            currentChapter = nextOrCurrentChapter
            targetPageIndex = targetNextPageIndex - 1
          } else {
            const currentChapterIndex = currentOrNextChapterIndex - 1
            currentChapter = loadedFullChapterList[currentChapterIndex]
            if (currentChapter == null) {
              return END_OF_STREAM
            }
            targetPageIndex = currentChapter.page_url.length - 1
          }

          const { key } = await fetchComicChapterKey({ id: currentChapter.id })
          const pages = currentChapter.page_url
          return [
            [
              <StyledAesImage
                ref={factory(0, 1).resizeRef}
                src={pages[targetPageIndex]}
                decryptKey={key}
                initialAspectRatio={390 / 550}
                noLazy
              />,
              {
                index: factory(0, 1).index,
                initialHeight: 550,
                fullChapterList: loadedFullChapterList,
                fullChapter: currentChapter,
                pageIndex: targetPageIndex,
                key,
              },
            ],
          ]
        } else {
          const nextOrCurrent = data[0]
          const pageIndex = nextOrCurrent.data.pageIndex

          if (pageIndex > 0) {
            const currentChapter = nextOrCurrent.data.fullChapter

            const key = nextOrCurrent.data.key
            const pages = currentChapter.page_url
            const targetPage = pageIndex - 1

            return [
              [
                <StyledAesImage
                  ref={factory(0, 1).resizeRef}
                  src={pages[targetPage]}
                  decryptKey={key}
                  initialAspectRatio={390 / 550}
                  noLazy
                />,
                {
                  index: factory(0, 1).index,
                  initialHeight: 550,
                  fullChapterList: loadedFullChapterList,
                  fullChapter: currentChapter,
                  pageIndex: targetPage,
                  key,
                },
              ],
            ]
          } else {
            const nextChapter = nextOrCurrent
            const currentChapterIndex =
              loadedFullChapterList.findIndex((i) => i.id === nextChapter.data.fullChapter.id) - 1
            const currentChapter = loadedFullChapterList[currentChapterIndex]

            if (currentChapter == null) {
              return END_OF_STREAM
            }

            const { key } = await fetchComicChapterKey({ id: currentChapter.id })
            const pages = currentChapter.page_url
            return [
              [
                <StyledAesImage
                  ref={factory(0, 1).resizeRef}
                  src={pages[pages.length - 1]}
                  decryptKey={key}
                  initialAspectRatio={390 / 550}
                  noLazy
                />,
                {
                  index: factory(0, 1).index,
                  initialHeight: 550,
                  fullChapterList: loadedFullChapterList,
                  fullChapter: currentChapter,
                  pageIndex: pages.length - 1,
                  key,
                },
              ],
            ]
          }
        }
      }
    },
    [chapterInfo.id, fullChapterList.promise, initialChapterInfo.id, targetChapter, targetOffset, targetPage]
  )

  /**
   * @type {import('component/DynamicScroll/DynamicScroll').ProgressHandler<DataComic>}
   */
  const onProgress = useCallback(
    (compChapterInfo, _, offset) => {
      if (state === 'initial') {
        setState('normal')
      } else {
        if (compChapterInfo.data.fullChapter.id !== chapterInfo.id) {
          console.log(state, compChapterInfo.data.fullChapter.id, chapterInfo.id)
          alertChapterChange(compChapterInfo.data.fullChapter.prefix_name)
        }
      }

      dispatch(
        actions.updateComicHistory({
          id: comicId,
          progress: {
            version: HISTORY_VERSION,
            chapter: compChapterInfo?.data.fullChapter.id,
            page: compChapterInfo?.data.pageIndex,
            offset: offset,
          },
        })
      )

      if (compChapterInfo.data.fullChapter.id !== chapterInfo.id) {
        setCurrentChapterInfo(compChapterInfo.data.fullChapter)

        setComicInfo((old) => ({
          ...old,
          // force set current chapter even it is not loaded yet
          last_read_chapter_id: compChapterInfo.data.fullChapter.id,
        }))
      }
    },
    [alertChapterChange, chapterInfo.id, comicId, dispatch, state]
  )

  /**
   * @type {import('react').UIEventHandler<HTMLDivElement>}
   */
  const onScroll = useCallback((ev) => {
    setUiHIdden(true)
  }, [])

  return (
    <>
      <Wrapper
        ref={scrollArea}
        // onScroll={onAreaScroll}
        onClick={onAreaClick}
      >
        <DynamicScroll
          key={readerKey}
          onLoadMore={onLoadMore}
          onProgress={onProgress}
          initialOffset={targetChapter === initialChapterInfo.id ? targetOffset : 0}
          appendSpace={844}
          prependSpace={844}
          maxLiveViewport={6000}
          preloadRange={3000}
          onScroll={onScroll}
          style={{ height: '100%' }}
          onSelectAnchor={'touch'}
        ></DynamicScroll>
      </Wrapper>
      {state === 'initial' && (
        <Overlay>
          <PageLoadingIcon />
        </Overlay>
      )}
      <Header className={uiHidden ? 'hidden' : ''}>
        <HeaderClose
          onClick={() => {
            navigate(-1)
          }}
        ></HeaderClose>
        {chapterInfo.prefix_name + (chapterInfo.title ? ` - ${chapterInfo.title}` : '')}
      </Header>
      <Footer className={uiHidden ? 'hidden' : ''}>
        <FooterButton disabled={prevChapter == null} onClick={() => onOpenChapter(prevChapter)}>
          <FooterButtonIconChapterPrev />
          上一章
        </FooterButton>
        <FooterButton onClick={() => setChapterListVisible((v) => !v)}>
          <FooterButtonIconChapter />
          目录
        </FooterButton>
        <FooterButton onClick={() => setFavorite(favorite === DISABLED)}>
          {comicInfo != null && <StyledAnimatedIcon animationData={saveOutline} active={favorite === ENABLED} />}
          {favorite === ENABLED ? '已收藏' : '收藏'}
        </FooterButton>
        <FooterButton disabled={nextChapter == null} onClick={() => onOpenChapter(nextChapter)}>
          <FooterButtonIconChapterNext />
          下一章
        </FooterButton>
      </Footer>
      <Tutorial isOpen={!skipTutorial} onFinished={setTutorialSkip} />
      <Chapters
        title={`更新到${latest_chapter_prefix_name}`}
        comicId={comicId}
        isOpen={chapterListVisible}
        onClose={() => setChapterListVisible(false)}
        onOpenChapter={onOpenChapter}
        activeChapter={last_read_chapter_id}
        preloadedList={fullChapterList}
      />
    </>
  )
}
