/**
 * 隨機取得列表內容
 * @param {(params: T)=>Promise<any>} fetchListTotalQtyApi
 * @param {(params: T)=>Promise<any>} fetchApi
 * @param {T} params
 * @template {{ signal: AbortSignal }} T
 * @returns
 */
export const fetchRandomWithMultiPage = async (fetchListTotalQtyApi, fetchApi, params) => {
  const originalSize = params.pageSize
  const pageSize = Math.min(originalSize, 100)

  const pageCount = Math.ceil(params.pageSize / pageSize)
  const data = []
  const fullDataCount = await fetchListTotalQtyApi({ ...params }).then((response) => response?.page_result?.total)

  const remaining = originalSize % pageSize === 0 ? pageSize : originalSize % pageSize
  const exceeded = fullDataCount % pageSize === 0 ? pageSize : fullDataCount % pageSize

  // page size: 100
  // 110, 220 => 10 <= 20 => use last page, require = 2, full page = 3
  // 110, 200 => 10 <= 100 => use last page, require = 2, full page = 2
  // 110, 201 => 10 > 1 => ignore last page, require = 2, full page = 2
  // 100, 220 => 100 > 20 => ignore last page, require = 1, full page = 2

  const fullDataPageCount =
    exceeded >= remaining ? Math.ceil(fullDataCount / pageSize) : Math.floor(fullDataCount / pageSize)
  const maxStartPageIndex = Math.max(fullDataPageCount - pageCount, 0)
  const startPageIndex = Math.floor((maxStartPageIndex + 1) * Math.random())
  const startItemIndex = startPageIndex * pageSize

  const requests = []

  for (let i = startItemIndex; i < startItemIndex + originalSize; i += pageSize) {
    requests.push(
      (async () => {
        const limit = Math.min(startItemIndex + originalSize - i, pageSize)
        const partial = (
          await fetchApi({
            ...params,
            pageSize: pageSize,
            current: i / pageSize + 1,
          })
        ).data
        return partial.slice(0, limit)
      })()
    )
  }
  const results = await Promise.all(requests)
  for (const partial of results) {
    data.push(...partial)
  }

  return randomInPlace(data)
}

const randomInPlace = (arr) => {
  for (let i = 0; i < arr.length - 1; i++) {
    const toSwapWith = i + Math.floor((arr.length - i) * Math.random())
    if (toSwapWith !== i) {
      const a = arr[i]
      const b = arr[toSwapWith]
      arr[i] = b
      arr[toSwapWith] = a
    }
  }
  return arr
}
