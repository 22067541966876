import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal() {
  const { navigateToSearchViaCategory, navigateToSearchViaSpecific } = useNavigateTo()
  // const alert = useAlert();

  const openSearchVideoViaViaSpecificModal = (searchTypes) => {
    navigateToSearchViaSpecific({})
  }

  const openFilterModal = () => {
    navigateToSearchViaCategory({})
  }

  return {
    openSearchVideoViaViaSpecificModal,
    openFilterModal,
  }
}
