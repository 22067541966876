import PageTabs from 'component/PageTabs'
import Video from './components/Video'
import Short from './components/Short'
import Comic from './components/Comic'
import Novel from './components/Novel'
import { useContentTab } from 'hooks/useContentTab'
import { useMemo } from 'react'

export default function PurchaseHistory({ ...props }) {
  const { tabs, tabContents } = useContentTab(
    {
      video: Video,
      short: Short,
      comic: Comic,
      novel: Novel,
    },
    { filter: [1, 2] }
  )
  const filterTabs = useMemo(() => tabs.filter((m) => m.id === 'video' || m.id === 'short'), [tabs])
  return (
    <PageTabs {...props} title="我的购买" type="underline" tabs={filterTabs}>
      {tabContents}
    </PageTabs>
  )
}
