import EmptyResult from 'component/EmptyResult'
import NovelBox from 'component/NovelBox'
import { useImmer } from 'use-immer'
import { fetchNovelBuyList } from 'api'
import { InfiniteScrollCmsGridWrapper, PageScrollWrap } from 'commonStyledComponents'

const Novel = () => {
  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const handleFetchNovelList = () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
    }

    fetchNovelBuyList(data).then((response) => {
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    })
  }

  return !state?.list?.length && !state.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollCmsGridWrapper
        pageStart={0}
        loadMore={handleFetchNovelList}
        hasMore={state?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {state?.list?.map((item, i) => (
          <NovelBox key={i} NovelBox={item} columns={3}>
            {i}
          </NovelBox>
        ))}
      </InfiniteScrollCmsGridWrapper>
    </PageScrollWrap>
  )
}

export default Novel
