// 漫畫 一行三個
import { CmsGridWrapper } from 'commonStyledComponents'
import ComicBox from 'component/ComicBox'
import { memo } from 'react'

/**
 * 模板 ID: "202: 豎版一行三個
 * */
function Template202({ videoData, trigger, currentLimit }) {
  return (
    <CmsGridWrapper>
      {videoData?.slice(0, currentLimit).map((item, i) => {
        return <ComicBox overflow={true} columns={3} key={i} comicInfo={item} allowUnload />
      })}
      {trigger}
    </CmsGridWrapper>
  )
}

// this indicate areaBox should hide bottom section until fully shown
Template202.lazy = true
export default memo(Template202)
