import { Provider, useSelector } from 'react-redux'
import { Provider as AlertProvider } from 'react-alert'

import 'init.css'
import 'App.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-fade'

import store from 'redux/store'

import useHero from 'hooks/useHero'

import LaunchSplash from 'component/LaunchSplash'
import Announcement from 'component/Announcement'
import LaunchScreenAd from 'component/Advertisement/LaunchScreen'
import Alert, { ALERT_OPTIONS } from 'component/Alert'
import HintModal, { ReactHintModal } from 'component/Modal/HintModal'
import GlobalModal, { ReactGlobalModal } from 'component/Modal/GlobalModal'
import { AppOutlet } from 'app-layered-layout/AppProvider'
import { GamePopupProvider } from 'hooks/useOpenGamePopup'
import UpdateChecker from 'component/UpdateChecker'
import { report, useAnalyticsPV } from 'hooks/useAnalytics'
import MaintenancePage from 'component/MaintenancePage'
import {
  selectIsShowingFullPageError,
  selectIsShowingLaunchSplash,
  selectIsShowingMaintenancePage,
  selectIsViewedAdvertisement,
} from 'redux/selector/app'
import FullPageError from 'component/FullPageError'
import { useEffect, useRef } from 'react'
import { NAV_TYPE_NORMAL } from 'constant/common'
import { useNavigationLocalData } from 'component/Navigation/hooks/useNavigationLocalData'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useLocation } from 'react-router-dom'
import { INDEX } from 'constant/routers'

if (process.env.REACT_APP_SITE_CODE === '') {
  require('./App.shy.css')
}
if (process.env.REACT_APP_SITE_CODE === 'spring') {
  require('./App.spring.css')
}
if (process.env.REACT_APP_SITE_CODE === 'fx') {
  require('./App.fx.css')
}
if (process.env.REACT_APP_SITE_CODE === 'seman') {
  require('./App.seman.css')
}
if (process.env.REACT_APP_SITE_CODE === 'lls') {
  require('./App.lls.css')
}
if (process.env.REACT_APP_SITE_CODE === 'quick') {
  require('./App.quick.css')
}
if (process.env.REACT_APP_SITE_CODE === 'djr88') {
  require('./App.djr88.css')
}
if (process.env.REACT_APP_SITE_CODE === 'lsav') {
  require('./App.lsav.css')
}
if (process.env.REACT_APP_SITE_CODE === 'xlav') {
  require('./App.xlav.css')
}

ReactHintModal.setAppElement('#root')
ReactGlobalModal.setAppElement('#root')

function App() {
  // eslint-disable-next-line
  const heroInfo = useHero()
  const navigate = useAppNavigate()
  useAnalyticsPV()

  const showLaunchSplash = useSelector(selectIsShowingLaunchSplash)
  const showMaintenance = useSelector(selectIsShowingMaintenancePage)
  const showFullPageError = useSelector(selectIsShowingFullPageError)

  const cmsTabs = useNavigationLocalData()

  const firstCmsType = cmsTabs.navContent[0]?.type
  const firstCmsPath = cmsTabs.navContent[0]?.navLink
  const firstCmsSearch = cmsTabs.navContent[0]?.search

  const currentLocation = useLocation()
  const initialLocation = useRef(currentLocation)

  useEffect(() => {
    if (initialLocation.current.pathname === INDEX && firstCmsType != null && firstCmsType !== NAV_TYPE_NORMAL) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('home page is not a cms tab, performing navigate')
      }
      navigate(
        {
          pathname: firstCmsPath,
          search: firstCmsSearch,
        },
        { replace: true }
      )
    }
  }, [firstCmsPath, firstCmsSearch, firstCmsType, navigate])

  const isViewedAdvertisement = useSelector(selectIsViewedAdvertisement)
  const logged = useRef(false)
  useEffect(() => {
    if (isViewedAdvertisement) {
      if (!logged.current) {
        logged.current = true
        report({
          SerialNumber: 1,
          Event: 'app_screen',
          Trigger: '進入首頁',
          Parameters: 'page_title',
          Value: 'cms',
        })
      }
    }
  })

  return (
    <Provider store={store}>
      <GamePopupProvider>
        <AlertProvider template={Alert} {...ALERT_OPTIONS}>
          <div id="app">
            {/* App 內各頁面 */}
            <AppOutlet />

            {/* 公告頁面(分為系統公告 和 彈窗廣告公告) */}
            <Announcement />

            {/* App 入站廣告頁面 */}
            <LaunchScreenAd />

            {/* App 入站讀取資料時的顯示畫面 */}
            {showLaunchSplash && <LaunchSplash />}

            {showMaintenance && <MaintenancePage />}

            {showFullPageError && <FullPageError />}

            {/* App Hint模式 Modal */}
            <HintModal />

            {/* App 全域模式 Modal */}
            <GlobalModal />

            {/* 更新檢查 */}
            <UpdateChecker />
          </div>
        </AlertProvider>
      </GamePopupProvider>
    </Provider>
  )
}

export default App
