import { AreaLoadingProvider } from 'component/AreaBox/hooks/useAreaBoxLoadingContext'
import { StyledAreaBox, StyledGroupedAreaBox } from 'page/CmsView/Styled'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LoadingPlaceholder, LoadingWrapper } from './Style'
import { loadData, loadGroupData } from 'page/CmsView/hooks/contentListApi'
import { SORT_BY_RANDOM } from 'component/AreaBox/constant'
import { Virtuoso } from 'react-virtuoso'
export const AreaInfo = memo(({ info, displayAreaBox, shortcuts, lazy, onScroll }) => {
  const videoDataListMap = useRef({})
  const loadingAreaListMap = useRef({})
  const firstLoadingList = useRef([])
  const [videoDataList, setVideoDataList] = useState([])
  const rankIndex = useRef(0)
  const firstShow = useRef(true)
  const pageLoaded = useRef(false)
  const areaInfoList = useMemo(() => {
    return info.areasInfo
  }, [info.areasInfo])

  // 清除載入狀態，頁面在第一次全部清除時，會隱藏載入動畫
  const clearLoading = useCallback(
    (index) => {
      firstLoadingList.current.splice(
        firstLoadingList.current.findIndex((v) => v === index),
        1
      )
      loadingAreaListMap.current[index] = false
      if (!firstLoadingList.current.length && !pageLoaded.current) {
        pageLoaded.current = true
        info.onLoad()
      }
    },
    [info]
  )

  const mapToList = (map) => {
    const list = []
    for (let i = 0; i < Object.keys(map).length; i++) {
      const item = map[i]
      if (!item) break
      list.push(item)
    }
    return list
  }

  const loadArea = useCallback(
    (index, signal) => {
      const areaInfo = areaInfoList[index]
      if (!videoDataListMap.current[index]) {
        if (!loadingAreaListMap.current[index]) {
          loadingAreaListMap.current[index] = true
          if (areaInfo.grouped) {
            // 排行榜
            firstLoadingList.current.push(index)
            loadGroupData({ infoList: areaInfo.list, signal }).then(
              (data) => {
                clearLoading(index)
                videoDataListMap.current = { ...videoDataListMap.current, [index]: data }
                setVideoDataList(mapToList(videoDataListMap.current))
              },
              () => {
                clearLoading(index)
              }
            )
          } else {
            firstLoadingList.current.push(index)
            loadData({
              type: areaInfo.area.type,
              info: areaInfo.area,
              random: areaInfo.area.video_sortby === SORT_BY_RANDOM,
              signal,
            }).then(
              (data) => {
                clearLoading(index)
                videoDataListMap.current = { ...videoDataListMap.current, [index]: data }
                setVideoDataList(mapToList(videoDataListMap.current))
              },
              () => {
                clearLoading(index)
              }
            )
          }
        }
      }
    },
    [clearLoading, areaInfoList]
  )
  const Row = useCallback(
    (index) => {
      // 金剛區會使用第一筆輸出
      const header = index === 0 ? shortcuts : null

      const areaInfo = areaInfoList[index]

      // 如果沒有則不輸出
      if (!areaInfo) return null

      // loadArea(index)

      let area = null

      if (areaInfo.grouped) {
        // 排行榜
        area = (
          <div>
            {videoDataList[index] ? (
              <StyledGroupedAreaBox
                key={`Slide_Rank`}
                infoList={areaInfo.list}
                lazy={lazy}
                areaIndex={index}
                listData={videoDataList[index]}
                tabIndex={rankIndex.current}
                onTabClick={(index) => {
                  rankIndex.current = index
                }}
              />
            ) : undefined}
          </div>
        )
      } else {
        // 一般區域
        const onReload = () => {
          loadData({ type: areaInfo.area.type, info: areaInfo.area, random: true }).then((data) => {
            videoDataListMap.current = { ...videoDataListMap.current, [index]: data }
            setVideoDataList(mapToList(videoDataListMap.current))
          })
        }
        area = (
          <AreaWrap
            key={`Slide_${index}`}
            info={areaInfo.area}
            // only the last area box need to enable infinite loading
            lazy={lazy}
            index={index}
            videoData={videoDataList[index]}
            onReload={onReload}
          />
        )
      }

      if (header) {
        // todo: header無法直接放到virtuoso的Header內
        return (
          <div>
            {header}
            {area}
          </div>
        )
      } else {
        return area
      }
    },
    [areaInfoList, lazy, shortcuts, videoDataList]
  )

  // 觸發階段性載入
  const handleEndReached = useCallback(() => {
    let length = videoDataList.length
    if (length < areaInfoList.length) {
      // 載入新的內容
      length += 5
      if (length > areaInfoList.length) length = areaInfoList.length
      for (let i = videoDataList.length; i < length; i++) {
        loadArea(i)
      }
    }
  }, [areaInfoList.length, loadArea, videoDataList.length])

  // 初次運行
  useEffect(() => {
    if (!displayAreaBox) return
    const controller = new AbortController()
    if (firstShow.current) {
      handleEndReached()
      firstShow.current = false
    }

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAreaBox])

  return (
    <AreaLoadingProvider
      key={String(info.key)}
      // onLoaded={info.onLoad}
    >
      <LoadingWrapper className={info.state === 'loading' ? 'loading' : ''}>
        {/* maintain scroll height so ios don't break scroll area */}
        {info.state === 'initial-loading' && <div style={{ height: '100vh' }} />}
        {videoDataList.length === 0 && shortcuts}
        <Virtuoso
          data={videoDataList}
          itemContent={(index, item) => Row(index)}
          endReached={handleEndReached}
          increaseViewportBy={500}
          onScroll={onScroll}
        ></Virtuoso>

        {/** 與 Current NavInfo.TabInfo.AreaInfo 有關 */}
      </LoadingWrapper>
    </AreaLoadingProvider>
  )
})

export const AreaWrap = ({ index, info, lazy, videoData, onReload }) => {
  if (!Array.isArray(videoData)) {
    return <LoadingPlaceholder></LoadingPlaceholder>
  }
  return (
    <StyledAreaBox
      key={`Slide_${index}`}
      info={info}
      // only the last area box need to enable infinite loading
      lazy={lazy}
      areaIndex={index}
      videoData={videoData}
      onReload={onReload}
    />
  )
}
