import styled, { css } from 'styled-components'
import { PageWrapper } from 'commonStyledComponents'
import Tabs from 'component/Tabs'
import { TabsType } from 'component/Tabs/Styled'
import AreaBox from 'component/AreaBox'
import { FloatPromotionContainer } from 'component/FloatPromotion/Styled'
import GroupedAreaBox from 'component/GroupedAreaBox'

export const Wrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const TabsContentWrapper = styled.div`
  height: 0;
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  ${FloatPromotionContainer} {
    z-index: 1;
  }

  & > * {
    flex: 1 1 auto;
    min-width: 0;
  }
`

export const TabContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* will-change: scroll-position; */
  overflow: auto;

  &.not-loaded {
    opacity: 0;
  }
  &.not-loaded > * {
    pointer-events: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

export const TabsTitleWrapper = styled(Tabs).attrs({
  type: TabsType.Underline,
})`
  flex: 0 0 auto;
  --component-tabs--color: var(--page-header-color);
`

export const Tab = styled.div`
  position: relative;
  text-align: center;
  padding: 0 0.28rem 0.16rem 0.28rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &.active {
    font-size: 0.48rem;
    color: #db1653;

    &::after {
      position: absolute;
      content: ' ';
      width: calc(100% - 0.586rem);
      height: 0.053rem;
      background-color: #db1653;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
`

export const StyledAreaBox = styled(AreaBox)`
  margin: var(--cms-grid-row-gap) 0;
`

export const StyledGroupedAreaBox = styled(GroupedAreaBox)`
  margin: var(--cms-grid-row-gap) 0;
`
