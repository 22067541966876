const styles = {
  overlay: {
    zIndex: 99,
    backgroundColor: 'transparent',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    inset: 'unset',
    border: 'none',
    background: 'var(--app-background, var(--main-background))',
    backgroundSize: 'cover',
    padding: 0,
    borderRadius: 0,
    marginRight: 0,
    color: 'var(--main-color)',
    flexDirection: 'column',
  },
}

/**
 * @returns {{ styles: import('react-modal').Styles }}
 */
export function useModalStyles() {
  return {
    styles,
  }
}
