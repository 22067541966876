import VideoLargeBox from 'component/VideoLargeBox'

import { Wrapper } from './Styled'
import { memo } from 'react'

/**
 * 模板 ID: "4": 左右滑動(大圖)
 * */
function Template4({ videoData }) {
  return (
    // 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能
    <Wrapper className={'swiper-no-swiping'}>
      {videoData?.map((item, i) => {
        return <VideoLargeBox key={i} videoInfo={item} allowUnload />
      })}
    </Wrapper>
  )
}
export default memo(Template4)
