import styled from 'styled-components'
import Base64Image from 'component/Base64Image'
import ButtonSearchMore from 'component/ButtonSearchMore'

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.25rem;
  gap: 0.2rem;
  align-items: flex-start;
  height: 0.8rem;
`

export const TabButtonRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: stretch;
  gap: 0.2rem;
  width: 100%;
`

export const TabButton = styled.button`
  padding: 0 0.2rem;
  font-size: 0.35rem;
  line-height: 100%;
  height: 0.65rem;
  flex-shrink: 0;
  max-width: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid transparent;
  &.active {
    border: 1px solid var(--theme-color);
    border-radius: 0.5rem;
  }
`

//
export const Button = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.153rem;
  padding: 0.2051rem 0;
  font-weight: 400;
  font-size: 0.3589rem;
  border-radius: 0.564rem;
`

export const Wrapper = styled.div``

export const ContentWrapper = styled.div`
  display: flex;
  gap: var(--cms-grid-row-gap) var(--cms-grid-column-gap);
  padding: 0 var(--cms-grid-column-gap);
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  align-items: stretch;
  /* min-height: 2.8rem; */
  flex-direction: column;
`

export const AdImage = styled(Base64Image).attrs({
  placeholder: '/loading/square-loading.svg',
  source: 'picture',
  allowUnload: true,
})`
  border-radius: 0.2564rem;
`

export const FunctionalArea = styled.div`
  width: 100%;
  display: flex;
  gap: 0.2051rem;
`

export const BtnMono = styled(Button)`
  color: var(--component-area-box--button-mono-color);
  background: var(--component-area-box--button-mono-background);
`

export const BtnColorful = styled(Button)`
  color: var(--component-area-box--button-colorful-color);
  background: var(--component-area-box--button-colorful-background);
`

export const Bottom = styled.div`
  padding: 0 var(--cms-grid-column-gap);
  width: 100%;
  display: block;
`

export const BottomRow = styled.div`
  padding: var(--cms-grid-row-gap) var(--cms-grid-column-gap) 0 var(--cms-grid-column-gap);
  width: 100%;
  display: block;
`

export const TriggerElement = styled.div`
  height: 200vh;
  width: 100%;
`

export const StyledButtonSearchMore = styled(ButtonSearchMore)`
  color: var(--component-area-title--more-color) !important;
  height: 0.65rem;
`
