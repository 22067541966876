import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0.87rem;
  height: 0.61rem;
  line-height: 0.56rem;
  background-image: url('${process.env.PUBLIC_URL}/icon-video/free.png');
  background-size: 100% 100%;
`
