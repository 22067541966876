// 漫畫 一行三個
import { CmsGridWrapper } from 'commonStyledComponents'
import NovelBox from 'component/NovelBox'
import { memo } from 'react'

/**
 * 模板 ID: "302": 豎版一行三個
 * */
function Template302({ videoData, trigger, currentLimit }) {
  return (
    <CmsGridWrapper>
      {videoData?.slice(0, currentLimit).map((item, i) => {
        return <NovelBox overflow={true} columns={3} key={i} novelInfo={item} allowUnload />
      })}
      {trigger}
    </CmsGridWrapper>
  )
}

// this indicate areaBox should hide bottom section until fully shown
Template302.lazy = true
export default memo(Template302)
