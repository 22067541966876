import styled from 'styled-components'
// @ts-expect-error
import IconRank1 from './assets/rank1.svg'
// @ts-expect-error
import IconRank2 from './assets/rank2.svg'
// @ts-expect-error
import IconRank3 from './assets/rank3.svg'

interface TWrapperProp {
  columns: number
  width: string | number
}

export const Wrapper = styled.div<TWrapperProp>`
  --columns: ${(props) => props.columns ?? 2};
  width: ${(props) =>
    props?.width != null
      ? props.width
      : 'calc(calc(100% - var(--cms-grid-column-gap) * calc(var(--columns) - 1)) / var(--columns))'};

  cursor: pointer;
  display: block;
  position: relative;

  > * {
    user-select: none;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  border-radius: var(--component-video-box--border-radius);
  overflow: hidden;
  // fuck the safari
  /* transform: translateZ(0px); */
`

export const VideoInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.41rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  color: white;
`

//
export const BoxWrap = styled.div`
  display: block;
  position: relative;
  width: '100%';
`

export const VideoItemWrap = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.2rem;
`

export const VideoInfoWrap = styled.div`
  padding-right: 0.2rem;
`

export const VideoTitle = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.4rem;
`

export const TagList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
  padding: 0.1rem 0;
  font-size: 0.25rem;
`

export const TagItem = styled.div`
  background: var(--page-view-video--tag-background);
  /* color: var(--page-view-video--tag-color); */
  padding: 0.05rem 0.25rem;
  border-radius: 0.5rem;
  font-size: 0.3rem;
  line-height: 130%;
`

export const VideoDescription = styled.div`
  display: block;
  display: -webkit-box;
  margin-top: 0.2rem;
  font-size: 0.3rem;
  color: var(--component-note-box--color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-line-orient: vertical;
  -webkit-box-orient: vertical;
  line-height: 0.35rem;
  height: 0.7rem;
`

export const RankTagN = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #4e8aff;
  color: #fff;
  z-index: 1;
  width: 0.6rem;
  height: 0.6rem;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  font-style: italic;
  font-size: 0.35rem;
  line-height: 0.6rem;
  border-radius: 0.2rem 0 0.15rem 0;
`

export const RankTag1 = styled.div`
  position: absolute;
  top: -0.1rem;
  left: -0.1rem;
  background-image: url(${IconRank1});
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  z-index: 1;
  width: 0.6rem;
  height: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  border-radius: 0.2rem 0 0.15rem 0;
`

export const RankTag2 = styled(RankTag1)`
  background-image: url(${IconRank2});
`

export const RankTag3 = styled(RankTag1)`
  background-image: url(${IconRank3});
`
