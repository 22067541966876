import { memo } from 'react'
import VideoLargeBox from 'component/VideoLargeBox'
import VideoBox from 'component/VideoBox'
import { CmsGridWrapper } from 'commonStyledComponents'
import { Fragment } from 'react'

/**
 * 模板 ID: "3": 一大四小循環
 * */
function Template3({ videoData }) {
  const list = videoData
  return (
    <CmsGridWrapper>
      {list?.map((item, i) => {
        return i % 5 === 0 ? (
          <VideoLargeBox key={i} videoInfo={item} />
        ) : (
          <Fragment key={i}>
            <VideoBox videoInfo={item} allowUnload />
            {(list.length % 5 === 2 || list.length % 5 === 4) && list.length - i === 1 && (
              <VideoBox videoInfo={item} allowUnload />
            )}
          </Fragment>
        )
      })}
    </CmsGridWrapper>
  )
}
export default memo(Template3)
