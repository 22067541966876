import { useState } from 'react'

export default function useEvents() {
  /** 當前 激活的頻道 */
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  // const handleTabSwitch = useCallback(
  //   (activeIndex) => {
  //     setActiveTabIndex(activeIndex);
  //   },
  //   [setActiveTabIndex]
  // );

  return {
    activeTabIndex,
    setActiveTabIndex,
  }
}
