import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectNavigationContent } from 'redux/selector/app'
import { INDEX, PROFILE, CATEGORY, APP_CENTER, GAME } from 'constant/routers'
import {
  NAV_TYPE_ACCOUNT,
  NAV_TYPE_APPLICATIONS,
  NAV_TYPE_DISCOVER,
  NAV_TYPE_NORMAL,
  NAV_TYPE_GAME,
} from 'constant/common'

const NAV_MAP = {
  [NAV_TYPE_NORMAL]: INDEX,
  [NAV_TYPE_DISCOVER]: CATEGORY,
  [NAV_TYPE_GAME]: GAME,
  [NAV_TYPE_ACCOUNT]: PROFILE,
  [NAV_TYPE_APPLICATIONS]: APP_CENTER,
}

const navIconName = {
  1: 'nav/ic_home',
  2: 'nav/ic_hot',
  3: 'nav/ic_sex',
  4: 'nav/ic_game',
  5: 'nav/ic_discover',
}

export const useNavigationLocalData = () => {
  /** @type {import("../../../../types/api").SchemaCmsPublishResults} */
  const navigationContent = useSelector(selectNavigationContent)

  const navContent = useMemo(() => {
    /** due to old version application support three navigation */
    const apiNavResponse = navigationContent
      ?.filter((e, i) => NAV_MAP[e.type] != null)
      ?.map(({ type, icon_id, name, icon_path, icon_path2, id }, index) => {
        return {
          id,
          name,
          search: index !== 0 && NAV_MAP[type] === INDEX ? `?id=${id}` : '',
          imageName: navIconName[icon_id],
          dynamicIconActive: icon_path,
          dynamicIconInactive: icon_path2,
          navLink: NAV_MAP[type] ?? INDEX,
          type,
        }
      })

    return [...apiNavResponse]
  }, [navigationContent])

  return {
    navContent,
  }
}
