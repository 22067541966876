import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal() {
  const {
    navigateToPurchaseHistory,
    navigateToBrowseCollection,
    navigateToBrowseFavorite,
    navigateToBrowseHistory,
    navigateToBusinessRelationship,
    navigateToAppCenter,
    navigateToLogin,
    navigateToSystemConfig,
    navigateToShare,
    navigateToWallet,
    navigateToEnableVip,
  } = useNavigateTo()

  const openSystemConfigModal = () => {
    navigateToSystemConfig({})
  }

  const openLoginModal = () => {
    navigateToLogin({})
  }

  const openPurchaseHistoryModal = () => {
    navigateToPurchaseHistory()
  }

  const openFavouriteModal = () => {
    navigateToBrowseFavorite({})
  }

  const openBusinessRelationshipModal = () => {
    navigateToBusinessRelationship({})
  }

  const openCollectionModal = () => {
    navigateToBrowseCollection({})
  }

  const openBrowseHistoryModal = () => {
    navigateToBrowseHistory({})
  }

  const openEnableVipModal = () => {
    navigateToEnableVip({})
  }

  const openAppCenterModal = () => {
    navigateToAppCenter({ asPage: true })
  }

  const openSharePageModal = () => {
    navigateToShare()
  }

  const openWalletModal = () => {
    navigateToWallet({})
  }
  return {
    openSystemConfigModal,
    openLoginModal,
    openPurchaseHistoryModal,
    openFavouriteModal,
    openCollectionModal,
    openBrowseHistoryModal,
    openEnableVipModal,
    openAppCenterModal,
    openBusinessRelationshipModal,
    openSharePageModal,
    openWalletModal,
  }
}
