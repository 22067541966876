import AreaTitle from 'component/AreaTitle'
import styled from 'styled-components'

export const Wrapper = styled.div`
  font-size: 0.426rem;
  letter-spacing: 0.48px;
  margin-top: 0.2rem;
`

export const Title = styled(AreaTitle).attrs({ hasDecoration: true })`
  margin-bottom: 0.2rem;
`
