import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useLayoutEffect, useMemo, useState } from 'react'
import { TriggerElement } from '../Styled'

const VIEWPORT_BASE = 30
const VIEWPORT_INCREMENT = 30
const LOAD_NEXT_INTERVAL = 10

export const useViewport = ({ count, enabled = false }) => {
  const [currentLimit, setCurrentCount] = useState(VIEWPORT_BASE)
  const hasMore = currentLimit < count

  const { ref, visible } = useIntersectionObserver({
    defaultVisible: false,
    targetKey: `viewport-${count}`,
    // FIXME: internal class name of react-simple-pull-to-refresh
    rootClassName: 'ptr__children',
    rootMargin: '400px 0px',
  })

  useLayoutEffect(() => {
    if (visible && hasMore) {
      setCurrentCount((currentCount) => currentCount + VIEWPORT_INCREMENT)

      // slight delay and load even more until trigger is no longer on the screen
      const id = setInterval(() => {
        setCurrentCount((currentCount) => currentCount + VIEWPORT_INCREMENT)
      }, LOAD_NEXT_INTERVAL)

      return () => {
        clearInterval(id)
      }
    }
  }, [visible, hasMore])

  const result = useMemo(() => {
    return {
      trigger: enabled && hasMore ? <TriggerElement ref={ref} /> : null,
      currentLimit: enabled ? currentLimit : Infinity,
      finished: !enabled || !hasMore,
      ref,
    }
  }, [currentLimit, enabled, hasMore, ref])

  return result
}
