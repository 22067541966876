import styled from 'styled-components'

export const VideoList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--cms-grid-row-gap);
`

export const VideoItemWrap = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.2rem;
`
