import AnimatedIcon from 'component/AnimatedIcon'
import styled from 'styled-components'

export const ToolbarElement = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background: #000000;
  height: 1.28rem;
  color: white;
`

export const ToolbarSeparator = styled.div`
  align-self: center;
  width: 1px;
  height: 0.87rem;
  background: #2e2e2e;
`

export const ToolbarButton = styled.button`
  flex-grow: 1;
  width: 0;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
`
export const ToolbarButtonTextGroup = styled.div`
  font-size: 0.25rem;
  text-align: left;
`

export const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 1rem;
  width: 1rem;
  margin-right: calc(-0.15rem + 5px);
`
