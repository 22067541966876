import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
  padding: 0 var(--cms-grid-column-gap, 0.266rem);
  gap: var(--cms-grid-row-gap, 0.266rem) var(--cms-grid-column-gap, 0.266rem);

  > div {
    flex: 0 0 auto;
  }
`
