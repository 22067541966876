// 漫畫 左右滑動
import { Swiper, SwiperSlide } from 'swiper/react'
import { Wrapper } from './Styled'
import NovelBox from 'component/NovelBox'
import { memo, useCallback, useState } from 'react'
import { selectStation } from 'redux/selector/app'
import { useSelector } from 'react-redux'
import EffectFlat from 'swiperEffects/cardFlat'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useDuplicatedArray } from 'hooks/useDuplicatedArray'
import { AreaBoxSwiperTemplateWrapper, MaskedAesBackground } from '../Styled'

function Template304({ videoData: videoDataProp }) {
  const videoData = useDuplicatedArray(videoDataProp, 5)

  const [currentSlide, setCurrentSlide] = useState(0)

  const station = useSelector(selectStation)

  /** @type {import("../../../../../types/api").SchemaComicShow} */
  const currentItem = videoData[currentSlide]

  const currentImage = currentItem?.cover_url

  const onSlideChange = useCallback((swiper) => {
    setCurrentSlide(swiper.realIndex)
  }, [])

  const { ref, visible } = useIntersectionObserver({
    /* Optional options */
    defaultVisible: false,
    threshold: 0,
    triggerOnce: false,
  })

  return (
    <AreaBoxSwiperTemplateWrapper>
      <Wrapper ref={ref}>
        <MaskedAesBackground
          source="novel"
          className={'swiper-no-swiping'}
          decryptKey={station.cover_key}
          src={currentImage}
          noLazy={visible}
          allowUnload
          useExternalHeight
        />
        <Swiper
          centeredSlides={true}
          spaceBetween={30}
          slidesPerView={2}
          effect="flat"
          onSlideChange={onSlideChange}
          modules={[EffectFlat]}
          flatEffect={{
            depth: 300,
          }}
          loop={true}
          nested={true}
        >
          {videoData?.map((item, i) => {
            return (
              <SwiperSlide key={i} style={{ width: '4.7rem' }}>
                <NovelBox
                  noDecoration
                  overflow={true}
                  style={{ textAlign: 'center' }}
                  novelInfo={item}
                  width="auto"
                  titleInSlide
                  noLazy={visible}
                  allowUnload
                  hideTags
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Wrapper>
    </AreaBoxSwiperTemplateWrapper>
  )
}
export default memo(Template304)
