// 長視頻 豎版一大左右滑動循環
import VideoBox from 'component/VideoBoxVertical'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Wrapper } from './Styled'
import EffectStack from 'swiperEffects/cardStack'
import { memo, useCallback, useMemo, useState } from 'react'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useDuplicatedArray } from 'hooks/useDuplicatedArray'
import { AreaBoxSwiperTemplateWrapper, MaskedBase64Background } from '../Styled'

function Template105({ videoData: videoDataProp }) {
  const videoData = useDuplicatedArray(videoDataProp, 5)

  const shortInfo = useMemo(
    () => ({
      list: videoData,
    }),
    [videoData]
  )
  const [currentSlide, setCurrentSlide] = useState(0)

  /** @type {import("../../../../../types/api").SchemaShortVideoShow} */
  const currentItem = videoData[currentSlide]

  const currentImage = currentItem?.coverphoto_v

  const onSlideChange = useCallback((swiper) => {
    setCurrentSlide(swiper.realIndex)
  }, [])

  const { ref, visible } = useIntersectionObserver({
    /* Optional options */
    defaultVisible: false,
    threshold: 0,
    triggerOnce: false,
  })

  return (
    <AreaBoxSwiperTemplateWrapper>
      <Wrapper ref={ref}>
        <MaskedBase64Background className={'swiper-no-swiping'} src={currentImage} noLazy={visible} allowUnload />
        <Swiper
          effect="stack"
          onSlideChange={onSlideChange}
          modules={[EffectStack]}
          virtualTranslate={true}
          loop={true}
          nested={true}
        >
          {videoData?.map((item, i) => {
            return (
              <SwiperSlide key={item.id}>
                <VideoBox
                  overflow={true}
                  textAlign="center"
                  videoInfo={item}
                  width="auto"
                  shortInfo={shortInfo}
                  noLazy={visible}
                  fontSize={'0.41rem'}
                  allowUnload
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Wrapper>
    </AreaBoxSwiperTemplateWrapper>
  )
}

export default memo(Template105)
