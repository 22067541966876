import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.form`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 0.307rem;
  background: var(--app-search-field--background);
  border-radius: 2.5rem;
`

export const TextField = styled.input`
  position: relative;
  font-size: 0.358rem;
  height: 0.948rem;
  flex: 1;
  color: var(--app-search-field--color);

  &::placeholder {
    color: var(--app-search-field--placeholder-color);
  }
`

export const IconSearch = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/search.svg`,
  color: `var(--page-search-video-viaspecific--search-icon)`,
})`
  margin-right: 0.143rem;
  width: 0.538rem;
  height: 0.538rem;
  position: absolute;
  right: 0.307rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

export const IconEmpty = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 0.346rem;
  z-index: 1;
  width: 0.538rem;
  height: 0.538rem;
  background: url(${process.env.PUBLIC_URL}/icon/empty.png) right center no-repeat;
  background-size: contain;
`

export const ButtonFilter = styled.button.attrs({ type: 'button' })`
  white-space: nowrap;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 0.9487rem;
  font-size: 0.358rem;
  line-height: 130%;
  letter-spacing: 0.38px;
  color: #ffffff;
  background: var(--page-search-video-viaspecific--search-button-background);
  color: var(--page-search-video-viaspecific--search-button-color);
  margin-left: 0.2rem;
  border-radius: 2.5rem;
  padding: 0 0.41rem;
`
