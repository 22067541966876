import styled, { css } from 'styled-components'
import EmptyResult from 'component/EmptyResult'
import { ColoredIcon } from 'component/ColoredIcon'
import PageLoadingIcon from 'component/Loading/PageLoadingIcon'
export const PageWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: grid;
  grid-template-rows: calc(var(--status-bar-height) + env(safe-area-inset-top, 0px)) 1fr;
  z-index: 1;
  --tab-bar-height: 1.15rem;
`

export const Wrapper = styled.div`
  height: 100%;
  font-size: 0.36rem;
  overflow: auto;
  /* 這裡加 padding 會導致遊戲區塊高度機算錯誤卷不上去 */
  /* padding: 0.2rem 0; */

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  > * {
    flex: 0 0 auto;
  }

  > :first-child {
    margin-top: 0.2rem;
  }
`

export const GameListWrap = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`

export const GameList = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.1rem 0.2rem;
  align-content: flex-start;
  position: relative;
`

export const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledAnimation = PageLoadingIcon

export const LobbyTitle = styled.div`
  padding-top: calc(0.5rem + env(safe-area-inset-top, 0px));
  font-size: 0.5rem;
  font-weight: 700;
  text-align: center;
  background: var(--page-header-background);
  color: var(--page-header-color);
`

export const GoTop = styled.div`
  position: fixed;
  right: 0.4rem;
  bottom: calc(1.6rem + env(safe-area-inset-bottom, 0px));
  background: center no-repeat;
  background-size: cover;
  background-image: url(${process.env.PUBLIC_URL}/game/icon-to-top.svg);
  width: 1rem;
  height: 1rem;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
  &.visible {
    opacity: 1;
    pointer-events: unset;
  }
`

export const LobbyErrorMessage = styled.div`
  text-align: center;
  padding-top: 0.5rem;
  > div {
    padding-bottom: 0.5rem;
  }
`

export const GamePanel = styled.div`
  /* height: 0; */
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 0.2rem;
  /* height: calc(100% - var(--tab-bar-height)); */
  flex: 1 1 auto;
  overflow: hidden;
`

export const GamePanelSide = styled.div`
  flex: 0 0 auto;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.2rem 0 0.2rem 0.2rem;
`

export const GamePanelCenter = styled.div`
  flex: 1 1 auto;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow: auto;
`

export const FixedTabsHeadOuterWrap = styled.div`
  position: relative;
  height: 0;
  z-index: 2;
`

export const FixedTabsHeadWrap = styled.div`
  flex: 0 0 auto;
  padding: 0.2rem 0;

  height: var(--tab-bar-height);

  z-index: 2;
  top: 0;
  background: var(--app-background, var(--main-background));
`
export const TabsHeadWrap = styled.div`
  flex: 0 0 auto;
  padding: 0.2rem 0;

  height: var(--tab-bar-height);

  z-index: 2;
  position: sticky;
  top: 0;
`

export const GamePanelCenterTabGamesMono = styled.div`
  align-self: stretch;
  /* flex-grow: 1; */
  max-width: 100vw;
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  > * {
    flex: 1 0 auto;
  }
  /* height: 0; */
`

export const GamePanelSection = styled.div`
  min-height: 100%;
`

export const StyledColoredIcon = styled(ColoredIcon)`
  width: 0.71795rem;
  height: 0.71795rem;
`

export const GameCategoryIcon = styled.div`
  width: 1.64103rem;
  height: 1.64103rem;
  border-radius: 0.25rem;
  flex: 0 0 auto;
  background-image: url(${`${process.env.PUBLIC_URL}/game/category.svg`});
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  font-size: 0.30769rem;
  align-items: center;
  justify-content: center;
  color: var(--page-game-lobby--category-color);

  scroll-margin-top: 0.2rem;
  scroll-margin-bottom: 0.2rem;

  > ${StyledColoredIcon} {
    background: var(--page-game-lobby--category-icon-color);
  }

  ${(props) =>
    props.active
      ? css`
          background-image: url(${`${process.env.PUBLIC_URL}/game/category_active.svg`});
          color: var(--page-game-lobby--category-active-color);

          > ${StyledColoredIcon} {
            background: var(--page-game-lobby--category-icon-active-color);
          }
        `
      : css``}
`

export const StyledEmptyResult = styled(EmptyResult).attrs({
  src: `${process.env.PUBLIC_URL}/empty-game-result.svg`,
  text: '看其他分类',
  width: '1.89744rem',
  height: '2.25641rem',
})`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export const MarqueeRow = styled.div`
  width: 2rem;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding: 0.05rem 0.2rem;
`

export const TransferButton = styled.button`
  position: relative;
  padding: 0.1rem 0.5rem;
  background: var(--page-game-theme-border-color);
  border-radius: 0.25rem;
  color: #000;
  &::after {
    position: absolute;
    content: ' ';
    inset: 0.05rem;
    background: var(--page-game-theme-background);
    border-radius: 0.25rem;
  }
  > div {
    z-index: 1;
    position: relative;
  }
`
