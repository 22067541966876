import { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import {
  ChapterArea,
  ChapterItem,
  CloseButton,
  SortIcon,
  SortButton,
  TitleBar,
  TitleWrap,
  BareListWrapper,
} from './Styled'
import { useAllNovelChapters } from 'hooks/useAllNovelChapters'
import classNames from 'classnames'

const styles = {
  overlay: {
    zIndex: 100, // higher than page
    backgroundColor: 'rgba(16, 4, 27, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: 'calc(env(safe-area-inset-top, 0px) + 1rem) 0 0 0',
  },
  content: {
    position: 'static',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    inset: 'unset',
    border: 'none',
    background: 'var(--main-background)',
    padding: 0,
    borderRadius: '5px 5px 0px 0px',
    marginRight: 0,
    color: '#fff',
  },
}

export function Chapters({
  novelId,
  disabled = false,
  isOpen = false,
  onClose = () => {},
  activeChapter = null,
  title = '',
  onOpenChapter = (chapter) => {},
  preloadedList = null,
  noOverlay = false,
  className,
}) {
  const [sortReversed, setSortReversed] = useState(false)

  const listLive = useAllNovelChapters(novelId, !isOpen || preloadedList != null)
  const list = preloadedList ?? listLive

  const listToUse = useMemo(() => {
    if (sortReversed) {
      return list
        .slice(0)
        .map((i, index) => ({ ...i, realIndex: index }))
        .reverse()
    } else {
      return list.slice(0).map((i, index) => ({ ...i, realIndex: index }))
    }
  }, [list, sortReversed])

  const elements = useRef({})

  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => {
    const index = listToUse.findIndex((i) => String(i.id) === String(activeChapter))
    if (modalOpened && index >= 0) {
      elements.current[activeChapter]?.scrollIntoView()
    }
  }, [activeChapter, listToUse, modalOpened])

  const contentJSX = (
    <>
      <TitleBar>
        <SortButton onClick={() => setSortReversed((v) => !v)}>
          <SortIcon reversed={sortReversed} />
          {sortReversed ? '倒序' : '正序'}
        </SortButton>
        <TitleWrap>{title}</TitleWrap>
        <CloseButton onClick={onClose} />
      </TitleBar>
      <ChapterArea>
        {listToUse.map((item, index) => (
          <ChapterItem
            key={item.id}
            active={
              activeChapter === null || Number(activeChapter) === 0 ? item.realIndex === 0 : item.id === activeChapter
            }
            onClick={() => onOpenChapter(item)}
            ref={(el) => {
              elements.current[item.id] = el
            }}
          >
            {item.prefix_name + (item.title ? ` - ${item.title}` : '')}
          </ChapterItem>
        ))}
      </ChapterArea>
    </>
  )

  useLayoutEffect(() => {
    if (!noOverlay) {
      return
    }
    setModalOpened(isOpen)
  }, [isOpen, noOverlay])

  if (!noOverlay) {
    return (
      <Fragment>
        {!disabled && (
          <ReactModal
            isOpen={isOpen}
            style={styles}
            onAfterOpen={() => {
              setModalOpened(true)
            }}
            onAfterClose={() => {
              setModalOpened(false)
            }}
            className={className}
          >
            {contentJSX}
          </ReactModal>
        )}
      </Fragment>
    )
  } else {
    return (
      <BareListWrapper className={classNames(disabled || !isOpen ? 'hidden' : '', className)}>
        {contentJSX}
      </BareListWrapper>
    )
  }
}
