import styled from 'styled-components'

export const Wrapper = styled.div`
  --dragger-size: 0.35rem;
  padding: 0 calc(var(--dragger-size) / 2);
  position: relative;

  display: flex;
  align-items: stretch;

  touch-action: none;
`

export const Bar = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
`
export const BarVisual = styled.div`
  flex: 1 1 auto;
  height: 0.05rem;
  background: var(--theme-color);
`

export const Dot = styled.div`
  --translate: 0px;
  position: absolute;
  left: 0;
  top: 50%;
  width: var(--dragger-size);
  height: var(--dragger-size);
  border-radius: calc(var(--dragger-size) / 2);
  background: var(--theme-color);
  transform: translate(-50%, -50%) translate(var(--translate));
  transition: transform 0.2s;
`

export const Label = styled.div`
  --translate: 0px;
  position: absolute;
  left: 0;
  top: -0.25rem;
  border-radius: 0.24rem;
  background: white;
  opacity: 0.8;
  transform: translate(-50%, -100%) translate(var(--translate));

  font-family: Roboto;
  font-size: 0.35897rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.00974rem;
  color: var(--system-black, #000);

  font-family: monospace;

  padding: 0.21rem 0.31rem;
  transition: transform 0.2s;
`
