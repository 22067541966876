import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'

import { FETCH_USER_INFO } from 'redux/constant/user'
import { selectUserInfo } from 'redux/selector/user'

import { fetchProductList, fetchPurchaseProduct, fetchUserInfo } from 'api'

import HeaderBox from 'component/PageModalHeaderBox'
import DepositChannelBox from 'component/DepositChannelBox'

import useDepositChannelBox from 'hooks/useDepositChannelBox'

import { Content, InfoBox, Info, Avatar, VipBox, VipCard, VipTitle, HeaderWrapper, InfoBoxWrapper } from './Styled'
import DepositSuccess from '../Deposit/component/DepositSuccess'
import { PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import NoteBox from 'component/NoteBox'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import actions from 'redux/action'
import { parseVipInfo } from 'utils/userUtils'
import { usePageActivated } from 'app-layered-layout/usePageActivated'

export default function EnableVip() {
  const dispatch = useDispatch()
  const navigate = useAppNavigate()
  const pageActivated = usePageActivated()
  const { closeDepositChannelBox, openDepositChannelBox } = useDepositChannelBox()
  const userInfo = useSelector(selectUserInfo)
  const alert = useAlert()
  const [isShowDepositSuccess, setIsShowDepositSuccess] = useState(false)
  const [depositItems, setDepositItems] = useState([])
  const [selectedDepositItem, setSelectedDepositItem] = useState([])
  const vipInfo = useMemo(() => parseVipInfo(userInfo), [userInfo])

  const handlePopupConfirm = useCallback(() => {
    /** 如果是從視頻介面進來, 會帶videoInfo, VIP啟用成功後, 自動轉為高清影片, 並導向至原視頻頁面 */
    setIsShowDepositSuccess(false)
    navigate(-1)
  }, [navigate])

  const onSuccessCallback = () => {
    setIsShowDepositSuccess(true)

    // 重新使用deviceId獲取用戶資料
    dispatch({ type: FETCH_USER_INFO })

    document.removeEventListener('visibilitychange', onSuccessCallback)
  }

  const bindVisibilitychange = () => {
    document.addEventListener('visibilitychange', onSuccessCallback)
  }

  async function loadPage(signal) {
    const userInfo = await fetchUserInfo({ signal })
    dispatch(actions.updateUser(userInfo))
    const response = await fetchProductList({ product_type: 2 }) // (1:金幣 2:VIP)
    setDepositItems(response?.data)
  }

  const handleDeposit = (payTypeId) => {
    const newWindow = window.open('', '_blank')
    fetchPurchaseProduct({
      payTypeId: payTypeId,
      productId: selectedDepositItem?.id,
    })
      .then((response) => {
        newWindow.location.href = response?.pay_url

        /** 監聽頁面跳轉回來的事件 */
        bindVisibilitychange()

        closeDepositChannelBox()
      })
      .catch((error) => {
        newWindow.close()
        alert.show(error?.message || '充值失败, 请联系客服!', { position: 'middle' })
      })
  }

  const onDepositItemSelected = (itemInfo) => {
    setSelectedDepositItem(itemInfo)
    openDepositChannelBox()
  }

  const handlePopupDismiss = () => {
    setIsShowDepositSuccess(false)
  }

  useEffect(() => {
    if (!pageActivated) {
      return
    }
    const controller = new AbortController()
    const signal = controller.signal
    loadPage(signal)
    return () => {
      controller.abort()
    }
  }, [pageActivated])

  return (
    <PageWrapperNonScroll>
      <HeaderWrapper>
        <HeaderBox headerTitle={'会员等级'} />
        <InfoBoxWrapper>
          <InfoBox>
            <Avatar>
              <img alt={'man'} width={'100%'} height={'100%'} src={`${process.env.PUBLIC_URL}/avatar/avatar_1.png`} />
            </Avatar>
            <Info>
              <div>
                <VipTitle>{vipInfo.title}</VipTitle>
                <div>{vipInfo.expireText}</div>
              </div>
            </Info>
          </InfoBox>
        </InfoBoxWrapper>
      </HeaderWrapper>
      <PageScrollWrap>
        <Content>
          <VipBox>
            {depositItems?.map((item, i) => {
              const classNameNumber = 1 + (i % 3)

              return (
                <VipCard
                  key={i}
                  src={item.icon_path}
                  className={`style-${classNameNumber}`}
                  onClick={() => onDepositItemSelected(item)}
                ></VipCard>
              )
            })}
          </VipBox>
          <NoteBox
            text={['支付不成功，请多尝试支付。', '无法拉起支付订单，是由于拉起订单人数较多，请稍后再试。']}
          ></NoteBox>
        </Content>
      </PageScrollWrap>
      <DepositChannelBox selectedDepositItem={selectedDepositItem} onSubmit={handleDeposit} />
      <DepositSuccess isOpen={isShowDepositSuccess} onDismiss={handlePopupDismiss} onConfirm={handlePopupConfirm} />
    </PageWrapperNonScroll>
  )
}
