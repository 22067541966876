import { fetchAddNovelCollectList, fetchNovelChapters, fetchNovelList, fetchRemoveNovelCollectList } from 'api'
import { COMIC_SERIAL_ENDED, COMIC_SERIAL_ONGOING } from 'constant/comic'
import { DISABLED, ENABLED, TRUE } from 'constant/common'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useCallback, useEffect, useState } from 'react'
import Recommendations from './components/Recommendations'
import useNovelChapters from './hooks/useNovelChapters'
import {
  Wrapper,
  IconBack,
  Title,
  TitleCard,
  TopCover,
  WrapperInner,
  TagArea,
  Tag,
  Desc,
  StatusBar,
  TitleObserveArea,
  StatusBarContent,
  Status,
  ChapterList,
  ChapterItem,
  ChapterTrigger,
  Footer,
  StartReading,
  CollectionButton,
  StyledAnimatedIcon,
  NoStartReading,
  StyledAesImage,
  CoverDecoration,
  InfoWrap,
  NovelCover,
  AuthorText,
  StarArea,
  Star,
  TopCoverFiller,
} from './Styled'
import saveIconData from './animated-icons/save_outline.json'
import { Chapters } from './components/Chapters'
import { useOpenNovelChapter } from './hooks/useOpenNovelChapter'
import { useDispatch, useSelector } from 'react-redux'
import { RESET_NOVEL_COLLECTION } from 'redux/constant/novelCollection'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import ButtonSearchMore from 'component/ButtonSearchMore'
import { useSharedCache } from 'hooks/useDirtyWorkData'
import { selectStation } from 'redux/selector/app'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'

export default function ViewNovel() {
  const { posterImg, novelInfo: initialNovelInfo } = useParamsOfPage('novel')
  const pageActivated = usePageActivated()
  const navigate = useAppNavigate()

  const { addDirtyData, useMappedData } = useSharedCache()
  const station = useSelector(selectStation)
  const dispatch = useDispatch()
  const [novelInfo] = /** @type {(typeof initialNovelInfo)[]} */ (useMappedData('novel', [initialNovelInfo]))

  useEffect(() => {
    // also trigger update when page re-activate
    if (!pageActivated) {
      return
    }
    const con = new AbortController()
    const sig = con.signal

    fetchNovelList({
      signal: sig,
      detail: TRUE,
      id: initialNovelInfo.id,
    }).then((res) => {
      addDirtyData('novel', res.data)
    })

    return () => {
      con.abort()
    }
  }, [addDirtyData, initialNovelInfo.id, pageActivated])

  const {
    id,
    favorite,
    tag_ids = [],
    tag_names = [],
    serial,
    last_read_chapter_id,
    // latest_chapter_prefix_name,
    author,
    favorite_count,
  } = novelInfo

  const [lastReadChapterInfo, setLastReadChapterInfo] = useState(null)

  useEffect(() => {
    if (!last_read_chapter_id || Number(last_read_chapter_id) === 0) {
      setLastReadChapterInfo(null)
      return
    }
    const con = new AbortController()
    const sig = con.signal
    fetchNovelChapters({ id: last_read_chapter_id, signal: sig }).then((info) => {
      setLastReadChapterInfo(info.data[0])
    })
    return () => {}
  }, [last_read_chapter_id])

  const [newestChapter, setNewestChapter] = useState(null)
  useEffect(() => {
    const con = new AbortController()
    const sig = con.signal
    fetchNovelChapters({ novel_id: id, pageSize: 1, signal: sig }).then((info) => {
      const total = info.page_result.total
      fetchNovelChapters({ novel_id: id, current: total, pageSize: 1, signal: sig }).then((info) => {
        console.log(info)
        setNewestChapter(info.data?.[0])
      })
    })
  }, [id])

  const expandRange = (current, [rangeMin, rangeMax], [toMin, toMax]) => {
    const expanded = ((current - rangeMin) / (rangeMax - rangeMin)) * (toMax - toMin) + toMin
    const capped = Math.min(toMax, Math.max(toMin, expanded))
    return capped
  }

  const { ref, containerRef, ratio } = useIntersectionObserver({
    threshold: Array.from({ length: 19 }).map((_, index) => (index + 1) / 19),
    defaultVisible: true,
  })

  const mappedRatio = expandRange(ratio, [1, 0.95], [0, 1])

  const { state, fetchMore } = useNovelChapters({
    novel_id: id,
  })

  const [chapterListOpened, setChapterListOpened] = useState(false)

  const {
    ref: loadMoreRef,
    containerRef: loadMoreContainerRef,
    visible,
  } = useIntersectionObserver({
    defaultVisible: false,
    cb: (entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          fetchMore()
        }
      }
    },
  })

  useEffect(() => {
    if (state.hasMore && state.list.length === 0) {
      fetchMore()
    }
  }, [fetchMore, state.nextPage, state.hasMore, state.list.length, visible])

  const { navigateToViewMore } = useNavigateTo()
  const openViewMore = (tagId, tagName) => {
    navigateToViewMore({
      params: {
        tag_ids: tagId,
      },
      headerTitle: tagName,
      type: 'novel',
    })
  }
  const { openNovelChapter: openComicChapter } = useOpenNovelChapter()

  const onOpenChapter = useCallback(
    (chapter) => {
      openComicChapter(novelInfo.id, chapter)
    },
    [novelInfo.id, openComicChapter]
  )

  const alerts = useAlertWorksActionResult()

  const setFavorite = async (value) => {
    if (value) {
      await fetchAddNovelCollectList({ novel_id: id })
    } else {
      await fetchRemoveNovelCollectList({ novel_id: id })
    }

    dispatch({ type: RESET_NOVEL_COLLECTION })

    const res = await fetchNovelList({
      detail: TRUE,
      id: initialNovelInfo.id,
    })

    addDirtyData('novel', res.data)

    if (value) {
      alerts.alertSaved()
    } else {
      // alerts.alertUnsaved()
    }
  }

  const startReading = () => {
    if (lastReadChapterInfo) {
      onOpenChapter(lastReadChapterInfo)
      return
    }

    if (state.list[0]) {
      onOpenChapter(state.list[0])
      return
    }
  }

  const canStartReading = lastReadChapterInfo != null || state.list[0] != null

  return (
    <Wrapper>
      <WrapperInner ref={containerRef}>
        <TopCover
          style={{
            backgroundImage: `url(${posterImg})`,
          }}
        >
          {!posterImg && (
            <StyledAesImage
              decryptKey={station.cover_key}
              src={novelInfo.cover_url}
              style={{ objectPosition: '50% 0' }}
              source="novel"
            />
          )}
          <CoverDecoration />
          <TopCoverFiller />
          <TitleCard>
            <NovelCover decryptKey={station.cover_key} src={novelInfo.cover_url} source="novel" />
            <InfoWrap>
              <Title>
                <TitleObserveArea ref={ref} />
                {novelInfo.title}
              </Title>
              <AuthorText>{author}</AuthorText>
              {tag_ids.filter((id, index) => tag_names[index]).length > 0 && (
                <TagArea>
                  {tag_ids
                    .filter((id, index) => tag_names[index])
                    .map((id, index) => (
                      <Tag key={index} onClick={() => openViewMore(id, tag_names[index])}>
                        {tag_names[index]}
                      </Tag>
                    ))}
                </TagArea>
              )}
              <StarArea>
                <Star /> <div>{favorite_count}</div>
              </StarArea>
              {novelInfo.summary && <Desc text={novelInfo.summary} />}
            </InfoWrap>
          </TitleCard>
        </TopCover>
        {canStartReading && (
          <>
            <Status text={serial === COMIC_SERIAL_ENDED ? '已完结' : serial === COMIC_SERIAL_ONGOING ? '连载中' : ''}>
              <ButtonSearchMore
                onClick={() => setChapterListOpened((v) => !v)}
                text={`更新到${newestChapter?.prefix_name ?? ''}`}
                style={{ color: 'var(--page-view-comic--more-color)' }}
              />
            </Status>
            <ChapterList ref={loadMoreContainerRef}>
              {state.list.map((item, index) => (
                <ChapterItem
                  key={index}
                  active={Number(last_read_chapter_id) === 0 ? index === 0 : item.id === last_read_chapter_id}
                  onClick={() => onOpenChapter(item)}
                >
                  {item.prefix_name + (item.title ? ` - ${item.title}` : '')}
                </ChapterItem>
              ))}
              {state.hasMore && <ChapterTrigger ref={loadMoreRef} />}
            </ChapterList>
          </>
        )}
        {tag_ids.length > 0 && <Recommendations tag_ids={tag_ids} />}
      </WrapperInner>
      <Footer>
        <CollectionButton onClick={() => setFavorite(favorite === DISABLED)}>
          <StyledAnimatedIcon animationData={saveIconData} active={favorite === ENABLED} />
          {favorite === ENABLED ? '已收藏' : '收藏'}
        </CollectionButton>
        {canStartReading ? (
          <StartReading onClick={startReading}>
            {lastReadChapterInfo ? `续看${lastReadChapterInfo.prefix_name}` : '开始阅读'}
          </StartReading>
        ) : (
          <NoStartReading>暂无章节</NoStartReading>
        )}
      </Footer>
      <StatusBar>
        <StatusBarContent style={{ opacity: mappedRatio }}>{novelInfo.title}</StatusBarContent>
        <IconBack
          onClick={() => {
            navigate(-1)
          }}
        />
      </StatusBar>
      <Chapters
        title={`更新到${newestChapter?.prefix_name ?? ''}`}
        novelId={id}
        disabled={!pageActivated}
        isOpen={chapterListOpened}
        onClose={() => setChapterListOpened(false)}
        activeChapter={last_read_chapter_id}
        onOpenChapter={onOpenChapter}
      />
    </Wrapper>
  )
}
