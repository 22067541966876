import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Bar, BarVisual, Dot, Label, Wrapper } from './Styled'

const LabeledSlider = ({
  min = 1,
  max = 2,
  value = 0,
  showLabel = true,
  onChange = (value) => {},
  labelFormat = (value, min, max) => `${value} / ${max - min + 1}`,
  ...props
}) => {
  const totalStep = max - min
  const [state, setState] = useState(/** @type {'init' | 'dragging'} */ ('init'))
  const [pointer, setPointer] = useState(null)
  const [maxTranslate, setMaxTranslate] = useState(0)
  const [translateBaseX, setTranslateBaseX] = useState(0)
  const [initialX, setInitialX] = useState(0)
  const [currentX, setCurrentX] = useState(0)

  const wrapperEl = useRef()
  const barEl = useRef()
  const dotEl = useRef()

  const onClickOutside = useCallback(() => {
    setState('init')
  }, [])

  useEffect(() => {
    window.addEventListener('pointerdown', onClickOutside)
    return () => {
      window.removeEventListener('pointerdown', onClickOutside)
    }
  })

  useLayoutEffect(() => {
    if (barEl.current == null) {
      return
    }

    const cb = () => {
      const wrapperRect = wrapperEl.current.getBoundingClientRect()
      const barRect = barEl.current.getBoundingClientRect()
      setMaxTranslate(barRect.width)
      setTranslateBaseX(barRect.x - wrapperRect.x)
    }
    const observer = new ResizeObserver(cb)
    observer.observe(barEl.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  const prevEmittedValue = useRef(null)
  const emitIfNot = (value) => {
    console.log(value)
    if (value !== prevEmittedValue.current) {
      onChange(value)
      prevEmittedValue.current = value
    }
  }

  /**
   * @param {React.PointerEvent<HTMLDivElement>} ev
   */
  const onPointerDown = (ev) => {
    ev.stopPropagation()
    if (state === 'init') {
      // console.log(ev.currentTarget)
      barEl.current.setPointerCapture(ev.pointerId)
      setPointer(ev.pointerId)
      setState('dragging')
      const barRect = barEl.current.getBoundingClientRect()
      setInitialX(barRect.x)
      setCurrentX(ev.clientX)

      const previewValue = getDiff(ev.clientX - barRect.x) + min
      emitIfNot(previewValue)
    }
  }

  /**
   * @param {React.PointerEvent<HTMLDivElement>} ev
   */
  const onPointerMove = (ev) => {
    if (state === 'dragging' && ev.pointerId === pointer) {
      // console.log(ev)
      setState('dragging')
      setCurrentX(ev.clientX)

      const previewValue = getDiff(ev.clientX - initialX) + min
      emitIfNot(previewValue)
    }
  }

  /**
   * @param {React.PointerEvent<HTMLDivElement>} ev
   */
  const onPointerUp = (ev) => {
    if (state === 'dragging' && ev.pointerId === pointer) {
      // console.log(ev)
      setState('init')
    }
  }

  /**
   * @param {React.PointerEvent<HTMLDivElement>} ev
   */
  const onPointerCancel = (ev) => {
    if (state === 'dragging' && ev.pointerId === pointer) {
      // console.log(ev)
      setState('init')
    }
  }

  const getDiff = (offset) => {
    const wrappedTranslate = Math.max(Math.min(offset, maxTranslate), 0)
    const diff = Math.round((wrappedTranslate / maxTranslate) * totalStep)
    return diff
  }

  const previewDiff = getDiff(currentX - initialX)
  const snappedTranslate = (maxTranslate / totalStep) * previewDiff
  const previewValue = previewDiff + min

  const actualTranslate = ((value - min) / totalStep) * maxTranslate

  return (
    <Wrapper {...props} ref={wrapperEl}>
      <Bar
        ref={barEl}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onPointerCancel={onPointerCancel}
      >
        <BarVisual></BarVisual>
        <Dot
          ref={dotEl}
          style={
            state === 'dragging'
              ? { '--translate': `${snappedTranslate}px` }
              : { '--translate': `${actualTranslate}px` }
          }
        />
      </Bar>
      {showLabel && (
        <Label
          style={{ '--translate': `${translateBaseX + (state === 'dragging' ? snappedTranslate : actualTranslate)}px` }}
        >
          {labelFormat(state === 'dragging' ? previewValue : value, min, max)}
        </Label>
      )}
    </Wrapper>
  )
}

export default LabeledSlider
