import { memo } from 'react'
import { Wrapper, InnerWrapper } from './Styled'
function AreaWrapper({ children, ...props }) {
  return (
    <Wrapper>
      <InnerWrapper {...props}>{children}</InnerWrapper>
    </Wrapper>
  )
}

export default memo(AreaWrapper)
