import AnimatedIcon from 'component/AnimatedIcon'
import { ColoredIcon } from 'component/ColoredIcon'
import LabeledSlider from 'component/LabeldSlider'
import NovelPlayer from 'component/NovelPlayer'
import { Chapters } from 'page/ViewNovel/components/Chapters'
import styled from 'styled-components'

import lightBackground from './assets/light-background.png'
import playerStyles from 'component/NovelPlayer/Style.module.css'
console.log(playerStyles.page)

export const Wrapper = styled.div`
  min-width: 0;
  flex: 1 1 auto;
  align-self: stretch;
  overflow: hidden;
  background: var(--page-view-comic-chapter--background);
  color: var(--page-view-comic-chapter--color);
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);

  display: flex;
  flex-direction: column;

  transition: background 0.3s, color 0.3s;

  &.mode-light {
    background: center / cover no-repeat url('${lightBackground}');
    --reader-page-background: center / cover no-repeat url('${lightBackground}');
    color: #2e2e2e;
    --line-color: #c9b79c;
  }

  &.mode-dark {
    background: #000000;
    --reader-page-background: #000000;
    color: #f9f9f9;
    --line-color: #5a5a5a;
  }
`

export const Header = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: calc(env(safe-area-inset-top) + 0.75rem);
  padding-top: env(safe-area-inset-top);
  background: var(--page-view-comic-chapter--header-footer-background);
  color: var(--page-view-comic-chapter--header-footer-color);
  transition: all 0.5s;
  transform: translateY(0%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.4rem;

  &.hidden {
    transform: translateY(-100%);
  }
`

export const HeaderClose = styled.button`
  position: absolute;
  top: calc(50% + calc(env(safe-area-inset-top, 0px) / 2));
  transform: translateY(-50%);
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  background-size: 50% 50%;
  background-image: url('${process.env.PUBLIC_URL}/novel-icons/chapter_close.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  padding: 0;
`

export const Footer = styled.div`
  --footer-height: 2.38rem;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(env(safe-area-inset-bottom) + var(--footer-height));
  background: var(--page-view-comic-chapter--header-footer-background);
  color: var(--page-view-comic-chapter--header-footer-color);
  transition: all 0.5s;
  transform: translateY(0%);

  &.hidden {
    transform: translateY(100%);
  }

  display: flex;
  justify-content: space-between;
  align-items: stretch;
`
export const FooterButton = styled.button`
  min-width: 0;
  width: 1.69rem;
  padding-top: 0.4rem;
  padding-bottom: calc(0.4rem + env(safe-area-inset-bottom, 0));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 0.35rem;
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const FooterButtonIcon = styled(ColoredIcon)`
  width: 0.8rem;
  height: 0.8rem;

  &.active {
    color: var(--theme-color);
  }
`

export const FooterButtonIconChapter = styled(FooterButtonIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/chapters.svg`,
})``

export const FooterButtonIconProgress = styled(FooterButtonIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/progress.svg`,
})``

export const FooterButtonIconSetting = styled(FooterButtonIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/setting.svg`,
})``

export const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 1rem;
  width: 1rem;
  margin: -0.15rem !important;
`

export const BottomFunctionArea = styled.div`
  position: absolute;
  transition: all 0.5s;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  background: #121317;

  opacity: 1;
  .hidden > & {
    opacity: 0;
    pointer-events: none;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`

export const BottomFunctionAreaChapters = styled(BottomFunctionArea)`
  height: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - var(--footer-height) - 0.62rem);

  --main-background: #161616;
  --page-view-novel--chapters-list-item-background: #161616;
  --page-view-novel--chapters-list-item-color: #d2d2d2;
  --page-view-novel--chapters-list-item-active-color: #fff;
  --page-view-novel--chapters-list-title-color: white;
`

export const StyledNovelChapters = styled(Chapters)`
  flex: 1 1 auto;
`

export const BottomFunctionAreaProgress = styled(BottomFunctionArea)`
  height: 2rem;
  display: flex;
  gap: 0.4rem;
  padding: 0 0.4rem;
`

export const StyledLabeledSlider = styled(LabeledSlider)`
  flex: 1 1 auto;
`

export const BottomFunctionAreaText = styled.div`
  align-self: center;

  font-size: 0.35897rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.01026rem;
  color: #aaaaaa;
`

export const ButtonFunctionAreaProgressButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`

export const ButtonFunctionAreaProgressButtonIcon = styled.div`
  background: url(${process.env.PUBLIC_URL}/novel-icons/arrow-next.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 0.82051rem;
  height: 0.82051rem;
`

export const ButtonFunctionAreaProgressButtonIconPrev = styled(ButtonFunctionAreaProgressButtonIcon)`
  transform: rotate(180deg);
`

export const ControlOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`

export const StyledNovelPlayer = styled(NovelPlayer)`
  flex: 1 1 auto;
`

export const BottomFunctionAreaSetting = styled(BottomFunctionArea)`
  display: flex;
  flex-direction: column;
  gap: 0.25641rem;
  padding: 0.62rem 0;
`

export const BottomFunctionAreaSettingItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.20513rem;
  height: 0.82051rem;
  align-items: center;
`

export const BottomFunctionAreaSettingOptionName = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.35897rem;
  font-weight: 700;
  color: white;
`

export const BottomFunctionAreaSettingOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 2.87rem;
  font-size: 0.35897rem;
`

export const BottomFunctionAreaSettingIcon = styled(ColoredIcon)`
  width: 0.87521rem;
  height: 0.87521rem;
`

export const BottomFunctionAreaSettingIconBrightness = styled(BottomFunctionAreaSettingIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/brightness.svg`,
})``

export const BottomFunctionAreaSettingIconFontSize = styled(BottomFunctionAreaSettingIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/font-size.svg`,
})``

export const BottomFunctionAreaSettingIconDarkMode = styled(BottomFunctionAreaSettingIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/dark-mode.svg`,
})``

export const BottomFunctionAreaSettingFontSizeIcon = styled(ColoredIcon)`
  width: 0.82051rem;
  height: 0.82051rem;
`
export const BottomFunctionAreaSettingFontSizeUp = styled(BottomFunctionAreaSettingFontSizeIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/font-size-up.svg`,
})``

export const BottomFunctionAreaSettingFontSizeDown = styled(BottomFunctionAreaSettingFontSizeIcon).attrs({
  src: `${process.env.PUBLIC_URL}/novel-icons/font-size-down.svg`,
})``

export const BottomFunctionAreaSettingDarkMode = styled.div`
  width: 1.02564rem;
  height: 0.61538rem;
  border-radius: 0.05rem;
  border: 0.025rem solid transparent;

  &.active {
    border-color: var(--theme-color);
  }
`
export const BottomFunctionAreaSettingDarkModeLight = styled(BottomFunctionAreaSettingDarkMode)`
  background: #ffffff;
`
export const BottomFunctionAreaSettingDarkModeDark = styled(BottomFunctionAreaSettingDarkMode)`
  background: #2e2e2e;
`
export const SwitchChapterHintPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BrightnessOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  --opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, calc(var(--opacity) * 100%));
`
