import { forwardRef, useCallback, useRef, useState } from 'react'
import { FloatPromotionClose, FloatPromotionContainer, FloatPromotionImage } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'
import classNames from 'classnames'

const LAST_CLICK_ENV = 'last_click_promo'
// 7 days
const HIDE_FOR_MS = 1000 * 60 * 60 * 24 * 7

export default forwardRef(function FloatPromotion({ onClick: onClickExternal, className, hidden, ...props }, ref) {
  const now = useRef()
  const { navigateToShare } = useNavigateTo()
  if (now.current == null) {
    now.current = Date.now()
  }
  const [lastClick, setLastClick_] = useState(() => {
    const v = localStorage.getItem(LAST_CLICK_ENV)
    return v ? Number(v) : 0
  })
  const setLastClick = useCallback((v) => {
    localStorage.setItem(LAST_CLICK_ENV, String(v))
    setLastClick_(v)
  }, [])
  const shouldShow = now.current - lastClick > HIDE_FOR_MS

  const onClick = useCallback(
    (...args) => {
      setLastClick(Date.now())
      navigateToShare()
      onClickExternal?.(...args)
    },
    [navigateToShare, onClickExternal, setLastClick]
  )
  return shouldShow ? (
    <FloatPromotionContainer {...props} className={classNames(className, hidden ? 'hidden ' : '')} ref={ref}>
      <FloatPromotionImage onClick={onClick} />
      <FloatPromotionClose onClick={onClick} />
    </FloatPromotionContainer>
  ) : null
})
