import EmptyResult from 'component/EmptyResult'
import ComicBox from 'component/ComicBox'
import { useImmer } from 'use-immer'
import { fetchComicHistoryList } from 'api'
import { AreaPanelCmsGridWrapper, InfiniteScrollWrapper, PageScrollWrap } from 'commonStyledComponents'

const Comic = () => {
  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const handleFetchVideoList = async () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
    }

    try {
      const response = await fetchComicHistoryList(data)
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    } catch (error) {
      updateState((draft) => {
        draft.isLoading = false
      })
    }
  }

  return !state?.list?.length && !state.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollWrapper
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={state?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {state?.list?.length > 0 && (
          <AreaPanelCmsGridWrapper>
            {state?.list?.map((item, i) => (
              <ComicBox key={i} comicInfo={item} columns={3}>
                {i}
              </ComicBox>
            ))}
          </AreaPanelCmsGridWrapper>
        )}
      </InfiniteScrollWrapper>
    </PageScrollWrap>
  )
}

export default Comic
