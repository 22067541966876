export const selectTokenInfo = (state) => state.user.tokenInfo
/**
 * @returns {import("../../../types/api").SchemaMemberShow}
 */
export const selectUserInfo = (state) => state.user?.userInfo

export const selectUserName = (state) => state.user?.userInfo?.name

export const selectInviteCode = (state) => state.user?.inviteCode

export const selectPreference = (state) => state.user?.preference
