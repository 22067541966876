import NovelBox from 'component/NovelBox'
import { Wrapper } from './Styled'
import { memo } from 'react'

// 豎版兩個半向左滑動
function Template306({ videoData }) {
  return (
    // 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能
    <Wrapper className={'swiper-no-swiping'}>
      {videoData?.map((item, i) => {
        return <NovelBox overflow={true} key={i} novelInfo={item} columns={2.5} allowUnload />
      })}
    </Wrapper>
  )
}
export default memo(Template306)
