import AnimatedIcon from 'component/AnimatedIcon'
import VideoPlayer from 'component/VideoPlayer'
import styled from 'styled-components'

const Link = styled.button`
  width: calc(100% - calc(var(--cms-grid-column-gap) * 2));
  height: 1.23rem;
  margin: 0.41rem var(--cms-grid-column-gap);
  padding: 0;
  border-radius: 2.56rem;
  font-size: 0.41rem;
  font-weight: bold;
  color: white;
`

const TextStyle = styled.div`
  font-weight: bold;
  letter-spacing: 0.27px;
  color: var(--page-view-video--title-color);
  margin-top: 0.16rem;
  margin-bottom: 0.16rem;
  line-height: 1.1;
`

export const Wrapper = styled.div`
  width: 100%;
  /** 這邊是用在 Modal, 所以使用 height: 100% */
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconBack = styled.button`
  position: absolute;
  z-index: 999;
  margin-top: env(safe-area-inset-top);
  top: -0.1rem;
  left: -0.2rem;
  width: 1.23rem;
  height: 1.23rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/arrow-white.svg);
  background-size: 33% 33%;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: inherit;
  width: 100%;
  // margin-top: 0.426rem;
`

export const VideoInfo = styled.div`
  width: 100%;
  overflow: auto;
`

export const VideoName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.173rem;
`

export const VipLink = styled(Link)`
  background-image: url('${process.env.PUBLIC_URL}/banner/vip-banner.png');
  background-size: cover;
  background-position: center center;
`

export const PaidLink = styled(Link)`
  background-image: url('${process.env.PUBLIC_URL}/banner/paid-banner.png');
  background-size: cover;
  background-position: center center;
`

export const Text = styled(TextStyle)`
  padding: 0 var(--cms-grid-column-gap);
  font-size: 0.426rem;
`

export const Info = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  align-items: center;
  padding: 0 var(--cms-grid-column-gap);
  gap: 0.2rem;
  margin-top: 0.1rem;
  line-height: 0.45rem;
`

export const InfoItem = styled.div`
  font-size: 0.307rem;
  color: var(--page-view-video--info-color);
  display: inline;
  margin-right: 0.4rem;
  vertical-align: top;
`

export const Spacing = styled.div`
  height: 0.213rem;
`

export const ShortcutArea = styled.div`
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  gap: 0.1025rem;
  margin-top: 0.41rem;
  padding: 0 var(--cms-grid-column-gap);
`
export const Shortcut = styled.button`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  align-items: center;
  font-size: 0.35rem;
  width: 2.15rem;
`
export const ShortcutOrigin = styled(Shortcut)`
  width: auto;
  flex: 1 0 auto;
  min-width: 2.5rem;
  justify-content: space-between;
`
export const OriginList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 var(--cms-grid-column-gap);
  margin-top: 0.41rem;
  margin-top: 0.41rem;
  overflow-x: auto;
`
export const OriginItem = styled.button`
  flex: 0 0 auto;
  min-width: 1.92rem;
  height: 0.71rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.256rem;
  background: var(--page-view-video--source-background);
  color: var(--page-view-video--source-color);
  margin-right: 0.102rem;
  border-radius: 2.5rem;

  &.active {
    background: var(--page-view-video--active-source-background);
    color: var(--page-view-video--active-source-color);
  }
`

export const ShortcutIcon = styled.i.attrs((props) => {
  return {
    style: {
      background: `url(${process.env.PUBLIC_URL}/icon/${props.name}) center / contain no-repeat`,
    },
  }
})`
  margin-right: 5px;
  height: 0.6rem;
  width: 0.6rem;
  background-position: center;
`

export const ShortcutIconDropDown = styled(ShortcutIcon)`
  margin-left: 5px;
  margin-right: 0;
  height: 0.3rem;
  width: 0.3rem;
`

export const StyledVideoPlayer = styled(VideoPlayer)`
  margin-top: env(safe-area-inset-top);
`

export const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 0.6rem;
  width: 0.6rem;
  transform: scale(1.3);
  margin-right: 5px;
`

export const TagArea = styled.div`
  overflow-x: auto;
  margin-top: 0.5rem;
`
export const TagAreaInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  padding: 0 var(--cms-grid-column-gap);
  gap: var(--cms-grid-column-gap);
`
export const TagItem = styled.div`
  flex: 0 0 auto;
  background: var(--page-view-video--tag-background);
  color: var(--page-view-video--tag-color);
  padding: 0.05rem 0.25rem;
  border-radius: 0.1rem;
  font-size: 0.3rem;
  line-height: 130%;
`

export const InfoItemLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const ActorTag = styled(InfoItemLink)`
  margin-right: 0.2rem;
  /* white-space: nowrap; */
`
