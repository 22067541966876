import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react'
import {
  fetchGameBalanceAll,
  fetchGameHall,
  fetchGameListV1,
  fetchGameMarquee,
  // fetchGamePlatformList,
  fetchGameUserStatus,
} from 'api/index'

import {
  Wrapper,
  GameList,
  GoTop,
  LobbyTitle,
  PageWrapper,
  LobbyErrorMessage,
  GamePanelSide,
  GamePanelCenter,
  GamePanel,
  GameCategoryIcon,
  StyledEmptyResult,
  // TabsHeadWrap,
  StyledColoredIcon,
  GameListWrap,
  LoadingOverlay,
  StyledAnimation,
  GamePanelCenterTabGamesMono,
  // FixedTabsHeadOuterWrap,
  // FixedTabsHeadWrap,
  TransferButton,
  MarqueeRow,
} from './Styled'

import Jumbotron from './component/Jumbotron'
import BalanceBar from './component/BalanceBar'
import GameIcon from './component/GameIcon'
import Marquee from './component/Marquee'
import Loading from './component/Loading'
import { useOpenGamePopup } from 'hooks/useOpenGamePopup'
import { useToastAlert } from 'hooks/useToastAlert'
import UsePageVisible from 'page/Profile/hooks/usePageVisible'
import { PrimaryButton } from 'commonStyledComponents'
import { useLocation } from 'react-router-dom'
import { useNavigateTo } from 'hooks/useNavigateTo'
import useConfirmModal from 'hooks/useConfirmModal'
import { CONFIRM_MODAL_TYPE } from 'component/Modal/ConfirmModal'
import { useMutex } from 'hooks/useMutex'
import { GAME_CATEGORY_LIST, GAME_MESSAGE } from 'constant/game'
import { useDispatch, useSelector } from 'react-redux'
import { selectGameBalance, selectGameUserStatus } from 'redux/selector/game'
import actions from 'redux/action'
import { selectUserInfo } from 'redux/selector/user'
import { useGameBanCheck } from 'hooks/useGameBanCheck'

const subsets = GAME_CATEGORY_LIST

export default function GameLobby() {
  const [banner, setBanner] = useState([])
  const [marquee, setMarquee] = useState([])
  const [csList, setCsList] = useState([])
  const userInfo = useSelector(selectUserInfo)
  const [lobbyError, setLobbyError] = useState(false)
  const [lobbyErrorMessage, setLobbyErrorMessage] = useState('')
  const uid = useMemo(() => (userInfo ? formatUid(userInfo.show_id) : ''), [userInfo])
  const preferredCs = useMemo(() => csList.find((m) => m.url), [csList])
  const [scrollTopVisible, setScrollTopVisible] = useState(false)
  const { navigateToGameTransfer, navigateToGameDeposit, navigateToGameWithdraw } = useNavigateTo()
  const openGamePopup = useOpenGamePopup()
  // const closeGamePopup = useCloseGamePopup()
  const { toast } = useToastAlert()
  const { openConfirm, formatApiErrorMessage } = useConfirmModal()
  const [currentSubsetState, setCurrentSubsetState] = useState({
    manual: true,
    value: subsets[0].name,
  })
  const [visibleSubset, setVisibleSubset] = useState([])

  // const [platformList, setPlatformList] = useState(
  //   /** @type { Awaited<ReturnType<typeof fetchGamePlatformList>>['data'] } */ ([])
  // )

  const dispatch = useDispatch()
  const userStatus = useSelector(selectGameUserStatus)
  const balance = useSelector(selectGameBalance)

  const { isVisible } = UsePageVisible()
  const currentLocation = useLocation()
  const { wrap: customerServiceMutex } = useMutex()
  const { wrap: promotionMutex } = useMutex()
  const { validateUser } = useGameBanCheck(userStatus)

  let balanceLoadTime = useRef(0)

  // the following effect is used for fetching platform list, but we don't need platform list currently.
  // useEffect(() => {
  //   const controller = new AbortController()
  //   const signal = controller.signal
  //   fetchGamePlatformList({ signal }).then((res) => {
  //     setPlatformList(res.data)
  //     setCurrentPlatform(res.data[0].tp_code)
  //   })

  //   return () => {
  //     controller.abort()
  //   }
  // }, [])

  // const [currentPlatform, setCurrentPlatform] = useState(null)

  const [currentGameState, setCurrentGameState] = useState({
    params: null,
    requireFetching: true,
    loading: false,
    initialLoaded: false,
    current: 1,
    hasMore: true,
    games: [],
  })

  const [groupedGames, setGroupedGames] = useState([])

  const loadUserStatus = useCallback(async () => {
    try {
      const status = await fetchGameUserStatus()
      dispatch(actions.updateGameUserStatus(status))
    } catch (ex) {
      console.warn(ex)
    }
  }, [dispatch])

  const loadBalance = useCallback(
    async function loadBalance(onSuccess, onError) {
      let isOk = false
      try {
        if (Date.now() - balanceLoadTime.current < 500) return
        balanceLoadTime.current = Date.now()
        const data = await fetchGameBalanceAll()
        dispatch(actions.updateGameBalance(data?.total || 0))
        isOk = true
      } catch (ex) {
        if (typeof onError === 'function') {
          onError(ex)
        }
        openConfirm({
          message: formatApiErrorMessage(ex),
          type: CONFIRM_MODAL_TYPE.OK,
        })
        // ex?.message && toast(ex?.message)
        return
      }
      if (typeof onSuccess === 'function' && isOk) {
        onSuccess()
      }
    },
    [dispatch, formatApiErrorMessage, openConfirm]
  )

  const loadSystemInfo = useCallback(
    async function loadSystemInfo() {
      try {
        const data = await fetchGameMarquee()
        setBanner([...data.banner])
        setMarquee([...data.marquee])
        setCsList([...data.customerServiceUrl])
      } catch (ex) {
        openConfirm({
          message: formatApiErrorMessage(ex),
          type: CONFIRM_MODAL_TYPE.OK,
        })
        // ex?.message && toast(ex?.message)
      }
    },
    [formatApiErrorMessage, openConfirm]
  )

  const loadInfo = useCallback(async () => {
    // 順序會影響第一次進入遊戲的用戶註冊
    await loadSystemInfo()
    await loadUserStatus()
    validateUser()
    loadBalance()
  }, [loadSystemInfo, loadUserStatus, validateUser, loadBalance])

  const handleEnterGame = useCallback(
    function handleEnterGame(game) {
      if (validateUser()) {
        openGamePopup({
          id: game.gameId,
          gameType: game.gameType,
          tpCode: game.tpCode,
          direction: game.direction,
        })
      }
    },
    [openGamePopup, validateUser]
  )

  function toGameDeposit() {
    if (validateUser()) {
      navigateToGameDeposit({})
    }
  }

  function toGameWithdraw() {
    if (validateUser()) {
      navigateToGameWithdraw({})
    }
  }

  const toGamePromotion = promotionMutex(() => {
    toast(GAME_MESSAGE.NOT_RELEASE)
    // navigateToGamePromotion({})
    return new Promise((res) => {
      setTimeout(() => {
        res()
      }, 2000)
    })
  })

  function toGameTransfer() {
    if (validateUser()) {
      navigateToGameTransfer({})
    }
  }

  const toService = customerServiceMutex(() => {
    if (preferredCs) {
      window.open(preferredCs.url)
    } else {
      toast(GAME_MESSAGE.NOT_RELEASE)
      console.warn('no customer service available')
    }
    return new Promise((res) => {
      setTimeout(() => {
        res()
      }, 2000)
    })
  })

  function formatUid(uid) {
    if (!uid) return ''
    return uid.replace('-', '').toLowerCase()
  }

  useEffect(() => {
    if (isVisible) loadInfo()
  }, [loadInfo, isVisible])

  useEffect(() => {
    // navigate到本頁面時，重置balance
    if (currentLocation?.pathname === '/game') loadBalance()
  }, [currentLocation, loadBalance])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const load = async () => {
      if (!currentGameState.requireFetching) {
        return
      }

      if (!currentGameState.loading) {
        setCurrentGameState((state) => ({ ...state, loading: true }))
        return
      }

      setLobbyError(false)
      setLobbyErrorMessage('')

      try {
        // group
        const hall = await fetchGameHall()

        const filteredSubset = subsets.filter((m) =>
          hall.game_type.find((n) => !m.filter.gameType || m.filter.gameType === n)
        )
        setVisibleSubset(filteredSubset)
        if (filteredSubset.length && filteredSubset.find((m) => m.name !== currentSubsetState.name)) {
          setCurrentSubsetState({
            value: filteredSubset[0].name,
            manual: false,
          })
        }
        // list
        const listRes = await fetchGameListV1({
          pageSize: 1000,
          current: currentGameState.current,
        })
        if (signal.aborted) {
          return
        }

        const games = [...currentGameState.games, ...(listRes?.data ?? [])]
        setCurrentGameState((state) => ({
          ...state,
          loading: false,
          initialLoaded: true,
          hasMore: listRes?.page_result.pageSize * listRes?.page_result.current < listRes?.page_result.total,
          current: state.current + 1,
          requireFetching: false,
          games: games,
        }))

        const grouped = subsets.map((i) => ({
          name: i.name,
          icon: i.icon,
          games: games
            .filter((g) => {
              const rule = i.filter
              for (const [k, v] of Object.entries(rule)) {
                if (g[k] !== v) return false
              }
              return true
            })
            .map((m, i) => (
              <GameIcon
                key={'game_icon_' + i}
                icon={m.imgUrl}
                name={m.gameName}
                disabled={m.isUnderMaintain === 1}
                onClick={(e) => handleEnterGame(m)}
              ></GameIcon>
            )),
        }))
        setGroupedGames(grouped)
      } catch (ex) {
        if (signal.aborted) {
          return
        }
        openConfirm({
          message: formatApiErrorMessage(ex),
          type: CONFIRM_MODAL_TYPE.OK,
        })
        console.warn(ex)
        setLobbyError(true)
        setLobbyErrorMessage(ex?.message)
        setCurrentGameState((state) => ({
          ...state,
          requireFetching: false,
          initialLoaded: true,
          loading: false,
          hasMore: false,
        }))
      }
    }

    load()

    return () => {
      controller.abort()
    }
  }, [currentGameState, currentSubsetState.name, formatApiErrorMessage, handleEnterGame, openConfirm])

  const handleFetchGame = () => {
    setCurrentGameState((v) => {
      if (v.requireFetching) return v
      return {
        ...v,
        requireFetching: true,
      }
    })
  }

  // create a platform options
  // const platformOptions = useMemo(() => {
  //   return platformList.map((i) => ({ id: i.tp_code, name: i.name }))
  // }, [platformList])

  // const showPlatformOptions = useMemo(() => platformOptions.length > 1, [platformOptions])

  const pageRef = useRef(null)
  const tabHeadAnchorRef = useRef(null)
  const tabsHeadRef = useRef(null)

  const [maxSidebarHeight, setMaxSidebarHeight] = useState(null)

  useEffect(() => {
    if (pageRef.current == null || tabsHeadRef.current == null) {
      return
    }
    /**
     * @type {HTMLDivElement}
     */
    const pageEl = pageRef.current
    /**
     * @type {HTMLDivElement}
     */
    const tabsEl = tabsHeadRef.current
    const obs = new ResizeObserver(() => {
      const wrapperSize = pageEl.getBoundingClientRect()
      const headSize = tabsEl.getBoundingClientRect()
      setMaxSidebarHeight(wrapperSize.height - headSize.height)
    })
    obs.observe(pageRef.current)

    return () => {
      obs.disconnect()
    }
  }, [])

  const [fakeLoadingState, setFakeLoadingState] = useState(/** @type {'init' | 'running'} */ ('init'))

  const resetScroll = () => {
    const initial = pageRef.current.scrollTop
    const target = tabHeadAnchorRef.current.offsetTop - pageRef.current.offsetTop

    if (initial > target) {
      pageRef.current.scrollTop = target
    }
  }

  const onChangeSubset = (s) => {
    setFakeLoadingState('running')
    setCurrentSubsetState({
      value: s,
      manual: false,
    })
    resetScroll()
  }

  function handleToTop() {
    pageRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    if (fakeLoadingState === 'init') return

    const id = setTimeout(() => {
      setFakeLoadingState('init')
    }, 200)

    return () => clearTimeout(id)
  }, [fakeLoadingState])

  // const [mainPanelDocked, setMainPanelDocked] = useState()

  const onPageScroll = () => {
    const anchor = pageRef.current.scrollTop
    // const target = tabHeadAnchorRef.current.offsetTop - pageRef.current.offsetTop

    // if (anchor >= target) {
    //   setMainPanelDocked(true)
    // } else {
    //   setMainPanelDocked(false)
    // }
    if (anchor > 100) setScrollTopVisible(true)
    else setScrollTopVisible(false)
  }

  return (
    <PageWrapper>
      <LobbyTitle>棋牌游戏</LobbyTitle>
      <Wrapper>
        {banner?.length > 0 && <Jumbotron list={banner}></Jumbotron>}
        <MarqueeRow>
          <Marquee list={marquee} speed={60}></Marquee>
          <TransferButton onClick={toGameTransfer}>
            <div>额度转换</div>
          </TransferButton>
        </MarqueeRow>
        <BalanceBar
          balance={balance}
          uid={uid}
          onRefresh={loadBalance}
          toGameDeposit={toGameDeposit}
          toGameWithdraw={toGameWithdraw}
          toGamePromotion={toGamePromotion}
          toService={toService}
        ></BalanceBar>
        <div ref={tabHeadAnchorRef} style={/* workaround for leaking BalanceBar  */ { marginTop: '1px' }}></div>
        <GamePanel>
          <GamePanelSide
            style={{
              maxHeight: maxSidebarHeight ? `${maxSidebarHeight}px` : undefined,
              // overflowY: mainPanelDocked ? 'auto' : 'hidden',
              // top: showPlatformOptions ? 'var(--tab-bar-height)' : '0',
            }}
          >
            {visibleSubset.map((i, index) => {
              return (
                <GameCategoryIcon
                  key={'category_icon_' + index}
                  active={currentSubsetState.value === i.name}
                  onClick={() => onChangeSubset(i.name)}
                  data-subset-icon={i.name}
                >
                  <StyledColoredIcon src={i.icon} />
                  {i.name}
                </GameCategoryIcon>
              )
            })}
          </GamePanelSide>
          <GamePanelCenter ref={pageRef} onScroll={onPageScroll}>
            {lobbyError ? (
              <LobbyErrorMessage>
                <div>载入失败</div>
                <div>{lobbyErrorMessage}</div>
                <PrimaryButton onClick={handleFetchGame}>重试</PrimaryButton>
              </LobbyErrorMessage>
            ) : (
              <GamePanelCenterTabGamesMono>
                <React.Fragment key={'game_list'}>
                  {fakeLoadingState === 'init' &&
                    groupedGames.map((s) =>
                      s.name === currentSubsetState.value ? (
                        <GameListWrap key={s.name} style={{ opacity: fakeLoadingState === 'running' ? '0' : '1' }}>
                          {s.games.length !== 0 ? (
                            <GameList data-name={s.name}>{s.games}</GameList>
                          ) : (
                            <StyledEmptyResult></StyledEmptyResult>
                          )}
                        </GameListWrap>
                      ) : null
                    )}

                  {fakeLoadingState === 'running' && (
                    <GameListWrap>
                      <LoadingOverlay>
                        <StyledAnimation />
                      </LoadingOverlay>
                    </GameListWrap>
                  )}
                </React.Fragment>
              </GamePanelCenterTabGamesMono>
            )}
            <GoTop onClick={handleToTop} className={scrollTopVisible ? 'visible' : ''}></GoTop>
            {!currentGameState.initialLoaded ? (
              <Loading active={!currentGameState.initialLoaded}>加载中</Loading>
            ) : (
              <></>
            )}
          </GamePanelCenter>
        </GamePanel>
      </Wrapper>
    </PageWrapper>
  )
}
