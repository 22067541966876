import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  // gap為4px, 因為一行兩個, 會有一個 gap , 所以是 4px/2
  width: calc((100% / 3) - (calc(var(--category-grid-gap) * 2) / 3));
  border-radius: 6px;
  overflow: hidden;

  // image animation fix
  transform: translateZ(0);

  > span {
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0.2051rem;
    left: 0;
    text-align: center;
    text-shadow: 0px 0px 3.05451px #000000;
    letter-spacing: 0.38px;
    font-size: 0.2564rem;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
