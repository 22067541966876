import VideoLargeBox from 'component/VideoLargeBox'

import { Wrapper } from './Styled'
import { CmsGridWrapper } from 'commonStyledComponents'
import { memo } from 'react'

/**
 * 模板 ID: "13": 橫版一大兩小半向左滑動
 * */
function Template13({ videoData }) {
  return videoData[0] ? (
    <>
      <CmsGridWrapper>
        <VideoLargeBox videoInfo={videoData[0]} />
      </CmsGridWrapper>
      {/* 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能 */}
      <Wrapper className={'swiper-no-swiping'}>
        {videoData.slice(1)?.map((item, i) => {
          return <VideoLargeBox key={i} videoInfo={item} width="4rem" allowUnload />
        })}
      </Wrapper>
    </>
  ) : null
}
export default memo(Template13)
