import styled from 'styled-components'

export const TextContainer = styled.p`
  /* --line-count: 3; */
  color: var(--page-view-novel--desc-color);
  background: var(--background-color);

  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: clip;

  position: relative;
`

export const OverflowLabel = styled.span`
  display: block;
  position: absolute;

  transform: translateY(-50%);
  white-space: nowrap;
  background: var(--background-color);
  font-weight: bold;
`

export const OverflowLabelDecor = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
  width: 1em;
  background: linear-gradient(to left, var(--background-color), transparent);
`
