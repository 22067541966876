import { fetchBinFile } from 'api'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectComicOrigin, selectNovelOrigin, selectPictureOrigin, selectPreviewOrigin } from 'redux/selector/app'
import { imageParser } from 'utils/imageParser'
import { Image, ImageWrapper, NonLazyLoadWrapper } from './Styled'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function Base64Image({
  src = '',
  noLazy = false,
  aspectRatio = 1.414,
  onImageLoad,
  // some component want actual image, so we emit both
  onBase64ImageLoad,
  overflow,
  // absolute placeholder path from public/, example: `/icon/image.png`
  placeholder = null,
  // preview, picture, comic
  source = /** @type {'preview' | 'comic' | 'novel' | 'picture' } */ ('preview'),
  allowUnload = false,
  rootClassName = 'lazy-root',
  rootMargin = '100px',
  imageStyle,
  hidePlaceholderOnLoaded = false,
  ...props
}) {
  const previewOrigin = useSelector(selectPreviewOrigin)
  const pictureOrigin = useSelector(selectPictureOrigin)
  const comicOrigin = useSelector(selectComicOrigin)
  const novelOrigin = useSelector(selectNovelOrigin)
  const currentOrigin =
    source === 'preview'
      ? previewOrigin
      : source === 'comic'
      ? comicOrigin
      : source === 'novel'
      ? novelOrigin
      : pictureOrigin

  const { ref, visible } = useIntersectionObserver({
    /* Optional options */
    defaultVisible: false,
    threshold: 0,
    triggerOnce: !allowUnload,
    rootClassName,
    rootMargin,
  })

  const [base64Img, setBase64Img] = useState(/** @type {string|null} */ (null))
  const [blobUrl, setBlobUrl] = useState(/** @type {string|null} */ (null))
  const [animationFinished, setAnimationFinished] = useState(false)
  const [hasPlaceholder, setHasPlaceholder] = useState(true)
  const fetchedSrc = useRef(/** @type {string|null} */ (null))
  const parsedBase64Src = useRef(/** @type {string|null} */ (null))

  const handleSetPlaceholderVisible = useCallback(
    (v) => {
      // 部分透明底圖片需要隱藏佔位圖
      if (hidePlaceholderOnLoaded) {
        setHasPlaceholder(v)
      }
    },
    [hidePlaceholderOnLoaded]
  )

  useEffect(() => {
    if (!currentOrigin) return

    if (src === '') {
      fetchedSrc.current = null
      setBase64Img(null)
      return
    }

    if (noLazy || visible) {
      if (fetchedSrc.current !== src) {
        const controller = new AbortController()

        fetchBinFile({
          url: currentOrigin.replace(/\/?$/, '/')?.concat(src.replace(/^\//, '')),
          signal: controller.signal,
        })
          .then((textBase64) => {
            fetchedSrc.current = src
            setBase64Img(textBase64)
          })
          .catch(catchPromiseCancel)

        return () => {
          controller.abort()
        }
      }
    } else {
      fetchedSrc.current = null
      setBase64Img(null)
      setAnimationFinished(false)
    }
  }, [visible, noLazy, currentOrigin, src])

  useEffect(() => {
    parsedBase64Src.current = base64Img
    if (base64Img == null) {
      setBlobUrl(null)
    } else {
      try {
        const url = imageParser(base64Img)
        setBlobUrl(url)

        return () => {
          URL.revokeObjectURL(url)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [base64Img])

  useEffect(() => {
    if (base64Img) {
      if (fetchedSrc.current === src) {
        onBase64ImageLoad?.(base64Img)
      }
    }
  }, [base64Img, onBase64ImageLoad, src])

  useEffect(() => {
    if (blobUrl) {
      if (parsedBase64Src.current === base64Img) {
        onImageLoad?.(blobUrl)
      }
    }
  }, [blobUrl, onImageLoad, base64Img])

  return (
    <ImageWrapper
      {...props}
      ref={ref}
      aspectRatio={aspectRatio}
      hasPlaceholder={hasPlaceholder}
      placeholder={placeholder}
      animation={!animationFinished}
    >
      <NonLazyLoadWrapper>
        {blobUrl && (
          <Image
            style={imageStyle}
            src={blobUrl}
            animation={!animationFinished}
            onAnimationEnd={() => setAnimationFinished(true)}
            onLoad={() => handleSetPlaceholderVisible(false)}
          />
        )}
      </NonLazyLoadWrapper>
    </ImageWrapper>
  )
}
