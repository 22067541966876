//
import useOpenModal from './hooks/useOpenModal'
//
//
import { ShortcutJumpType } from '../../constant'
import { Wrapper, ShortcutImage, ShortcutName, ShortcutNameText } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { fetchPostAdEvent } from 'api'
import { formatDate } from 'utils/timeFormat'
import { useSelector } from 'react-redux'
import { selectGameUserStatus } from 'redux/selector/game'
import { useGameBanCheck } from 'hooks/useGameBanCheck'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useOpenGamePopup } from 'hooks/useOpenGamePopup'
import { selectNavigationContent } from 'redux/selector/app'
import { useNavigationLocalData } from 'component/Navigation/hooks/useNavigationLocalData'
import { report } from 'hooks/useAnalytics'

/**
 * 元件 - 金剛區選項(快捷鍵)
 * @param {{ info: import('../../../../../types/api').SchemaCmsIconuiShow }} param0
 * @returns {import('react').ReactNode}
 */
export default function Shortcut({ info, autoWidth, avatarWidth, avatarHeight }) {
  const { openActorListModal, openProducerListModal, openEnableVipModal, openViewMoreModal } = useOpenModal()
  const { navigateToProducer, navigateToActor } = useNavigateTo()
  const navigate = useAppNavigate()

  // game
  const gameUserStatus = useSelector(selectGameUserStatus)
  const { userCanEnter } = useGameBanCheck(gameUserStatus)
  const openGamePopup = useOpenGamePopup()

  // nav
  const navigationContent = useSelector(selectNavigationContent)
  const localData = useNavigationLocalData()

  const jumpToInternalLink = () => {
    /**
     *   jump_type  integer
     *   跳轉類型(1:片商, 2:演員, 3:片商列表, 4:演員列表, 5:開通VIP, 6: 鏈結, 7: NAV, 8: 遊戲)
     */
    const {
      jump_type,
      video_actor_id,
      video_producer_id,
      name,
      game_platform,
      game_type_id,
      direction,
      internal_id,
      outside_url,
    } = info

    switch (jump_type) {
      case ShortcutJumpType.Producer:
        // 如果是特定片商，直接使用Producer頁
        navigateToProducer({
          producerId: video_producer_id,
          // producerImage: info?.icon_path,
          // producerName: name,
          // producerImageSource: 'picture',
        })
        break
      case ShortcutJumpType.Actor:
        navigateToActor({ actorId: video_actor_id })
        break
      case ShortcutJumpType.ProducerList:
        openProducerListModal()
        break
      case ShortcutJumpType.ActorList:
        openActorListModal({ video_actor_id })
        break
      case ShortcutJumpType.EnabledVIP:
        openEnableVipModal()
        break
      case ShortcutJumpType.Link:
        if (outside_url) {
          window.open(outside_url, '_blank')
        }
        break
      case ShortcutJumpType.Game:
        if (!userCanEnter) {
          // user banned, redirect to game lobby
          navigate('/game')
          return
        }
        openGamePopup({
          id: internal_id,
          ...(game_platform
            ? {
                gameType: game_type_id,
                tpCode: game_platform,
                direction: direction,
              }
            : {}),
        })
        break
      case ShortcutJumpType.Nav:
        const index = navigationContent.findIndex((i) => i.id === internal_id)

        if (index < 0) {
          return
        }

        const target = localData.navContent[index]

        navigate(target.navLink + target.search, { replace: true, forceReplaceNonCurrentPage: true })
        break
      default:
        break
    }
  }

  function logClickEvent(info) {
    try {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: 10000,
        material_name: info.name,
        oholo_site_id: 10000, // 固定值
        oholoer_id: info.oholoer_id,
      })
    } catch (ex) {
      console.warn('Error at log click event', ex)
    }
  }

  const chooseRedirectFunction = () => {
    report({
      SerialNumber: 8,
      Event: 'cms_click',
      Trigger: '點擊金剛區',
      Parameters: 'icon_ui_id',
      Value: info.id,
    })

    const { jump_method, outside_url } = info

    switch (jump_method) {
      /** 跳轉內部鏈接 */
      case 1:
        jumpToInternalLink()
        break
      /** 跳轉外部鏈接 */
      case 2:
        logClickEvent(info)
        window.open(outside_url)
        break
      default:
        break
    }
  }

  /**
   * 只取前五個字
   * @param {*} text 原始文字
   * @returns 前五個字
   */
  const takeFive = (text) => {
    if (typeof text != 'string') text = '' + text
    return text.substring(0, 5)
  }

  return (
    <Wrapper autoWidth={autoWidth} onClick={chooseRedirectFunction}>
      <ShortcutImage src={info?.icon_path} width={avatarWidth} height={avatarHeight} allowUnload />

      <ShortcutName>
        <ShortcutNameText>{takeFive(info.name)}</ShortcutNameText>
      </ShortcutName>
    </Wrapper>
  )
}
