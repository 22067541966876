import { combineReducers } from '@reduxjs/toolkit'
import app from 'redux/slice/app'
import user from 'redux/slice/user'
import modal from 'redux/slice/modal'
import timer from 'redux/slice/timer'
import deposit from 'redux/slice/deposit'
import favourite from 'redux/slice/favourite'
import collection from 'redux/slice/collection'
import shortFavourite from 'redux/slice/shortFavourite'
import shortCollection from 'redux/slice/shortCollection'
import comicCollection from 'redux/slice/comicCollection'
import novelCollection from 'redux/slice/novelCollection'
import history from 'redux/slice/history'
import game from 'redux/slice/game'

const reducers = combineReducers({
  app,
  user,
  modal,
  timer,
  deposit,
  favourite,
  collection,
  shortFavourite,
  shortCollection,
  comicCollection,
  novelCollection,
  history,
  game,
})

export default reducers
