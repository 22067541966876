// 短視頻 左右滑動
import VideoBox from 'component/VideoBoxVertical'
import { memo, useCallback, useMemo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Wrapper } from './Styled'
import EffectFlat from 'swiperEffects/cardFlat'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useDuplicatedArray } from 'hooks/useDuplicatedArray'
import { AreaBoxSwiperTemplateWrapper, MaskedBase64Background } from '../Styled'

function Template104({ videoData: videoDataProp }) {
  const videoData = useDuplicatedArray(videoDataProp, 5)

  const shortInfo = useMemo(
    () => ({
      list: videoData,
    }),
    [videoData]
  )

  const [currentSlide, setCurrentSlide] = useState(0)

  /** @type {import("../../../../../types/api").SchemaShortVideoShow} */
  const currentItem = videoData[currentSlide]

  const currentImage = currentItem?.coverphoto_v

  const onSlideChange = useCallback((swiper) => {
    setCurrentSlide(swiper.realIndex)
  }, [])

  const { ref, visible } = useIntersectionObserver({
    /* Optional options */
    defaultVisible: false,
    threshold: 0,
    triggerOnce: false,
  })

  return (
    <AreaBoxSwiperTemplateWrapper>
      <Wrapper ref={ref}>
        <MaskedBase64Background className={'swiper-no-swiping'} src={currentImage} noLazy={visible} allowUnload />
        <Swiper
          centeredSlides={true}
          spaceBetween={30}
          slidesPerView={2}
          effect="flat"
          onSlideChange={onSlideChange}
          modules={[EffectFlat]}
          flatEffect={{
            depth: 300,
          }}
          loop={true}
          nested={true}
        >
          {videoData?.map((item, i) => {
            return (
              <SwiperSlide key={i} style={{ width: '4.7rem' }}>
                <VideoBox
                  overflow={true}
                  textAlign="center"
                  videoInfo={item}
                  width="auto"
                  shortInfo={shortInfo}
                  noLazy={visible}
                  allowUnload
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Wrapper>
    </AreaBoxSwiperTemplateWrapper>
  )
}

export default memo(Template104)
