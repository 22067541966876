import Video from './components/Video'
import Short from './components/Short'
import PageTabs from 'component/PageTabs'
import { useContentTab } from 'hooks/useContentTab'

export default function BrowseFavourite({ ...props }) {
  const { tabs, tabContents } = useContentTab({ video: Video, short: Short }, { filter: [1, 2] })
  return (
    <PageTabs {...props} title="我的喜欢" tabs={tabs}>
      {tabContents}
    </PageTabs>
  )
}
