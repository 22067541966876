import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div.attrs((props) => {
  return {
    style: {
      width: props?.autoWidth ? 'auto' : '1.6rem',
    },
  }
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.35897rem;
  color: var(--shortcut-color, #fff);
  cursor: pointer;
  user-select: none;
`

export const ShortcutImage = styled(Base64Image).attrs(({ width, ...props }) => {
  return {
    ...props,
    placeholder: '/loading/quick-access.svg',
    aspectRatio: 1,
    source: 'picture',
    style: {
      width: width || '1.6rem',
    },
  }
})`
  border-radius: 50%;
  margin-bottom: 16px;
`

export const ShortcutName = styled.div`
  position: relative;
  white-space: nowrap;
  // 文字可能重疊，規格表明設定最多5個字避免重疊
  /* overflow-x: visible; */
  /* overflow-y: hidden; */
  width: fit-content;
  width: 100%;
  min-height: 0.4rem;

  ::-webkit-scrollbar {
    width: 0.026rem;
    cursor: pointer;
  }
  /* scroll bar 底部背景 */
  ::-webkit-scrollbar-track {
    background: transparent;
    cursor: pointer;
  }

  /* scroll bar 滑動本身 */
  ::-webkit-scrollbar-thumb {
    //background: #c238dc;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    //background: #9B23AA;
    cursor: pointer;
  }
`

export const ShortcutNameText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`
