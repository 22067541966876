import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--cms-grid-column-gap);
`

export const Area = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background: var(--app-search-field--background);
  border-radius: 2.5rem;
  color: var(--app-search-field--placeholder-color);
  font-size: 0.358rem;
  height: 0.948rem;
  padding: 0 0.307rem;
  justify-content: space-between;
  white-space: nowrap;
`

export const IconSearch = styled.button`
  width: 0.538rem;
  height: 0.538rem;
  mask-image: url('${process.env.PUBLIC_URL}/icon/search.svg');
  mask-size: contain;
  mask-position: center;
  background: var(--component-header--search-icon);
`

export const ButtonFilter = styled.button`
  white-space: nowrap;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  height: 0.9487rem;
  font-size: 0.358rem;
  line-height: 130%;
  color: var(--component-header--filter-color);
  background: var(--component-header--filter-background);
  border: var(--component-header--filter-border);
  margin-left: 8px;
  border-radius: 2.5rem;
  padding: 0 0.41rem;
`

export const IconPlace = styled.div`
  width: 1.125rem;
`

export const IconBrand = styled.img`
  position: absolute;
  width: var(--app-header-bar--logo-size);
  height: var(--app-header-bar--logo-size);
  object-fit: contain;
`

export const IconFilter = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/filter.svg`,
})`
  margin-right: 0.143rem;
  width: 0.41rem;
  height: 0.41rem;
`
