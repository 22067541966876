import { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import shuffle from 'lodash/shuffle'
//
import { selectNavigationContent } from 'redux/selector/app'
//
import {
  ENABLED,
  NAV_ALIGN_CENTER,
  NAV_ALIGN_LEFT,
  NAV_ALIGN_RIGHT,
  NAV_TYPE_NORMAL,
  SECTION_HF_TYPE_FOOTER,
  SECTION_HF_TYPE_HEADER,
  TRUE,
} from 'constant/common'

import useEvents from './hooks/useEvents'
import { Wrapper, TabsContentWrapper, TabsTitleWrapper } from './Styled'
import Header from 'component/Header'
import { HeaderWrap } from 'commonStyledComponents'
import { CmsTab } from './component/CmsTab'
import { useAppLocation } from 'app-layered-layout/useAppLocation'
import FloatPromotion from 'component/FloatPromotion'
import { useResizeObserver } from 'hooks/useResizeObsever'
import { report } from 'hooks/useAnalytics'
// import { forceCheck } from "react-lazyload";

const TITLE_ALIGN_MAP = {
  [NAV_ALIGN_LEFT]: 'left',
  [NAV_ALIGN_CENTER]: 'center',
  [NAV_ALIGN_RIGHT]: 'right',
}

const HIDE_PROMO_BOX_AFTER_TAB_CHANGE_FOR_MS = 2000

export default function CmsView() {
  const location = useAppLocation()
  const [thumbsSwiper] = useState(null)

  /** 所有Nav頁面資料 */
  /** @type {import('../../../types/api').SchemaCmsPublishResults} */
  const navsInfo = useSelector(selectNavigationContent)

  const navId = location.search ? /id=(\w+)/.exec(location.search)?.[1] ?? null : null
  const currentNavIndex = navId
    ? navsInfo.findIndex((i) => i.id === navId)
    : navsInfo.findIndex((i) => i.type === NAV_TYPE_NORMAL) ?? 0

  /** 當前 Nav 內的所有頻道 */
  const tabsInfo = useMemo(() => {
    return navsInfo?.[currentNavIndex]?.tabs || []
  }, [navsInfo, currentNavIndex])

  const hideHeader =
    navsInfo?.[currentNavIndex]?.hide_head != null ? navsInfo?.[currentNavIndex]?.hide_head === ENABLED : false

  const { activeTabIndex, setActiveTabIndex } = useEvents()
  const tabContentRef = useRef()
  // const swiperSlide = useSwiperSlide();

  const jumpTo = useRef(null)

  const handleTabSwitch = useCallback((activeIndex) => {
    jumpTo.current = activeIndex
    tabContentRef?.current?.swiper.slideTo(activeIndex)

    const activeElement = document.getElementsByClassName('tab-title active')?.[0]
    activeElement?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }, [])

  const onSlideChange = useCallback(
    (swiper) => {
      if (activeTabIndex !== swiper.activeIndex) {
        if (jumpTo.current != null) {
          if (swiper.activeIndex === jumpTo.current) {
            report({
              SerialNumber: 4,
              Event: 'cms_click',
              Trigger: '點擊頻道',
              Parameters: 'channel_id',
              Value: tabsInfo[swiper.activeIndex].id,
            })
            jumpTo.current = null
          }
        } else {
          report({
            SerialNumber: 5,
            Event: 'cms_swipe',
            Trigger: '滑動選擇頻道',
            Parameters: 'channel_id',
            Value: tabsInfo[swiper.activeIndex].id,
          })
        }
        setActiveTabIndex(swiper.activeIndex)
      }
    },
    [activeTabIndex, setActiveTabIndex, tabsInfo]
  )

  const titleAlign = navsInfo?.[currentNavIndex]?.align ?? NAV_ALIGN_LEFT

  useEffect(() => {
    setActiveTabIndex(0)
  }, [currentNavIndex, setActiveTabIndex])

  /** @typedef {import("../../../types/api").SchemaCmsTabDetail} SchemaCmsTabDetail */
  /**
   * tab 隨機排序的 cache
   * @type {React.MutableRefObject<Map<SchemaCmsTabDetail, SchemaCmsTabDetail>>  }>}
   */
  const shuffledAreaData = useRef(new Map())

  /**
   * @param {import('../../../types/api').SchemaCmsSectionShow} section
   * @returns {'top' | 'normal' | 'bottom'}
   */
  const getShuffleType = (section) => {
    if (!section.hf_type) {
      if (section.top_seqno > 0) {
        return 'top'
      } else {
        return 'normal'
      }
    }

    if (section.hf_type === SECTION_HF_TYPE_HEADER) {
      return 'top'
    }

    if (section.hf_type === SECTION_HF_TYPE_FOOTER) {
      return 'bottom'
    }

    return 'normal'
  }

  /**
   * 取得對應該 tab 的隨機排序，沒有的話就生成一個
   * @param {SchemaCmsTabDetail} original
   */
  const getShuffledArea = (original) => {
    if (shuffledAreaData.current.get(original)) {
      return shuffledAreaData.current.get(original)
    }

    // seqno > 0 按seqno由大到小排序放置於前排
    const topList = original.sections
      .filter((e) => getShuffleType(e) === 'top')
      .sort((a, b) => b.top_seqno - a.top_seqno)

    const doShuffleList = original.sections.filter((e) => getShuffleType(e) === 'normal')
    const shuffledSection = shuffle(doShuffleList || [])

    const bottomList = original.sections
      .filter((e) => getShuffleType(e) === 'bottom')
      .sort((a, b) => b.top_seqno - a.top_seqno)

    const shuffled = {
      ...original,
      sections: [...topList, ...shuffledSection, ...bottomList],
    }

    shuffledAreaData.current.set(original, shuffled)
    return shuffled
  }

  /** 在變更 nav 時清空記憶下來的隨機排序 */
  useEffect(() => {
    shuffledAreaData.current = new Map()
  }, [currentNavIndex])

  const [tabRefreshKeys, setTabRefreshKeys] = useState({})

  const getKeyOfTab = useCallback((nav, tab) => `${nav}_${tab}`, [])

  const getKeySuffix = useCallback(
    (nav, tab) => {
      const existingKey = tabRefreshKeys[getKeyOfTab(nav, tab)]
      return existingKey ? `_${existingKey}` : ''
    },
    [getKeyOfTab, tabRefreshKeys]
  )

  const onRefresh = useCallback(
    async (tabIndex) => {
      const tabToRefresh = tabsInfo[tabIndex]
      shuffledAreaData.current.delete(tabToRefresh)
      setTabRefreshKeys((oldKeys) => ({
        ...oldKeys,
        [getKeyOfTab(currentNavIndex, tabIndex)]: Math.random().toString(16).slice(2),
      }))
    },
    [currentNavIndex, getKeyOfTab, tabsInfo]
  )

  const [shortcutPositions, setShortcutPositions] = useState({})

  const onPositionChange = useCallback((index, box) => {
    setShortcutPositions((old) => ({ ...old, [index]: box }))
  }, [])

  const [floatPromoBox, setFloatPromoBox] = useState(null)
  const floatPromoRef = useRef(undefined)
  const onFloatPromoRefed = useCallback((el) => {
    floatPromoRef.current = el
    setFloatPromoBox(el?.getBoundingClientRect())
  }, [])

  const resizeRef = useResizeObserver({
    onResize() {
      setFloatPromoBox(floatPromoRef.current?.getBoundingClientRect())
    },
  })

  const overlayWithPromoBox = (index) => {
    let overlay = false
    const nextActiveBox = shortcutPositions[index]
    if (nextActiveBox && floatPromoBox) {
      if (floatPromoBox.top < nextActiveBox.bottom) {
        overlay = true
      }
    }
    return overlay
  }

  const [hideUntil, setHideUntil] = useState(null)
  const [delayedActiveTabIndex, setDelayedActiveTabIndex] = useState(null)

  useEffect(() => {
    const now = Date.now()
    if (hideUntil != null) {
      if (hideUntil > now) {
        const id = setTimeout(() => {
          setHideUntil(null)
        }, hideUntil - now)
        return () => {
          clearTimeout(id)
        }
      } else {
        setHideUntil(null)
      }
    }
  }, [hideUntil])

  useEffect(() => {
    if (delayedActiveTabIndex !== activeTabIndex) {
      setHideUntil(Date.now() + HIDE_PROMO_BOX_AFTER_TAB_CHANGE_FOR_MS)
      setDelayedActiveTabIndex(activeTabIndex)
    }
  }, [activeTabIndex, delayedActiveTabIndex])

  let promoHidden = hideUntil != null || overlayWithPromoBox(delayedActiveTabIndex) || floatPromoBox == null

  return (
    <>
      <Wrapper ref={resizeRef}>
        <HeaderWrap>
          {!hideHeader && <Header searchTypes={navsInfo?.[currentNavIndex]?.search_types ?? []} />}

          <TabsTitleWrapper
            align={TITLE_ALIGN_MAP[titleAlign]}
            options={tabsInfo?.map((tabInfo, tabIndex) => ({ id: tabIndex, name: tabInfo?.name }))}
            value={activeTabIndex}
            onChange={(id) => handleTabSwitch(id)}
          />
        </HeaderWrap>
        <TabsContentWrapper
          thumbsSwiper={thumbsSwiper}
          tabsInfo={tabsInfo}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        >
          <Swiper
            key={`nav-${currentNavIndex}`}
            ref={tabContentRef}
            className="navContentWrapper"
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Thumbs]}
            onSlideChange={onSlideChange}
            cssMode
          >
            {tabsInfo?.map((tabInfo, tabIndex) => {
              /** 頻道裡的所有區域資料 及 該頻道是否開啟動態排序 - 頻道設為動態排序時, 以隨機方式顯示所擁有的區域 */
              const isAreaRandom = tabInfo.is_random === TRUE
              const areasInfo = isAreaRandom ? getShuffledArea(tabInfo).sections : tabInfo?.sections

              return (
                <SwiperSlide key={`Slide_${currentNavIndex}_${tabIndex}`} virtualIndex={tabIndex}>
                  {({ isActive }) => (
                    /* WORKAROUND: swiper js 會監聽 img onload 是件並且 reset 進度導致 lazy load 載入的圖片 onload 時顯示異常 */
                    <CmsTab
                      tabInfo={tabInfo}
                      areasInfo={areasInfo}
                      isActive={isActive}
                      onRefresh={() => onRefresh(tabIndex)}
                      contentKey={`${getKeySuffix(currentNavIndex, tabIndex)}`}
                      onShortcutMove={(box) => onPositionChange(tabIndex, box)}
                      displayAreaBox={Math.abs(tabIndex - activeTabIndex) < 1}
                    />
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
          {currentNavIndex === 0 && <FloatPromotion ref={onFloatPromoRefed} hidden={promoHidden} />}
        </TabsContentWrapper>
      </Wrapper>
    </>
  )
}
