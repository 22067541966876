import { TabContentWrapper } from 'page/CmsView/Styled'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Carousel from '../Carousel'
import ShortcutBox from 'component/ShortcutBox'
import { HeaderContents, StyledAnimation, TabOverLay, TabWrapper } from './Style'
import PullToRefresh from 'react-simple-pull-to-refresh'
import PagePullLoad from 'component/Loading/PagePullLoad'
import { useAscenderScrollEvent } from 'hooks/useAscenderScrollEvent'
import { useEvent } from 'hooks/useEvent'
import { useResizeObserver } from 'hooks/useResizeObsever'
import { AreaInfo } from '../AreaInfo'
// const LOAD_DELAY = 500
export const CmsTab = memo(
  ({
    tabInfo,
    areasInfo,
    isActive = false,
    onRefresh: onRefreshParent,
    contentKey,
    onShortcutMove,
    displayAreaBox,
  }) => {
    const onShortcutMoveEvent = useEvent(onShortcutMove)

    const onAreaLoaded = useCallback(() => {
      setInitialLoaded(true)
    }, [])

    const onFinishRefresh = useCallback(() => {
      setPrevAreasInfo(null)
      setPrevKey(null)
      finishLoadCallback.current?.()
    }, [])
    // const [loading, setLoading] = useState(isActive)
    const [initialLoaded, setInitialLoaded] = useState(false)

    const finishLoadCallback = useRef()

    const onRefresh = async () => {
      // setLoaded(false)
      onRefreshParent?.()
      await new Promise((resolve) => {
        finishLoadCallback.current = resolve
      })
    }

    // 排行榜
    const rankArea = useMemo(() => {
      if (areasInfo) return areasInfo.filter((m) => m.template_id === '19')
      return []
    }, [areasInfo])

    const areaInfo = useMemo(() => {
      if (areasInfo) return areasInfo.filter((m) => m.template_id !== '19')
      return []
    }, [areasInfo])

    const rankIndex = useMemo(() => {
      if (areasInfo) return areasInfo.findIndex((m) => m.template_id === '19')
      return -1
    }, [areasInfo])

    const areaInfoFormatted = useMemo(() => {
      const list = [
        ...areaInfo.map((m) => {
          return {
            grouped: false,
            area: m,
          }
        }),
      ]
      if (rankIndex >= 0) {
        list.splice(rankIndex, 0, {
          grouped: true,
          list: rankArea,
        })
      }
      return list
    }, [areaInfo, rankArea, rankIndex])

    // useEffect(() => {
    //   if (isActive) {
    //     const id = setTimeout(() => {
    //       setLoading(true)
    //     }, LOAD_DELAY)
    //     return () => clearTimeout(id)
    //   }
    // }, [isActive])

    const [prevAreasInfo, setPrevAreasInfo] = useState(null)
    const [prevKey, setPrevKey] = useState(null)
    const [currentAreasInfo, setCurrentAreasInfo] = useState(() => areaInfoFormatted)
    const [currentKey, setCurrentKey] = useState(contentKey)
    const shortcutRef = useRef(null)

    if (currentKey !== contentKey) {
      setPrevAreasInfo(currentAreasInfo)
      setPrevKey(currentKey)
      setCurrentKey(contentKey)
      setCurrentAreasInfo(areaInfoFormatted)
    }

    const onScroll = useCallback(() => {
      const el = shortcutRef.current
      if (!el) return
      const shortcutPos = el?.getBoundingClientRect()
      onShortcutMoveEvent(shortcutPos)
    }, [onShortcutMoveEvent])

    const infos = useMemo(() => {
      return prevAreasInfo != null
        ? [
            {
              key: prevKey,
              areasInfo: prevAreasInfo,
              onLoad: onAreaLoaded,
              state: initialLoaded ? 'loaded' : 'initial-loading',
              onScroll,
            },
            {
              key: currentKey,
              areasInfo: currentAreasInfo,
              onLoad: onFinishRefresh,
              state: 'loading',
              onScroll: () => {},
            },
          ]
        : [
            {
              key: currentKey,
              areasInfo: currentAreasInfo,
              onLoad: onAreaLoaded,
              state: initialLoaded ? 'loaded' : 'initial-loading',
              onScroll,
            },
          ]
    }, [currentAreasInfo, currentKey, initialLoaded, onAreaLoaded, onFinishRefresh, onScroll, prevAreasInfo, prevKey])

    const trigger = useAscenderScrollEvent({
      onScroll: (ev) => {
        const shortcutPos = shortcutRef.current?.getBoundingClientRect()
        onShortcutMoveEvent(shortcutPos)
        // console.log('offset', shortcutPos)
      },
    })

    const onShortcutRefed = useCallback(
      (el) => {
        if (!el) return
        shortcutRef.current = el
        const shortcutPos = el?.getBoundingClientRect()
        onShortcutMoveEvent(shortcutPos)
      },
      [onShortcutMoveEvent]
    )

    const resizeRef = useResizeObserver({
      onResize() {
        const shortcutPos = shortcutRef.current?.getBoundingClientRect()
        onShortcutMoveEvent(shortcutPos)
      },
    })

    const shortcutHeader = useMemo(() => {
      return (
        <HeaderContents ref={resizeRef}>
          <Carousel {...tabInfo} />

          <ShortcutBox {...tabInfo} ref={onShortcutRefed} />
        </HeaderContents>
      )
    }, [onShortcutRefed, resizeRef, tabInfo])

    return (
      <TabWrapper>
        <TabContentWrapper
          onLoadCapture={(e) => {
            e.stopPropagation()
          }}
          className={initialLoaded ? 'lazy-root' : 'lazy-root not-loaded'}
        >
          <PullToRefresh
            onRefresh={onRefresh}
            pullingContent={<PagePullLoad paused />}
            refreshingContent={<PagePullLoad />}
          >
            {infos.map((info, index) => (
              <AreaInfo
                key={info.key}
                info={info}
                lazy={index === infos.length - 1}
                displayAreaBox={displayAreaBox}
                shortcuts={shortcutHeader}
                onScroll={info.onScroll}
              ></AreaInfo>
            ))}
            {trigger}
          </PullToRefresh>
        </TabContentWrapper>
        {!initialLoaded && (
          <TabOverLay>
            <StyledAnimation />
          </TabOverLay>
        )}
      </TabWrapper>
    )
  }
)
