import { memo } from 'react'
import { fetchPostAdEvent } from 'api'
import { CmsGridWrapper } from 'commonStyledComponents'
import { AdImage } from 'component/AreaBox/Styled'
import VideoBox from 'component/VideoBox'
import { AdvertisementType } from 'constant/advertisement'
import { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAdInfo } from 'redux/selector/app'
import { formatDate } from 'utils/timeFormat'

const AD_EVERY_VIDEO = 8

/**
 * 模板 ID: "1": 小圖(默認)
 * */
function Template1({ videoData, trigger, currentLimit }) {
  const adInfo = useSelector(selectAdInfo)

  /** 視頻櫥窗中廣告位 */
  const adAreaCenter = useMemo(() => {
    return adInfo?.[AdvertisementType.AreaCentral] ?? []
  }, [adInfo])

  const onAdItemClick = (ad) => {
    if (ad) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: ad.id,
        material_name: ad.name,
        oholo_site_id: ad.site_id,
        oholoer_id: ad.oholoer_id,
      })
      window.open(ad?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }
  return (
    <CmsGridWrapper>
      {videoData?.slice(0, currentLimit).map((item, i) => {
        const hasAd = adAreaCenter.length > 0 && i >= AD_EVERY_VIDEO && (i - AD_EVERY_VIDEO) % AD_EVERY_VIDEO === 0
        const adIndex = ((i - AD_EVERY_VIDEO) / AD_EVERY_VIDEO) % adAreaCenter.length
        const ad = adAreaCenter[adIndex]
        return (
          <Fragment key={i}>
            {hasAd && (
              <AdImage src={ad.icon_path} source="preview" aspectRatio={374 / 94} onClick={() => onAdItemClick(ad)} />
            )}
            <VideoBox videoInfo={item} allowUnload />
          </Fragment>
        )
      })}
      {trigger}
    </CmsGridWrapper>
  )
}

// this indicate areaBox should hide bottom section until fully shown
Template1.lazy = true

export default memo(Template1)
