import styled from 'styled-components'

export const Wrapper = styled.div`
  --columns: ${(props) => props.columns ?? 2};
  width: ${(props) =>
    props?.width != null
      ? props.width
      : 'calc(calc(100% - var(--cms-grid-column-gap, 0.093rem) * calc(var(--columns) - 1)) / var(--columns))'};

  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    user-select: none;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  border-radius: var(--component-video-box--border-radius);
  overflow: hidden;
  // fuck the safari
  /* transform: translateZ(0px); */
`

export const VideoInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.41rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  color: white;
`

export const VideoViewCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.26rem;
`

const Icon = styled.div`
  width: 0.356rem;
  height: 0.227rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export const Viewed = styled(Icon)`
  background-image: url(${process.env.PUBLIC_URL}/icon/view-icon.svg);
  margin-right: 0.1rem;
`

export const VideoName = styled.div`
  padding: 0.1rem 0 0;
  font-size: 0.35897rem;
  line-height: 150%; /* 0.53846rem */
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: ${(props) => props.textAlign};
`

export const GradientBox = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent 75%, rgba(0, 0, 0, 0.6));
`
