import Template1 from '../component/Template1'
import Template2 from '../component/Template2'
import Template3 from '../component/Template3'
import Template4 from '../component/Template4'
import Template5 from '../component/Template5'
import Template8 from '../component/Template8'
import Template9 from '../component/Template9'
import Template10 from '../component/Template10'
import Template11 from '../component/Template11'
import Template12 from '../component/Template12'
import Template13 from '../component/Template13'
import Template14 from '../component/Template14'
import Template15 from '../component/Template15'
import Template16 from '../component/Template16'
import Template17 from '../component/Template17'
import Template18 from '../component/Template18'

import Template101 from '../component/Template101'
import Template102 from '../component/Template102'
import Template103 from '../component/Template103'
import Template104 from '../component/Template104'
import Template105 from '../component/Template105'
import Template106 from '../component/Template106'
import Template107 from '../component/Template107'

import Template201 from '../component/Template201'
import Template202 from '../component/Template202'
import Template203 from '../component/Template203'
import Template204 from '../component/Template204'
import Template205 from '../component/Template205'
import Template206 from '../component/Template206'
import Template207 from '../component/Template207'

import Template301 from '../component/Template301'
import Template302 from '../component/Template302'
import Template303 from '../component/Template303'
import Template304 from '../component/Template304'
import Template305 from '../component/Template305'
import Template306 from '../component/Template306'
import Template307 from '../component/Template307'
import Template19 from '../component/Template19'

export const useTemplates = () => {
  const templates = {
    1: Template1,
    2: Template2,
    3: Template3,
    4: Template4,
    5: Template5,
    8: Template8,
    9: Template9,
    10: Template10,
    11: Template11,
    12: Template12,
    13: Template13,
    14: Template14,
    16: Template16,
    15: Template15,
    17: Template17,
    18: Template18,
    19: Template19,

    ...(process.env.REACT_APP_HAS_SHORT
      ? {
          101: Template101,
          102: Template102,
          103: Template103,
          104: Template104,
          105: Template105,
          106: Template106,
          107: Template107,
        }
      : {}),

    ...(process.env.REACT_APP_HAS_COMIC
      ? {
          201: Template201,
          202: Template202,
          203: Template203,
          204: Template204,
          205: Template205,
          206: Template206,
          207: Template207,
        }
      : {}),

    ...(process.env.REACT_APP_HAS_NOVEL
      ? {
          301: Template301,
          302: Template302,
          303: Template303,
          304: Template304,
          305: Template305,
          306: Template306,
          307: Template307,
        }
      : {}),
  }

  return { templates }
}
