// 漫畫 單行左滑(兩個半)
import NovelBox from 'component/NovelBox'

import { Wrapper } from './Styled'
import { memo } from 'react'

function Template303({ videoData }) {
  return (
    // 因這裡有滑動事件, 設定.swiper-no-swiping 禁止CMS View的左右滑動切換頻道功能
    <Wrapper className={'swiper-no-swiping'}>
      {videoData?.map((item, i) => {
        return <NovelBox overflow={true} key={i} novelInfo={item} width="3rem" allowUnload />
      })}
    </Wrapper>
  )
}
export default memo(Template303)
