import styled from 'styled-components'

/** 金剛區佈局設定 */
const shortcutSettings = {
  /** 三個居中 */
  1: {
    flexWrap: 'wrap',
    gap: '0.41rem calc((100% - (1.6rem * 3)) / (3 - 1))',
    justifyContent: 'center',
  },

  /** 四個居中 */
  2: {
    flexWrap: 'wrap',
    gap: '0.41rem calc((100% - (1.6rem * 4)) / (4 - 1))',
    justifyContent: 'center',
  },

  /** 三個半 (停用) */
  // 3: {
  //   gap: '0.41rem calc((100% - (1.6rem * 3)) / (3 - 1))',
  // },

  /** 四個半 */
  4: {
    gap: '0.41rem calc((100% - (1.6rem * 4.5)) / (4 - 1))',
  },

  /** 四個換行 */
  5: {
    flexWrap: 'wrap',
    gap: '0.41rem calc((100% - (1.6rem * 4)) / (4 - 1))',
  },
  /** 五個左右滑 */
  6: {
    flexWrap: 'wrap',
    gap: '0.41rem calc((100% - (1.6rem * 5)) / (5 - 1))',
  },
}

export const Wrapper = styled.div`
  display: flex;
  padding: 0.48rem 0.3692rem 0.533rem 0.3692rem;
  overflow-x: auto;

  // default template: 6
  ${(props) => shortcutSettings[props.template] ?? shortcutSettings[6]}

  ::-webkit-scrollbar {
    display: none;
  }
  /* scroll bar 底部背景 */
  ::-webkit-scrollbar-track {
    display: none;
  }

  /* scroll bar 滑動本身 */
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`
